import { ReleasesState, ReleasesTypes } from './Types'

const initialState: ReleasesState = {
  items: [],
}

export function reducer(state = initialState, action: any): ReleasesState {
  let items: any, item, key
  switch (action.type) {
    case ReleasesTypes.REQUEST_RELEASES:
      key = action.payload.key
      let clear = action.payload.clear
      item = state.items.find((item) => item.key === key)
      items = state.items.filter((item) => item.key !== key)
      if (!item) {
        item = {
          releases: [],
        }
      }
      if (clear) {
        item.releases = []
      }
      items.push({
        ...item,
        key,
        requesting: true,
        error: false,
        success: false,
        message: null,
        search: action.payload.search,
        page: action.payload.page,
        lastPage: null,
        limit: action.payload.limit,
      })
      return {
        items,
      }
    case ReleasesTypes.SUCCESS_RELEASES:
      key = action.data.key
      item = state.items.find((item) => item.key === key)
      items = state.items.filter((item) => item.key !== key)

      item = {
        ...item,
        releases: item.releases.concat(action.data.releases),
        lastPage: action.data.lastPage,
        page: action.data.page,
        total: action.data.total,
        perPage: action.data.perPage,
        requesting: false,
        error: false,
        success: true,
        message: null,
      }
      items.push(item)
      return {
        items,
      }
    case ReleasesTypes.ERROR_RELEASES:
      key = action.error.key
      item = state.items.find((item) => item.key === key)
      items = state.items.filter((item) => item.key !== key)
      item = {
        ...item,
        requesting: false,
        error: true,
        success: false,
        message: action.error.message,
      }
      items.push(item)
      return {
        items,
      }
    case ReleasesTypes.CLEAR_RELEASES:
      key = action.clear.key
      items = state.items.filter((item) => item.key !== key)
      return {
        items,
      }
  }
  return state
}
