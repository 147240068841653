import { RegisterState, RegisterTypes } from './Types'

const initialState: RegisterState = {
  requesting: false,
  error: false,
  success: false,
  message: null,
}

export function reducer(state = initialState, action: any): RegisterState {
  switch (action.type) {
    case RegisterTypes.REQUEST_REGISTER:
      return {
        requesting: true,
        error: false,
        success: false,
        message: null,
      }
    case RegisterTypes.SUCCESS_REGISTER:
      return {
        requesting: false,
        error: false,
        success: true,
        message: action.data.message,
      }
    case RegisterTypes.ERROR_REGISTER:
      return {
        requesting: false,
        error: true,
        success: false,
        message: action.error.message,
      }
    case RegisterTypes.CLEAR_REGISTER:
      return {
        requesting: false,
        error: false,
        success: false,
        message: null,
      }
    case RegisterTypes.CLEAR_ERROR:
      return {
        ...state,
        error: false,
        message: null
      }
  }
  return state
}
