import { BookEditState, BookEditTypes } from './Types'

const initialState: BookEditState = {
  data: null,
  requesting: false,
  errors: null,
  success: false,
  message: null
}

export function reducer(state = initialState, action: any): BookEditState {
  switch (action.type) {
    case BookEditTypes.REQUEST_BOOK_EDIT:
      const payloadEdit = action.payload
      return {
        data: payloadEdit,
        requesting: true,
        errors: null,
        success: false,
        message: null
      }
    case BookEditTypes.SUCCESS_BOOK_EDIT:
      const payloadBook = action.data
      return {
        data: payloadBook.book,
        requesting: false,
        errors: null,
        success: true,
        message: payloadBook.message
      }
    case BookEditTypes.ERROR_BOOK_EDIT:
      const payloadError = action.error
      return {
        data: null,
        requesting: false,
        errors: payloadError.errors,
        success: false,
        message: payloadError.message
      }
    case BookEditTypes.CLEAR_BOOK_EDIT:
      return {
        data: null,
        requesting: false,
        errors: null,
        success: false,
        message: null
      }
  }
  return state
}
