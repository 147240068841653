import React, { useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import SnackbarContent from '../Snackbar/SnackbarContent';

interface IItem {
  id: any;
  title: string;
  image: { url: string };
}

export interface IContentList {
  items: Array<IItem> | null;
  changeText: (text: string) => void;
  changePage: (page: number) => void;
  paginateState: {page, lastPage, perPage, total};
  loading: boolean;
  renderItemActions?: (item: object) => React.Component;
  renderRemoveAction?: (item: object) => React.Component;
  refreshList: Function
}

export interface IPaginate {
  changePage: (page: number) => void;
  page: number| undefined;
  lastPage: number| undefined;
  perPage: number| undefined;
  total: number| undefined;
}

interface IContentProvider {
  request: Function;
  clearRequest: Function;
  storeName: string;
  name: string;
  SearchComponent: React.FC<any>;
  ListComponent: React.FC<IContentList>;
  defaultTitle?: string | null;
  defaultPage?: number | null;
  onFiltersChange?: ({ page: number, text: string }) => void;
  styles?: any;
}

const ContentProvider: React.FC<IContentProvider> = ({
  request,
  clearRequest,
  storeName,
  name,
  SearchComponent,
  ListComponent,
  defaultTitle,
  defaultPage,
  onFiltersChange
}) => {
  const dispatch = useDispatch()
  const findState = (state: any) => state[storeName].items.find((item: any) => item.key === name)
  const state: any = useSelector<any>(findState) || {}
  const Content = state[storeName]
  const search = state.search
  
  useEffect(() => {
    const search = defaultTitle
    const page = defaultPage || 1
    dispatch(request({ key: name, search, page }))
    return () => {
      dispatch(clearRequest({ key: name }))
    }
  }, [])

  useEffect(() => {
    onFiltersChange && onFiltersChange({ page: state.page, text: state.search })
  }, [state.page, state.search])

  const handleNextPage = useCallback(nextPage => {
    if (state.requesting || nextPage <= 0 || nextPage > state.lastPage) {
      return
    }
    dispatch(request({ key: name, page: nextPage, clear: true }))
  }, [state.lastPage, state.page, state.requesting, dispatch, name])

  const handleOnChangeSearch = useCallback(text => {
    if (text === search) {
      return
    }
    dispatch(request({
      key: name,
      search: text,
      page: 1,
      clear: true
    }))
  }, [search, name, dispatch])

  const refreshList = useCallback(() => {
    dispatch(request({ key: name, page: state.page, search: state.search, clear: true }))
  }, [name, state.page, state.search])

  const Error = useMemo(() => {
    if (!state.error) {
      return null 
    }
    return <SnackbarContent message={state.message} color="danger" />
  }, [state.error, state.message])

  const paginateState = useMemo(() => {
    return {
      page: state.page,
      lastPage: state.lastPage,
      perPage: state.perPage,
      total: state.total,
    }
  }, [state])

  return (
    <div>
      {Error}
      <SearchComponent defaultText={defaultTitle} onChange={handleOnChangeSearch} />
      <ListComponent
        changeText={handleOnChangeSearch}
        changePage={handleNextPage}
        paginateState={paginateState}
        items={Content}
        loading={state.requesting}
        refreshList={refreshList}
      />
    </div>
  )
}

export default ContentProvider
