import { get, ResponseService, requestForm } from './Service'
import { uploadPdf, uploadBookCover } from './UploadService'
import { RequestJavAddParams } from '../stores/JavAdd/Types'
import { RequestJavEditParams } from '../stores/JavEdit/Types'

async function fetchJavs({ page, search, limit }): Promise<ResponseService> {
  let url = 'api/v1/javs?'
  if (page) {
    url += 'page=' + page + '&'
  }
  if (search) {
    url += 'text=' + encodeURI(search) + '&'
  }
  if (limit) {
    url += 'limit=' + limit
  }
  return await get(url, undefined, {
    default_error_message: 'Ocorreu um erro na busca'
  })
}

async function fetchJav(id: Number): Promise<ResponseService> {
  let url = 'api/v1/javs/' + id
  return await get(url, undefined, {
    default_error_message: 'Ocorreu um erro na busca'
  })
}

async function javAdd(data: RequestJavAddParams): Promise<ResponseService> {
  let payload: any = {...data}
  if (data.image instanceof File) {
    const responseUploadImage = await uploadBookCover(data.image)
    if (responseUploadImage.type === 'error') {
      return {
        type: 'error',
        message: 'Ocorreu um erro no upload de imagem',
        details: {
          image_id: responseUploadImage.message
        }
      }
    }
    payload.image_id = responseUploadImage.details.id
    delete payload.image
  } else {
    payload.image_id = data.image
    delete payload.image
  }
  if (data.pdf instanceof File) {
    const responseUploadPdf = await uploadPdf(data.pdf)
    if (responseUploadPdf.type === 'error') {
      return {
        type: 'error',
        message: 'Ocorreu um erro no upload de pdf',
        details: {
          pdf_id: responseUploadPdf.message
        }
      }
    }
    payload.pdf_id = responseUploadPdf.details.id
    delete payload.pdf
  } else {
    payload.pdf_id = data.pdf
    delete payload.pdf
  }
  let url = 'api/v1/admin/javs'
  return await requestForm('post', url, payload, {
    default_error_message: 'Ocorreu um erro na busca'
  })
}

async function javEdit(data: RequestJavEditParams): Promise<ResponseService> {
  let payload: any = {...data}
  if (data.image instanceof File) {
    const responseUploadImage = await uploadBookCover(data.image)
    if (responseUploadImage.type === 'error') {
      return {
        type: 'error',
        message: 'Ocorreu um erro no upload de imagem',
        details: {
          image_id: responseUploadImage.message
        }
      }
    }
    payload.image_id = responseUploadImage.details.id
    delete payload.image
  } else {
    payload.image_id = data.image
    delete payload.image
  }
  if (data.pdf instanceof File) {
    const responseUploadPdf = await uploadPdf(data.pdf)
    if (responseUploadPdf.type === 'error') {
      return {
        type: 'error',
        message: 'Ocorreu um erro no upload de pdf',
        details: {
          pdf_id: responseUploadPdf.message
        }
      }
    }
    payload.pdf_id = responseUploadPdf.details.id
    delete payload.pdf
  } else {
    payload.pdf_id = data.pdf
    delete payload.pdf
  }
  let url = 'api/v1/admin/javs/' + payload.id
  return await requestForm('put', url, payload, {
    default_error_message: 'Ocorreu um erro na busca'
  })
}

export const javsService = {
  javAdd,
  javEdit,
  fetchJav,
  fetchJavs,
}