import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";

import { fetchContent } from "../../services/SearchService";

const AutocompleteContent = ({ typeContent, onSelected }) => {
  const [requesting, setRequesting] = React.useState<any>(false);
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState<any>([]);

  const findOptions = React.useCallback(
    (text) => {
      if (requesting) {
        return;
      }
      setRequesting(true);
      fetchContent({ typeContent, text, limit: 10000 })
        .then((options) => {
          setOptions(options);
          setRequesting(false);
        })
        .catch((err) => {
          console.error(err);
          setRequesting(false);
        });
    },
    [typeContent, requesting]
  );

  React.useEffect(() => {
    if (!typeContent) return;
    findOptions(null);
  }, [typeContent]);

  return (
    <Autocomplete
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      getOptionSelected={(option: any, value: any) => option.id === value.id}
      getOptionLabel={(option) => option.title}
      options={options}
      loading={requesting}
      onChange={(event, newValue) => {
        onSelected(newValue ? newValue.id : null);
      }}
      renderOption={(option) => {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <img src={option.image.url} width={50} />
            <div style={{ paddingLeft: "10px" }}>
              {option.id} - {option.title}
            </div>
          </div>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          onChange={(e) => findOptions(e.target.value)}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {requesting ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default AutocompleteContent;
