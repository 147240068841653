export const REQUEST_BOOK_EDIT: any = 'REQUEST_BOOK_EDIT'
export const SUCCESS_BOOK_EDIT = 'SUCCESS_BOOK_EDIT'
export const ERROR_BOOK_EDIT = 'ERROR_BOOK_EDIT'
export const CLEAR_BOOK_EDIT = 'CLEAR_BOOK_EDIT'

export interface Book {
  id: string;
  title: string;
}

export interface BookEditState {
  data: RequestBookEditParams | Book | null;
  requesting: boolean;
  errors: Object | null;
  success: boolean;
  message: string | null;
}

export interface RequestBookEditParams {
  id: string | number;
  title: string;
  active: boolean;
  is_public: boolean;
  tags: Array<string>;
  image: File | number;
  pdf: File | number;
}

export interface ErrorRequestBookEdit {
  errors: Array<{ name, message }>;
  message: string;
}

export interface SuccessRequestBookEdit {
  message: string;
  book: Book;
}

export interface RequestBookEditAction {
  type: typeof REQUEST_BOOK_EDIT
  payload: RequestBookEditParams
}

export interface SuccessRequestBookEditAction {
  type: typeof SUCCESS_BOOK_EDIT
  data: SuccessRequestBookEdit
}

export interface ErrorRequestBookEditAction {
  type: typeof ERROR_BOOK_EDIT
  error: ErrorRequestBookEdit
}

export interface ClearBookEditAction {
  type: typeof CLEAR_BOOK_EDIT
}

export type BookEditActions = RequestBookEditAction | SuccessRequestBookEditAction | ErrorRequestBookEditAction | ClearBookEditAction

export const BookEditTypes = {
  REQUEST_BOOK_EDIT,
  SUCCESS_BOOK_EDIT,
  ERROR_BOOK_EDIT,
  CLEAR_BOOK_EDIT,
}