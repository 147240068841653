import {
  REQUEST_BOOKS,
  SUCCESS_BOOKS,
  ERROR_BOOKS,
  CLEAR_BOOKS,
  BooksActions,
  RequestBooksParams,
  SuccessRequestBooks,
  ErrorRequestBooks,
  ClearBooks,
} from './Types'

export function requestBooks(params: RequestBooksParams): BooksActions {
  return {
    type: REQUEST_BOOKS,
    payload: params,
  }
}

export function successRequestBooks(response: SuccessRequestBooks): BooksActions {
  return {
    type: SUCCESS_BOOKS,
    data: response,
  }
}

export function errorRequestBooks(response: ErrorRequestBooks): BooksActions {
  return {
    type: ERROR_BOOKS,
    error: response,
  }
}

export function clearBooks(clear: ClearBooks): BooksActions {
  return {
    type: CLEAR_BOOKS,
    clear,
  }
}

export default {
  requestBooks,
  clearBooks,
  successRequestBooks,
  errorRequestBooks,
}
