import React, { useState } from 'react'

import SubscriptionProvider from '../../components/SubscriptionProvider'

import Card from '../../components/Card/Card'
import CardBody from '../../components/Card/CardBody'

import SubscriptionContentAdd from './SubscriptionContentAdd'
import SubscriptionContentList from './SubscriptionContentList'

function SubscriptionContentPage(props) {
  const id = props.match.params.id
  const params = new URLSearchParams(props.location.search)
  const defaultText = params.get('text')
  const defaultPage = params.get('page') ? Number(params.get('page')) : 0
  const changeUrl = ({ page, text }) => {
    const newUrl = props.location.pathname 
      + '?'
      + (page ? '&page=' + page : '')
      + (text ? '&text=' + text : '')
    props.history.push(newUrl)
  }
  return (
    <SubscriptionProvider 
      id={id} 
      ContentComponent={({ subscription }) => (
        <SubscriptionContent 
          defaultText={defaultText} 
          defaultPage={defaultPage}
          changeUrl={changeUrl}
          subscription={subscription}
        />
      )}
    />
  )
}

const SubscriptionContent = ({ subscription, defaultText, defaultPage, changeUrl }) => {
  const [key, setKey] = useState('key')
  return (
    <div>
      <Card>
        <CardBody>
          <h3 style={{margin: 0}}>{subscription.id} - {subscription.title}</h3>
        </CardBody>
      </Card>
      <SubscriptionContentAdd
        subscription={subscription}
        onSuccess={() => setKey('key' + Date.now())}
      />
      <SubscriptionContentList
        key={key}
        subscription={subscription}
        defaultText={defaultText}
        defaultPage={defaultPage}
        changeUrl={changeUrl}
      />
    </div>
  )
}


export default SubscriptionContentPage
