export const REQUEST_JAV_EDIT: any = 'REQUEST_JAV_EDIT'
export const SUCCESS_JAV_EDIT = 'SUCCESS_JAV_EDIT'
export const ERROR_JAV_EDIT = 'ERROR_JAV_EDIT'
export const CLEAR_JAV_EDIT = 'CLEAR_JAV_EDIT'

export interface Jav {
  id: string;
  title: string;
}

export interface JavEditState {
  data: RequestJavEditParams | Jav | null;
  requesting: boolean;
  errors: Object | null;
  success: boolean;
  message: string | null;
}

export interface RequestJavEditParams {
  id: string | number;
  title: string;
  active: boolean;
  is_public: boolean;
  tags: Array<string>;
  image: File | number;
  pdf: File | number;
}

export interface ErrorRequestJavEdit {
  errors: Array<{ name, message }>;
  message: string;
}

export interface SuccessRequestJavEdit {
  message: string;
  jav: Jav;
}

export interface RequestJavEditAction {
  type: typeof REQUEST_JAV_EDIT
  payload: RequestJavEditParams
}

export interface SuccessRequestJavEditAction {
  type: typeof SUCCESS_JAV_EDIT
  data: SuccessRequestJavEdit
}

export interface ErrorRequestJavEditAction {
  type: typeof ERROR_JAV_EDIT
  error: ErrorRequestJavEdit
}

export interface ClearJavEditAction {
  type: typeof CLEAR_JAV_EDIT
}

export type JavEditActions = RequestJavEditAction | SuccessRequestJavEditAction | ErrorRequestJavEditAction | ClearJavEditAction

export const JavEditTypes = {
  REQUEST_JAV_EDIT,
  SUCCESS_JAV_EDIT,
  ERROR_JAV_EDIT,
  CLEAR_JAV_EDIT,
}