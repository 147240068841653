import {
  REQUEST_BOOK_EDIT,
  SUCCESS_BOOK_EDIT,
  ERROR_BOOK_EDIT,
  CLEAR_BOOK_EDIT,
  BookEditActions,
  RequestBookEditParams,
  SuccessRequestBookEdit,
  ErrorRequestBookEdit
} from './Types'

export function requestBookEdit(params: RequestBookEditParams): BookEditActions {
  return {
    type: REQUEST_BOOK_EDIT,
    payload: params,
  }
}

export function successRequestBookEdit(response: SuccessRequestBookEdit): BookEditActions {
  return {
    type: SUCCESS_BOOK_EDIT,
    data: response,
  }
}

export function errorRequestBookEdit(response: ErrorRequestBookEdit): BookEditActions {
  return {
    type: ERROR_BOOK_EDIT,
    error: response,
  }
}

export function clearBookEdit(): BookEditActions {
  return {
    type: CLEAR_BOOK_EDIT
  }
}

export default {
  requestBookEdit,
  clearBookEdit,
  successRequestBookEdit,
  errorRequestBookEdit,
}
