import { put, call } from 'redux-saga/effects'
import { contentsService } from '../services/ContentsService'

import ContentsActions from '../stores/Contents/Actions'
import { RequestContentsAction } from '../stores/Contents/Types'

import { ResponseService } from '../services/Service'

export function* getContents(action: RequestContentsAction) {
  const response: ResponseService = yield call<any>(contentsService.fetchContents, action.payload)
  if (response.type === 'success') {
    let contents = response.details.data
    let lastPage = Number(response.details.lastPage) || 0
    let data = {
      key: action.payload.key,
      contents,
      lastPage,
    }
    yield put(ContentsActions.successRequestContents(data))
  } else {
    let message = response.message || ''
    yield put(
      ContentsActions.errorRequestContents({
        key: action.payload.key,
        message,
      })
    )
  }
}
