import React, { useState, useEffect, useRef } from 'react'
import { DropzoneArea } from 'material-ui-dropzone'
import { createStyles, withStyles } from '@material-ui/core'

const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});

const CustomFileUpload = ({ title, acceptedFiles, onChange, disabled, classes, ...restProps }) => {
  const [ready, setReady] = useState(false)
  const [file, setFile] = useState<any>([])

  useEffect(() => {
    if (restProps.defaultFile instanceof File) {
      toBase64(restProps.defaultFile)
        .then((text) => {
          setFile([text])
          setReady(true)
        })
        .catch(() => {
          setReady(true)
        })
    } else {
      setReady(true)
    }
  }, [restProps.defaultFile])

  if (!ready) {
    return null
  }
  
  return (
    <div>
      <p>{ title }</p>
      {disabled ? 'Aguarde ...' :
        <DropzoneArea
          //getDropRejectMessage={message => {}}
          initialFiles={file}
          maxFileSize={5e+7}
          dropzoneClass={classes.dropzone}
          dropzoneText="Faça seu upload aqui"
          showFileNames={false}
          showAlerts={false}
          filesLimit={1}
          acceptedFiles={acceptedFiles}
          onChange={onChange}
        />
      }
    </div>
  )
}

const styles = createStyles({
  dropzone: {
    background: '#fff',
    minHeight: '100px'
  },
})

export default withStyles(styles)(CustomFileUpload)
