export const REQUEST_JAV_ADD: any = 'REQUEST_JAV_ADD'
export const SUCCESS_JAV_ADD = 'SUCCESS_JAV_ADD'
export const ERROR_JAV_ADD = 'ERROR_JAV_ADD'
export const CLEAR_JAV_ADD = 'CLEAR_JAV_ADD'

export interface Jav {
  id: string;
  title: string;
}

export interface JavAddState {
  data: RequestJavAddParams | Jav | null;
  requesting: boolean;
  errors: Object | null;
  success: boolean;
  message: string | null;
}

export interface RequestJavAddParams {
  title: string;
  active: boolean;
  is_public: boolean;
  tags: Array<string>;
  image: File | number;
  pdf: File | number;
}

export interface ErrorRequestJavAdd {
  errors: Object;
  message: string;
}

export interface SuccessRequestJavAdd {
  message: string;
  jav: Jav;
}

export interface RequestJavAddAction {
  type: typeof REQUEST_JAV_ADD
  payload: RequestJavAddParams
}

export interface SuccessRequestJavAddAction {
  type: typeof SUCCESS_JAV_ADD
  data: SuccessRequestJavAdd
}

export interface ErrorRequestJavAddAction {
  type: typeof ERROR_JAV_ADD
  error: ErrorRequestJavAdd
}

export interface ClearJavAddAction {
  type: typeof CLEAR_JAV_ADD
}

export type JavAddActions = RequestJavAddAction | SuccessRequestJavAddAction | ErrorRequestJavAddAction | ClearJavAddAction

export const JavAddTypes = {
  REQUEST_JAV_ADD,
  SUCCESS_JAV_ADD,
  ERROR_JAV_ADD,
  CLEAR_JAV_ADD,
}