import {
  ERROR_REQUEST_RECOVERY_PASSWORD,
  SUCCESS_REQUEST_RECOVERY_PASSWORD,
  REQUEST_REQUEST_RECOVERY_PASSWORD,
  CLEAR_REQUEST_RECOVERY_PASSWORD,
  CLEAR_ERROR,
  Data,
  MessageRequestRecoveryPassword,
  RequestRecoveryPasswordActions,
} from './Types'

export function requestRequestRecoveryPassword(data: Data): RequestRecoveryPasswordActions {
  return {
    type: REQUEST_REQUEST_RECOVERY_PASSWORD,
    payload: data,
  }
}

export function successRequestRecoveryPassword(response: MessageRequestRecoveryPassword): RequestRecoveryPasswordActions {
  return {
    type: SUCCESS_REQUEST_RECOVERY_PASSWORD,
    data: response,
  }
}

export function errorRequestRecoveryPassword(response: MessageRequestRecoveryPassword): RequestRecoveryPasswordActions {
  return {
    type: ERROR_REQUEST_RECOVERY_PASSWORD,
    error: response,
  }
}

export function clear(): RequestRecoveryPasswordActions {
  return {
    type: CLEAR_REQUEST_RECOVERY_PASSWORD,
  }
}

export function clearError(): RequestRecoveryPasswordActions {
  return {
    type: CLEAR_ERROR,
  }
}

export default {
  requestRequestRecoveryPassword,
  successRequestRecoveryPassword,
  errorRequestRecoveryPassword,
}
