import React, { useState, useEffect } from 'react'
import CustomSwitch from '../../components/CustomSwitch/CustomSwitch'

import { activeAccessCode } from '../../services/AccessCodeService'

const AccessCodeSwitch: React.FC<{ accessCode: String, defaultChecked: Boolean }> = ({ accessCode, defaultChecked }) => {
  const [disabled, setDisabled] = useState(false)
  const [checked, setChecked] = useState(defaultChecked)

  const handleOnChange = (checked) => {
    setChecked(checked)
    setDisabled(true)
    activeAccessCode({ accessCode, active: checked })
      .finally(() => setDisabled(false))
  }

  return (
    <CustomSwitch
      disabled={disabled}
      checked={checked} 
      onChange={handleOnChange} />
  )
}

export default AccessCodeSwitch
