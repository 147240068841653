export const REQUEST_JAV: any = 'REQUEST_JAV'
export const SUCCESS_JAV = 'SUCCESS_JAV'
export const ERROR_JAV = 'ERROR_JAV'
export const CLEAR_JAV = 'CLEAR_JAV'

export interface JavState {
  id: number | string | null;
  jav: Object | null;
  requesting: boolean;
  error: boolean;
  success: boolean;
  message: string | null;
}

export interface RequestJavParams {
  id: number | string;
}

export interface ErrorRequestJav {
  message: string;
}

export interface ClearJav {
}

export interface SuccessRequestJav {
  jav: Object;
}

export interface RequestJavAction {
  type: typeof REQUEST_JAV
  payload: RequestJavParams
}

export interface SuccessRequestJavAction {
  type: typeof SUCCESS_JAV
  data: SuccessRequestJav
}

export interface ErrorRequestJavAction {
  type: typeof ERROR_JAV
  error: ErrorRequestJav
}

export interface ClearJavAction {
  type: typeof CLEAR_JAV
}

export type JavActions = RequestJavAction | SuccessRequestJavAction | ErrorRequestJavAction | ClearJavAction

export const JavTypes = {
  REQUEST_JAV,
  SUCCESS_JAV,
  ERROR_JAV,
  CLEAR_JAV,
}