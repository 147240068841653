import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import customSwitchStyle from '../../assets/jss/material-dashboard-react/components/customSwitchStyle';
import { FormGroup, FormControlLabel, Switch } from '@material-ui/core';

const GreenSwitch = withStyles({
  switchBase: {
  },
  checked: {
    color: 'green',
    '&$checked': {
      color: 'green',
    },
    '&$checked + $track': {
      backgroundColor: 'green',
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
  },
})(Switch);

function CustomSwitch({ ...props }: any) {
  const {
    classes,
    label,
    onChange,
    checked,
    disabled
  } = props

  return (
    <FormGroup className={classes.formControl}>
      <FormControlLabel
        className={classes.formControl}
        control={
          <GreenSwitch
            disabled={disabled}
            color="default"
            className={classes.switch}
            checked={checked}
            onChange={(e, checked) => onChange(checked)}
          />
        }
        label={label}
      />
    </FormGroup>
  )
}

export default withStyles(customSwitchStyle)(CustomSwitch)
