import React from 'react'
import Chip from '@material-ui/core/Chip'
import Paper from '@material-ui/core/Paper'
import { createStyles, withStyles } from '@material-ui/core'

const styles = createStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: 5,
    margin: 0,
    width: '100%',
    boxShadow: 'none',
    justifyContent: 'flex-start'
  },
  chip: {
    margin: 5,
  },
}))

function ChipsList({ classes, chips, onDelete, disabled }) {
  if (chips.length === 0) {
    return null
  }
  return (
    <Paper className={classes.root}>
      {chips.map((chip) => {
        return (
          <li key={chip}>
            <Chip
              label={chip}
              onDelete={() => !disabled && onDelete(chip)}
              className={classes.chip}
            />
          </li>
        )
      })}
    </Paper>
  )
}

export default withStyles(styles)(ChipsList)