import { AuthState, AuthTypes } from './Types'

const initialState: AuthState = {
  logged: false,
  requesting: false,
  error: false,
  message: null,
  username: null,
  token: null,
  refreshToken: null,
}

export function reducer(state = initialState, action: any): AuthState {
  switch (action.type) {
    case AuthTypes.REQUEST_AUTH:
      return {
        logged: false,
        requesting: true,
        error: false,
        message: null,
        username: null,
        token: null,
        refreshToken: null,
      }
    case AuthTypes.SUCCESS_AUTH:
      return {
        logged: true,
        requesting: false,
        error: false,
        message: action.data.message,
        username: action.data.username,
        token: action.data.token,
        refreshToken: action.data.refreshToken,
      }
    case AuthTypes.ERROR_AUTH:
      return {
        logged: false,
        requesting: false,
        error: true,
        message: action.error.message,
        username: null,
        token: null,
        refreshToken: null,
      }
    case AuthTypes.CLEAR_AUTH:
      return {
        logged: false,
        requesting: false,
        error: false,
        message: null,
        username: null,
        token: null,
        refreshToken: null,
      }
    case AuthTypes.CLEAR_ERROR:
      return {
        ...state,
        error: false,
        message: null
      }
  }
  return state
}
