export const REQUEST_BOOKS: any = 'REQUEST_BOOKS'
export const SUCCESS_BOOKS = 'SUCCESS_BOOKS'
export const ERROR_BOOKS = 'ERROR_BOOKS'
export const CLEAR_BOOKS = 'CLEAR_BOOKS'


export interface BooksStateItem {
  key: string;
  books: Array<Object>;
  search: string;
  page: number;
  lastPage: number;
  limit: number;
  requesting: boolean;
  error: boolean;
  success: boolean;
  message: string;
}

export interface BooksState {
  items: Array<BooksStateItem> 
}

export interface RequestBooksParams {
  key: string;
  page: number;
  search?: string;
  limit?: number;
  clear?: boolean;
}

export interface ErrorRequestBooks {
  key: string;
  message: string;
}

export interface ClearBooks {
  key: string;
}

export interface SuccessRequestBooks {
  key: string;
  books: Array<Object>;
  lastPage: number;
  page: number;
  total: number;
  perPage: number;
}

export interface RequestBooksAction {
  type: typeof REQUEST_BOOKS
  payload: RequestBooksParams
}

export interface SuccessRequestBooksAction {
  type: typeof SUCCESS_BOOKS
  data: SuccessRequestBooks
}

export interface ErrorRequestBooksAction {
  type: typeof ERROR_BOOKS
  error: ErrorRequestBooks
}

export interface ClearBooksAction {
  type: typeof CLEAR_BOOKS,
  clear: ClearBooks
}

export type BooksActions = RequestBooksAction | SuccessRequestBooksAction | ErrorRequestBooksAction | ClearBooksAction

export const BooksTypes = {
  REQUEST_BOOKS,
  SUCCESS_BOOKS,
  ERROR_BOOKS,
  CLEAR_BOOKS,
}