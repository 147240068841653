import React, { useMemo, useState, useEffect, useCallback } from 'react'
import { Link } from 'react-router-dom'
import AddOutlined from '@material-ui/icons/AddOutlined'
import AddCircle from '@material-ui/icons/AddCircle'

import { createEditButton, createAccessCodeButton, buildButtonAction } from '../../components/ContentList/ContentList'
import { fetchSubscriptions } from '../../services/SubscriptionService'

import GridItem from '../../components/Grid/GridItem'
import GridContainer from '../../components/Grid/GridContainer'
import Table from '../../components/Table/Table'
import Card from '../../components/Card/Card'
import CardBody from '../../components/Card/CardBody'
import Paginate from '../../components/Paginate'
import CardFooter from '../../components/Card/CardFooter'
import Loading from '../../components/Loading/Loading'
import Error from '../../components/Error/Error'
import Button from '../../components/CustomButtons/Button'
import CustomInput from '../../components/CustomInput/CustomInput'

const SubscriptionList: React.FC<any> = props => {
  const params = new URLSearchParams(props.location.search)
  const defaultText = params.get('text')
  const defaultPage = params.get('page') ? Number(params.get('page')) : 0

  const [items, setItems] = useState([])
  const [error, setError] = useState<any>(null)
  const [success, setSuccess] = useState(false)
  const [loading, setLoading] = useState(false)
  const [filters, setFilters] = useState({ page: defaultPage, text: defaultText })
  const [paginateState, setPaginateState] = useState({ page: 0,  lastPage: 0,  perPage: 0, total: 0, })

  useEffect(() => {
    fetchData(filters)
  }, [])

  useEffect(() => {
    fetchData(filters)
    changeUrl(filters)
  }, [filters])

  const changeUrl = ({ page, text }) => {
    const newUrl = props.location.pathname 
      + '?'
      + (page ? '&page=' + page : '')
      + (text ? '&text=' + text : '')
    props.history.push(newUrl)
  }

  const onChangePage = useCallback(page => {
    setFilters({
      ...filters,
      page
    })
  }, [filters])

  const fetchData = filters => {
    setLoading(true)
    setError(null)
    setSuccess(false)
    fetchSubscriptions(filters)
      .then(response => {
        if (response.type === 'error') {
          setSuccess(false)
          setError(response.message)
          setLoading(false)
          return
        }
        const data = response.details
        setPaginateState({
          page: +data.page,
          lastPage: +data.lastPage,
          perPage: +data.perPage,
          total: +data.total,
        })
        setItems(data.data)
        setSuccess(true)
        setError(null)
        setLoading(false)
      })
      .catch(err => {
        setSuccess(false)
        setError(err.message)
        setLoading(false)
      })
  }

  const ListContent = useMemo(() => {
    const data = items.map(createContentItem)
    if (data.length === 0) {
      if (loading) {
        return null
      }
      return <p>Nenhum registro encontrado</p>
    }
    return (
      <Table
        tableHeaderColor="primary"
        tableHead={['Id', 'Título', 'Opções']}
        tableData={data}
      />
    )
  }, [items, loading])

  return (
    <div>
      <Link to="/admin/adicionar-assinatura">
        <Button color="primary">
          <AddCircle />
          <span>Adicionar Assinatura</span>
        </Button>
      </Link>
      <Search 
        onChange={text => setFilters({ ...filters, text, page: 1 })} 
        defaultText={defaultText} 
      />
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              <Error message={error} hide={!error} />
              <Loading message={"Aguarde..."} hide={!loading} />
              {ListContent}
            </CardBody>
            <CardFooter>
              {useMemo(() => {
                if (!success) {
                  return null
                }
                return <Paginate changePage={onChangePage} {...paginateState} />
              }, [success, paginateState])}
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  )
}

const createContentItem = (item) => {
  return [item.id, item.title, createItemActions(item)]
}

const createItemActions = (item : any) => [
  createEditButton(item.id, '/admin/editar-assinatura/'),
  buildButtonAction('Conteúdos', AddOutlined, '/admin/conteudos-assinatura/' + item.id),
  createAccessCodeButton(item.id, '/admin/codigos-de-acesso?subscription_id=')
]

const Search = ({ defaultText, onChange }) => {
  const [text, setText] = useState(defaultText || '')
  return (
    <div>
      <CustomInput
        labelText="Filtrar por título"
        id="search"
        formControlProps={{
          fullWidth: true
        }}
        inputProps={{
          defaultValue: text,
          onChange: (e) => setText(e.target.value)
        }}
      />
      <Button onClick={() => onChange(text)}>Filtrar</Button>
    </div>
  )
}

export default SubscriptionList
