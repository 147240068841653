import React, { useMemo, useState } from 'react'

import { IContentList } from '../../components/ContentProvider'

import GridItem from '../../components/Grid/GridItem'
import GridContainer from '../../components/Grid/GridContainer'
import Table from '../../components/Table/Table'
import Card from '../../components/Card/Card'
import CardHeader from '../../components/Card/CardHeader'
import CardBody from '../../components/Card/CardBody'
import Paginate from '../Paginate'
import CardFooter from '../Card/CardFooter'
import CustomInput from '../CustomInput/CustomInput'
import Button from '../CustomButtons/Button'

import VpnKeyIcon from '@material-ui/icons/VpnKey'
import EditIcon from '@material-ui/icons/Create'
import { Link } from 'react-router-dom';
import Loading from '../Loading/Loading'

export const buildButtonAction = (name, Icon, url) => {
  return (
    <Link to={url} key={url}>
      <Button
        variant="contained"
        color="grey"
        size="small"
      >
        <Icon /> {name}
      </Button>
    </Link>
  )
}

export const createEditButton = (id, urlBase) => buildButtonAction('Editar', EditIcon, urlBase + id)

export const createAccessCodeButton = (id, urlBase) => buildButtonAction('Códigos de Acesso', VpnKeyIcon, urlBase + id)

const createContentItem = (item, renderItemActions, renderRemoveAction, refreshList) => {
  const img = <img src={item.image.url} width={80} />
  const actions = renderItemActions(item)
  if (!renderRemoveAction) {
    return [img, item.id, item.title, actions]
  }
  const deleteAction = renderRemoveAction(item, refreshList)
  return [img, item.id, item.title, actions, deleteAction]
}

const ContentList: React.FC<IContentList> = (props) => {
  let {
    items, 
    paginateState, 
    changePage,
    changeText,
    loading,
    renderItemActions,
    renderRemoveAction,
    refreshList
  } = props
  const ListContent = useMemo(() => {
    items = items || []
    const data = items.map(item => createContentItem(item, renderItemActions, renderRemoveAction, refreshList))
    if (data.length === 0) {
      if (loading) {
        return null
      }
      return <p>Nenhum registro encontrado</p>
    }
    const tableHead = ['Imagem', 'Id', 'Título', 'Opções']
    if (renderRemoveAction) {
      tableHead.push('Remover')
    }
    return (
      <Table
        tableHeaderColor="primary"
        tableHead={tableHead}
        tableData={data}
      />
    )
  }, [items, loading])
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              <Loading message="Aguarde..." hide={!loading} />
              {ListContent}
            </CardBody>
            <CardFooter>
              <Paginate changePage={changePage} {...paginateState} />
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  )
}

export const Search = ({ defaultText, onChange }) => {
  const [text, setText] = useState(defaultText || '')
  return (
    <div>
      <CustomInput
        labelText="Filtrar por tag"
        id="search"
        formControlProps={{
          fullWidth: true
        }}
        inputProps={{
          defaultValue: text,
          onChange: (e) => setText(e.target.value)
        }}
      />
      <Button onClick={() => onChange(text)}>Filtrar</Button>
    </div>
  )
}

export default ContentList