import React, { useState, useCallback, useMemo, useEffect } from 'react'

import { subscriptionAdd } from '../../services/SubscriptionService'

import GridItem from '../../components/Grid/GridItem'
import GridContainer from '../../components/Grid/GridContainer'
import CustomInput from '../../components/CustomInput/CustomInput'
import Button from '../../components/CustomButtons/Button'
import Card from '../../components/Card/Card'
import CardBody from '../../components/Card/CardBody'
import CardFooter from '../../components/Card/CardFooter'
import CustomSwitch from '../../components/CustomSwitch/CustomSwitch'
import Loading from '../../components/Loading/Loading'
import Error from '../../components/Error/Error'
import Success from '../../components/Success/Success'

function SubscriptionAdd(props: any) {
  const [keyForm, setKeyForm] = useState('form')
  const [title, setTitle] = useState('')
  const [isActive, setIsActive] = useState(false)

  const [error, setError] = useState<any>(null)
  const [errors, setErrors] = useState<any>(null)
  const [success, setSuccess] = useState<any>(null)
  const [loading, setLoading] = useState(false)

  const resetForm = () => {
    setTitle('')
    setIsActive(false)
    setKeyForm('form' + Date.now())
  }

  const handleSubmit = useCallback(() => {
    const data: any = {
      title,
      active: isActive,
    }
    setLoading(true)
    setSuccess(false)
    setError(null)
    setErrors({})
    subscriptionAdd(data)
      .then(response => {
        if (response.type === 'error') {
          setLoading(false)
          setSuccess(false)
          setError(response.message)
          setErrors(response.details)
          return
        }
        setSuccess(response.message || 'Assinatura criada com sucesso')
        setError(null)
        setErrors({})
        setLoading(false)
        resetForm()
      })
      .catch(err => {
        setSuccess(false)
        setError(err.message)
        setLoading(false)
      })
  }, [title, isActive])
  
  function ErrorItem({ message }) {
    if (!message) return null
    return <span style={{color: 'red'}}>{message}</span>
  }

  return (
    <div key={keyForm}>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              <Success message={success} hide={!success} />
              <Error message={error} hide={!error} />
              <Loading message={"Aguarde..."} hide={!loading} />
              <GridContainer>
                <GridItem xs={12} sm={12} md={8}>
                  <CustomInput
                    labelText="Título"
                    id="title"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      defaultValue: title,
                      onChange: (e) => setTitle(e.target.value),
                      disabled: loading
                    }}
                    error={errors && errors.title}
                  />
                  <ErrorItem message={errors ? errors.title : null} />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomSwitch 
                    label="Ativo?"
                    checked={isActive}
                    onChange={setIsActive}
                    disabled={loading}
                  />
                  <ErrorItem message={errors ? errors.active : null} />
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter>
              <Button 
                onClick={handleSubmit} 
                color="primary"
              >Adicionar Assinatura</Button>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  )
}

export default SubscriptionAdd
