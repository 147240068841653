import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory, } from 'history';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { Provider, useSelector } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';

import createStore from './stores';

// core components
import Admin from './layouts/Admin';
import Login from './layouts/Login';

import 'assets/css/material-dashboard-react.css?v=1.6.0';

const { store, persistor } = createStore();
const hist = createBrowserHistory();

const App = () => {
  hist.listen((e, action) => {
    if (action === 'PUSH') {
      setTimeout(() => {
        window.scroll(0, 0)
      }, 200)
    }
  });
  const isLogged = useSelector<any>(state => state.auth.logged);
  const Routes = React.useMemo(() => {
    if (!isLogged) {
      return [
        <Route path="/login" component={Login} />,
        <Redirect from="/" to="/login" />
      ]
    }
    return [
      <Route path="/admin" component={Admin} />,
      <Redirect from="/" to="/admin/alimentos-diarios" />
    ]
  }, [isLogged]);
  return (
    <Router history={hist}>
      <Switch children={Routes} />
    </Router>
  );
}

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
,document.getElementById('root'));
