import { post, ResponseService } from './Service'

export async function uploadFile(url: string, file: File): Promise<ResponseService> {
  const data = new FormData()
  data.append('file', file)
  return await post(url, data, {
    default_error_message: 'Ocorreu um erro no upload do arquivo'
  })
}

export async function uploadBookCover(file: File): Promise<ResponseService> {
  return await uploadFile('/api/v1/admin/files/bookcover', file)
}

export async function uploadPdf(file: File): Promise<ResponseService> {
  return await uploadFile('/api/v1/admin/files/pdf', file)
}

export async function uploadAudio(file: File): Promise<ResponseService> {
  return await uploadFile('/api/v1/admin/files/audio', file)
}