export const REQUEST_RELEASE: any = 'REQUEST_RELEASE'
export const SUCCESS_RELEASE = 'SUCCESS_RELEASE'
export const ERROR_RELEASE = 'ERROR_RELEASE'
export const CLEAR_RELEASE = 'CLEAR_RELEASE'

export interface ReleaseState {
  id: number | string | null;
  release: Object | null;
  requesting: boolean;
  error: boolean;
  success: boolean;
  message: string | null;
}

export interface RequestReleaseParams {
  id: number | string;
}

export interface ErrorRequestRelease {
  message: string;
}

export interface ClearRelease {
}

export interface SuccessRequestRelease {
  release: Object;
}

export interface RequestReleaseAction {
  type: typeof REQUEST_RELEASE
  payload: RequestReleaseParams
}

export interface SuccessRequestReleaseAction {
  type: typeof SUCCESS_RELEASE
  data: SuccessRequestRelease
}

export interface ErrorRequestReleaseAction {
  type: typeof ERROR_RELEASE
  error: ErrorRequestRelease
}

export interface ClearReleaseAction {
  type: typeof CLEAR_RELEASE
}

export type ReleaseActions = RequestReleaseAction | SuccessRequestReleaseAction | ErrorRequestReleaseAction | ClearReleaseAction

export const ReleaseTypes = {
  REQUEST_RELEASE,
  SUCCESS_RELEASE,
  ERROR_RELEASE,
  CLEAR_RELEASE,
}