import { ReleaseState, ReleaseTypes } from './Types'

const initialState: ReleaseState = {
  id: null,
  release: null,
  requesting: false,
  error: false,
  success: false,
  message: null,
}

export function reducer(state = initialState, action: any): ReleaseState {
  switch (action.type) {
    case ReleaseTypes.REQUEST_RELEASE:
      return {
        id: action.payload.id,
        release: null,
        requesting: true,
        error: false,
        success: false,
        message: null
      }
    case ReleaseTypes.SUCCESS_RELEASE:
      return {
        ...state,
        release: action.data.release,
        requesting: false,
        error: false,
        success: true,
        message: null,
      }
    case ReleaseTypes.ERROR_RELEASE:
      return {
        ...state,
        release: null,
        requesting: false,
        error: true,
        success: false,
        message: action.error.message,
      }
    case ReleaseTypes.CLEAR_RELEASE:
      return {
        id: null,
        release: null,
        requesting: false,
        error: false,
        success: false,
        message: null,
      }
  }
  return state
}
