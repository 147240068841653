import authApiClient, { ResponseService } from './Service'
import { in200s } from './Utils'

async function registerUser({ name, email, password, repassword }): Promise<ResponseService> {
  if (!name || !email || !password || !repassword) {
    return {
      type: 'error',
      message: 'Preencha todos os campos',
    }
  }

  if (password !== repassword) {
    return {
      type: 'error',
      message: 'Senhas diferentes',
    }
  }
  const data = {
    name, 
    email, 
    password
  }
  let response: any = {};
  try {
    response = await authApiClient.post('api/v1/users', data)
  } catch(error) {
    if (error.response) {
      response = error.response
    }
  }

  if (in200s(response.status)) {
    return {
      type: 'success',
      message: response.data.message,
      details: response.data.user
    } as ResponseService
  }

  if (response.status === 400) {
    const data = response.data
    if (data instanceof Object 
      && data.errors instanceof Array
      && data.errors.length > 0) {
      return {
        type: 'error',
        message: data.errors[0].detail,
      } as ResponseService
    }
  }
  
  return {
    type: 'error',
    message: 'Ocorreu um erro no serviço de cadastro',
  } as ResponseService
}

export const registerService = {
  registerUser,
}
