import { combineReducers } from 'redux'

import configureStore from './CreateStore'
import rootSaga from '../sagas'

import { reducer as AuthReducer } from './Auth/Reducers'
import { reducer as BooksReducer } from './Books/Reducers'
import { reducer as BookReducer } from './Book/Reducers'
import { reducer as BookAddReducer } from './BookAdd/Reducers'
import { reducer as BookEditReducer } from './BookEdit/Reducers'
import { reducer as ContentsReducer } from './Contents/Reducers'
import { reducer as JavReducer } from './Jav/Reducers'
import { reducer as JavAddReducer } from './JavAdd/Reducers'
import { reducer as JavEditReducer } from './JavEdit/Reducers'
import { reducer as JavsReducer } from './Javs/Reducers'
import { reducer as RegisterReducer } from './Register/Reducers'
import { reducer as ReleaseReducer } from './Release/Reducers'
import { reducer as ReleaseAddReducer } from './ReleaseAdd/Reducers'
import { reducer as ReleaseEditReducer } from './ReleaseEdit/Reducers'
import { reducer as ReleasesReducer } from './Releases/Reducers'
import { reducer as SpiritualFoodAddReducer } from './SpiritualFoodAdd/Reducers'
import { reducer as SpiritualFoodEditReducer } from './SpiritualFoodEdit/Reducers'
import { reducer as SpiritualFoodFormReducer } from './SpiritualFoodForm/Reducers'
import { reducer as SpiritualFoodsReducer } from './SpiritualFoods/Reducers'
import { reducer as SpiritualFoodReducer } from './SpiritualFood/Reducers'
import { reducer as RequestRecoveryPasswordReducer } from './RequestRecoveryPassword/Reducers'
import { reducer as RecoveryPasswordReducer } from './RecoveryPassword/Reducers'

const reducers = {
  auth: AuthReducer,
  book: BookReducer,
  bookAdd: BookAddReducer,
  bookEdit: BookEditReducer,
  books: BooksReducer,
  contents: ContentsReducer,
  jav: JavReducer,
  javAdd: JavAddReducer,
  javEdit: JavEditReducer,
  javs: JavsReducer,
  spiritualFoods: SpiritualFoodsReducer,
  spiritualFood: SpiritualFoodReducer,
  spiritualFoodAdd: SpiritualFoodAddReducer,
  spiritualFoodEdit: SpiritualFoodEditReducer,
  spiritualFoodForm: SpiritualFoodFormReducer,
  register: RegisterReducer,
  release: ReleaseReducer,
  releaseAdd: ReleaseAddReducer,
  releaseEdit: ReleaseEditReducer,
  releases: ReleasesReducer,
  requestRecoveryPassword: RequestRecoveryPasswordReducer,
  recoveryPassword: RecoveryPasswordReducer,
}

export default () => {
  const rootReducer = combineReducers(reducers)
  return configureStore(rootReducer, rootSaga)
}
