import { in200s } from './Utils'
import authApiClient, { ResponseService } from './Service'

async function fetchAuth({username, password}): Promise<ResponseService> {
  if (!username || !password) {
    return {
      type: 'error',
      message: 'Preencha todos os campos',
    } as ResponseService
  }

  let response: any = {};
  try {
    response = await authApiClient.post('api/v1/auth', {
      email: username, 
      password
    })
  } catch(error) {
    if (error.response) {
      response = error.response
    }
  }

  if (in200s(response.status)) {
    const token = response.data.token
    localStorage.setItem("@app:token", token)
    let testIsAdminResponse: any = {}
    try {
      testIsAdminResponse = await authApiClient.get('/api/v1/admin/access-codes')
    } catch (err) {}
    if (in200s(testIsAdminResponse.status)) {
      return {
        type: 'success',
        message: 'Success!',
        details: response.data
      } as ResponseService
    } else {
      return {
        type: 'error',
        message: 'Usuário não é um administrador',
      } as ResponseService
    }
  }

  if (response.status === 401 || response.status === 400) {
    const data = response.data
    if (data instanceof Object 
      && data instanceof Array
      && data.length > 0) {
      return {
        type: 'error',
        message: data[0].detail || data[0].message,
      } as ResponseService
    }
  }

  return {
    type: 'error',
    message: 'Ocorreu um erro no serviço de autenticação',
  } as ResponseService
}

async function logout({ refreshToken }): Promise<any> {
  try {
    await authApiClient.post('api/v1/logout', {
      refreshToken
    })
  } catch(error) {
    console.log('logout', error.response)
  }
}

async function requestRecoveryPassword({ email }): Promise<any> {
  if (!email) {
    return {
      type: 'error',
      message: 'Preencha o campo',
    } as ResponseService
  }

  let response: any = {};
  try {
    response = await authApiClient.put('request-recovery-password', {
      email
    })
  } catch(error) {
    if (error.response) {
      response = error.response
    }
  }
  if (in200s(response.status)) {
    return {
      type: 'success',
      message: response.data.message,
      details: response.data,
    } as ResponseService
  }

  if (response.status === 400) {
    const data = response.data
    if (data instanceof Object 
      && data instanceof Array
      && data.length > 0) {
      return {
        type: 'error',
        message: data[0].detail || data[0].message,
      } as ResponseService
    }
  }

  return {
    type: 'error',
    message: 'Ocorreu um erro no serviço de recuperar senha',
  } as ResponseService
}

async function recoveryPassword({ code, password, repassword }): Promise<any> {
  if (!code) {
    return {
      type: 'error',
      message: 'Preencha o campo',
    } as ResponseService
  }

  if (password !== repassword) {
    return {
      type: 'error',
      message: 'Senhas diferentes',
    }
  }

  let response: any = {};
  try {
    response = await authApiClient.put('recovery-password', {
      code,
      password
    })
  } catch(error) {
    if (error.response) {
      response = error.response
    }
  }

  if (in200s(response.status)) {
    return {
      type: 'success',
      message: response.data.message,
      details: response.data,
    } as ResponseService
  }
  
  if (response.status === 400) {
    const data = response.data
    if (data instanceof Object 
      && typeof data.messages === 'string') {
      return {
        type: 'error',
        message: data.messages
      } as ResponseService
    }
  }

  if (response.status === 400) {
    const data = response.data
    if (data instanceof Object 
      && data instanceof Array
      && data.length > 0) {
      return {
        type: 'error',
        message: data[0].detail || data[0].message,
      } as ResponseService
    }
  }

  return {
    type: 'error',
    message: 'Ocorreu um erro no serviço de recuperar senha',
  } as ResponseService
}

export const authService = {
  recoveryPassword,
  requestRecoveryPassword,
  fetchAuth,
  logout
}
