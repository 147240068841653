import axios from 'axios';

import { Config } from '../config';
import { in200s } from './Utils';
import AuthActions from '../stores/Auth/Actions';

export interface ResponseService {
  type: 'error' | 'success'
  message?: string
  details?: any
}

const api = axios.create({
  baseURL: Config.API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  timeout: 1000000
})

api.interceptors.request.use(config => {
  const token = localStorage.getItem("@app:token")
  if (!!token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  config.headers.common["Accept-Language"] = "pt-BR"
  config.headers.common["Content-Type"] = "application/json"
  config.headers.common.Accept = "application/json"
  return config;
})

export async function post(url: string, params?: object, options?: { default_error_message?: string }): Promise<ResponseService> {
  return await request('post', url, params, options)
}

export async function get(url: string, params?: object | undefined, options?: { default_error_message?: string }): Promise<ResponseService> {
  return await request('get', url, params, options)
}

export async function request(method: any, url: string, params?: object, options?: { default_error_message?: string }): Promise<ResponseService> {
  let response: any = {};
  try {
    response = await api[method](url, params)
  } catch (error) {
    if (error.response) {
      response = error.response
    }
  }
  let message
  if (response.data instanceof Object
    && 'string' === typeof response.data.message) {
    message = response.data.message
  }
  if (in200s(response.status)) {
    return {
      type: 'success',
      details: response.data,
      message
    } as ResponseService
  }
  if (response.status === 400) {
    const data = response.data
    if (data instanceof Object
      && data.errors instanceof Array
      && data.errors.length > 0) {
      message = data.errors[0].detail
    }
  }
  if (!message) {
    message = !options || !options.default_error_message
      ? 'Ocorreu um erro na requisição'
      : options.default_error_message
  }
  return {
    type: 'error',
    message,
  } as ResponseService
}

export async function requestForm(method: any, url: string, params?: object, options?: { default_error_message?: string }): Promise<ResponseService> {
  let response: any = {};
  try {
    response = await api[method](url, params)
  } catch (error) {
    if (error.response) {
      response = error.response
    }
  }
  let message
  if (response.data instanceof Object
    && 'string' === typeof response.data.message) {
    message = response.data.message
  }
  if (in200s(response.status)) {
    return {
      type: 'success',
      details: response.data,
      message
    } as ResponseService
  }
  if (response.status === 400) {
    const data = response.data
    if (data.messages) {
      message = data.messages
    }
    let details = {}
    if (data.errors) {
      data.errors.forEach(error => {
        details[error.source.pointer] = error.detail
      })
    }
    return {
      type: 'error',
      message,
      details
    } as ResponseService
  }
  if (!message) {
    message = !options || !options.default_error_message
      ? 'Ocorreu um erro na requisição'
      : options.default_error_message
  }
  return {
    type: 'error',
    message,
  } as ResponseService
}

async function refreshToken(originalRequest: any, dispatch: any, getState: any) {
  let state = getState()
  let refreshToken = state.auth.refreshToken
  if (!refreshToken) {
    throw new Error('Token not found')
  }
  let response = await api.post('api/v1/auth/token/refresh', {
    refresh_token: refreshToken
  })
  if (!response.data || response.status !== 200) {
    throw new Error()
  }
  const dataToken = response.data.token
  refreshToken = response.data.refreshToken
  api.defaults.headers.common.Authorization = `Bearer ${dataToken}`
  originalRequest.headers.Authorization = `Bearer ${dataToken}`
  dispatch(AuthActions.successRefreshAuthorization(response.data))
  return api.request(originalRequest)
}

export function setToken(token: any) {
  localStorage.setItem("@app:token", token)
}

export function createRefreshTokenInterceptor(dispatch: any, getState: any) {
  api.interceptors.response.use(response => response, error => {
    return new Promise((resolve, reject) => {
      const originalRequest = error.config
      if (!error.response || error.response.status !== 401 || originalRequest._retry) {
        return reject(error)
      }
      dispatch(AuthActions.requestLogout())
      reject(error)
    })
  })
}

export default api;
