import {
  REQUEST_RELEASE,
  SUCCESS_RELEASE,
  ERROR_RELEASE,
  CLEAR_RELEASE,
  ReleaseActions,
  RequestReleaseParams,
  SuccessRequestRelease,
  ErrorRequestRelease
} from './Types'

export function requestRelease(params: RequestReleaseParams): ReleaseActions {
  return {
    type: REQUEST_RELEASE,
    payload: params,
  }
}

export function successRequestRelease(response: SuccessRequestRelease): ReleaseActions {
  return {
    type: SUCCESS_RELEASE,
    data: response,
  }
}

export function errorRequestRelease(response: ErrorRequestRelease): ReleaseActions {
  return {
    type: ERROR_RELEASE,
    error: response,
  }
}

export function clearRelease(): ReleaseActions {
  return {
    type: CLEAR_RELEASE
  }
}

export default {
  requestRelease,
  clearRelease,
  successRequestRelease,
  errorRequestRelease,
}
