import { put, call } from 'redux-saga/effects'
import { releasesService } from '../services/ReleasesService'

import ReleaseActions from '../stores/Release/Actions'
import ReleaseEditActions from '../stores/ReleaseEdit/Actions'
import ReleaseAddActions from '../stores/ReleaseAdd/Actions'

import ReleasesActions from '../stores/Releases/Actions'
import { RequestReleasesAction } from '../stores/Releases/Types'

import { ResponseService } from '../services/Service'
import { RequestReleaseAddAction } from '../stores/ReleaseAdd/Types'
import { RequestReleaseAction } from '../stores/Release/Types'
import { RequestReleaseEditAction } from '../stores/ReleaseEdit/Types'

export function* getReleases(action: RequestReleasesAction) {
  const response: ResponseService = yield call<any>(releasesService.fetchReleases, action.payload)
  if (response.type === 'success') {
    let releases = response.details.data
    let lastPage = Number(response.details.lastPage) || 0
    let page = Number(response.details.page) || 0
    let total = Number(response.details.total) || 0
    let perPage = Number(response.details.perPage) || 0
    let data = {
      key: action.payload.key,
      releases,
      lastPage,
      page,
      total,
      perPage
    }
    yield put(ReleasesActions.successRequestReleases(data))
  } else {
    let message = response.message || ''
    yield put(
      ReleasesActions.errorRequestReleases({
        key: action.payload.key,
        message,
      })
    )
  }
}

export function* releaseAdd(action: RequestReleaseAddAction) {
  const response: ResponseService = yield call<any>(releasesService.releaseAdd, action.payload)
  if (response.type === 'success') {
    yield put(ReleaseAddActions.successRequestReleaseAdd({
      release: response.details,
      message: 'Lançamento adicionado com sucesso'
    }))
  } else {
    let message = response.message || ''
    yield put(
      ReleaseAddActions.errorRequestReleaseAdd({
        errors: response.details,
        message,
      })
    )
  }
}


export function* getRelease(action: RequestReleaseAction) {
  const response: ResponseService = yield call<any>(releasesService.fetchRelease, action.payload.id)
  if (response.type === 'success') {
    let data = {
      release: response.details
    }
    yield put(ReleaseActions.successRequestRelease(data))
  } else {
    let message = response.message || ''
    yield put(
      ReleaseActions.errorRequestRelease({
        message
      })
    )
  }
}

export function* releaseEdit(action: RequestReleaseEditAction) {
  const response: ResponseService = yield call<any>(releasesService.releaseEdit, action.payload)
  if (response.type === 'success') {
    yield put(ReleaseEditActions.successRequestReleaseEdit({
      release: response.details,
      message: 'Lançamento editado com sucesso'
    }))
  } else {
    let message = response.message || ''
    yield put(
      ReleaseEditActions.errorRequestReleaseEdit({
        errors: response.details,
        message,
      })
    )
  }
}

