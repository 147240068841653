export const REQUEST_SPIRITUAL_FOODS: any = 'REQUEST_SPIRITUAL_FOODS'
export const SUCCESS_SPIRITUAL_FOODS = 'SUCCESS_SPIRITUAL_FOODS'
export const ERROR_SPIRITUAL_FOODS = 'ERROR_SPIRITUAL_FOODS'
export const CLEAR_SPIRITUAL_FOODS = 'CLEAR_SPIRITUAL_FOODS'


export interface SpiritualFoodsStateItem {
  key: string;
  spiritualFoods: Array<Object>;
  search: string;
  page: number;
  lastPage: number;
  limit: number;
  requesting: boolean;
  error: boolean;
  success: boolean;
  message: string;
}

export interface SpiritualFoodsState {
  items: Array<SpiritualFoodsStateItem> 
}

export interface RequestSpiritualFoodsParams {
  key: string;
  page: number;
  search?: string;
  limit?: number;
  clear?: boolean;
}

export interface ErrorRequestSpiritualFoods {
  key: string;
  message: string;
}

export interface ClearSpiritualFoods {
  key: string;
}

export interface SuccessRequestSpiritualFoods {
  key: string;
  spiritualFoods: Array<Object>;
  lastPage: number;
  page: number;
  total: number;
  perPage: number;
}

export interface RequestSpiritualFoodsAction {
  type: typeof REQUEST_SPIRITUAL_FOODS
  payload: RequestSpiritualFoodsParams
}

export interface SuccessRequestSpiritualFoodsAction {
  type: typeof SUCCESS_SPIRITUAL_FOODS
  data: SuccessRequestSpiritualFoods
}

export interface ErrorRequestSpiritualFoodsAction {
  type: typeof ERROR_SPIRITUAL_FOODS
  error: ErrorRequestSpiritualFoods
}

export interface ClearSpiritualFoodsAction {
  type: typeof CLEAR_SPIRITUAL_FOODS,
  clear: ClearSpiritualFoods
}

export type SpiritualFoodsActions = RequestSpiritualFoodsAction | SuccessRequestSpiritualFoodsAction | ErrorRequestSpiritualFoodsAction | ClearSpiritualFoodsAction

export const SpiritualFoodsTypes = {
  REQUEST_SPIRITUAL_FOODS,
  SUCCESS_SPIRITUAL_FOODS,
  ERROR_SPIRITUAL_FOODS,
  CLEAR_SPIRITUAL_FOODS,
}