import React, { useState } from 'react'

import { downloadAccessCode, DownloadParams } from '../../services/AccessCodeService'
import Button from '../../components/CustomButtons/Button'

interface IAccessCoodeDownload {
  params: DownloadParams
}

const AccessCodeDownload: React.FC<IAccessCoodeDownload> = ({ params }) => {
  const [disabled, setDisabled] = useState(false)
  const handleClick = () => {
    setDisabled(true)
    downloadAccessCode(params)
      .finally(() => {
        setDisabled(false)
      })
  }
  return (
    <Button
      color="primary"
      disabled={disabled}
      onClick={handleClick} 
    >Baixar códigos</Button>
  )
}

export default AccessCodeDownload
