export const REQUEST_RELEASE_EDIT: any = 'REQUEST_RELEASE_EDIT'
export const SUCCESS_RELEASE_EDIT = 'SUCCESS_RELEASE_EDIT'
export const ERROR_RELEASE_EDIT = 'ERROR_RELEASE_EDIT'
export const CLEAR_RELEASE_EDIT = 'CLEAR_RELEASE_EDIT'

export interface Release {
  id: string;
  title: string;
}

export interface ReleaseEditState {
  data: RequestReleaseEditParams | Release | null;
  requesting: boolean;
  errors: Object | null;
  success: boolean;
  message: string | null;
}

export interface RequestReleaseEditParams {
  id: string | number;
  title: string;
  link: string;
  tags: Array<string>;
  image: File | number;
}

export interface ErrorRequestReleaseEdit {
  errors: Array<{ name, message }>;
  message: string;
}

export interface SuccessRequestReleaseEdit {
  message: string;
  release: Release;
}

export interface RequestReleaseEditAction {
  type: typeof REQUEST_RELEASE_EDIT
  payload: RequestReleaseEditParams
}

export interface SuccessRequestReleaseEditAction {
  type: typeof SUCCESS_RELEASE_EDIT
  data: SuccessRequestReleaseEdit
}

export interface ErrorRequestReleaseEditAction {
  type: typeof ERROR_RELEASE_EDIT
  error: ErrorRequestReleaseEdit
}

export interface ClearReleaseEditAction {
  type: typeof CLEAR_RELEASE_EDIT
}

export type ReleaseEditActions = RequestReleaseEditAction | SuccessRequestReleaseEditAction | ErrorRequestReleaseEditAction | ClearReleaseEditAction

export const ReleaseEditTypes = {
  REQUEST_RELEASE_EDIT,
  SUCCESS_RELEASE_EDIT,
  ERROR_RELEASE_EDIT,
  CLEAR_RELEASE_EDIT,
}