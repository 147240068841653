import React, { useState, useMemo, useEffect } from 'react'

import { fetchSubscription } from '../../services/SubscriptionService'

import Error from '../Error/Error'
import Loading from '../Loading/Loading'

function SubscriptionProvider({ id, ContentComponent }) {
  const [subscription, setSubscription] = useState<any>(null)
  const [error, setError] = useState<any>(null)
  const [loading, setLoading] = useState<any>(true)

  useEffect(() => {
    fetchSubscription(id)
      .then(response => {
        setLoading(false)
        if (response.type === 'error') {
          setError(response.message)
          return
        }
        setSubscription(response.details)
      })
      .catch(err => {
        setLoading(false)
        setError(err.message)
      })
  }, [])

  return useMemo(() => {
    if (error) {
      return <Error message={error} hide={false} />
    }
    if (loading) {
      return <Loading message="Aguarde..." hide={false} />
    }
    if (!subscription) {
      return null
    }
    return <ContentComponent subscription={subscription} />
  }, [subscription, error, loading])
}

export default SubscriptionProvider
