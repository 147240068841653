import {
  REQUEST_SPIRITUAL_FOOD_ADD,
  SUCCESS_SPIRITUAL_FOOD_ADD,
  ERROR_SPIRITUAL_FOOD_ADD,
  CLEAR_SPIRITUAL_FOOD_ADD,
  SpiritualFoodAddActions,
  RequestSpiritualFoodAddParams,
  SuccessRequestSpiritualFoodAdd,
  ErrorRequestSpiritualFoodAdd
} from './Types'

export function requestSpiritualFoodAdd(params: RequestSpiritualFoodAddParams): SpiritualFoodAddActions {
  return {
    type: REQUEST_SPIRITUAL_FOOD_ADD,
    payload: params,
  }
}

export function successRequestSpiritualFoodAdd(response: SuccessRequestSpiritualFoodAdd): SpiritualFoodAddActions {
  return {
    type: SUCCESS_SPIRITUAL_FOOD_ADD,
    data: response,
  }
}

export function errorRequestSpiritualFoodAdd(response: ErrorRequestSpiritualFoodAdd): SpiritualFoodAddActions {
  return {
    type: ERROR_SPIRITUAL_FOOD_ADD,
    error: response,
  }
}

export function clearSpiritualFoodAdd(): SpiritualFoodAddActions {
  return {
    type: CLEAR_SPIRITUAL_FOOD_ADD
  }
}

export default {
  requestSpiritualFoodAdd,
  clearSpiritualFoodAdd,
  successRequestSpiritualFoodAdd,
  errorRequestSpiritualFoodAdd,
}
