import React, { useState, useCallback, useMemo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { JavEditState } from '../../stores/JavEdit/Types'
import { requestJavEdit, clearJavEdit } from '../../stores/JavEdit/Actions'

import { JavState } from '../../stores/Jav/Types'
import { requestJav, clearJav } from '../../stores/Jav/Actions'

import GridItem from '../../components/Grid/GridItem'
import GridContainer from '../../components/Grid/GridContainer'
import CustomInput from '../../components/CustomInput/CustomInput'
import Button from '../../components/CustomButtons/Button'
import Card from '../../components/Card/Card'
import CardBody from '../../components/Card/CardBody'
import CardFooter from '../../components/Card/CardFooter'
import CustomSwitch from '../../components/CustomSwitch/CustomSwitch'
import CustomTagsInput from '../../components/CustomInput/CustomTagsInput'
import CustomFileUpload from '../../components/CustomFileUpload/CustomFileUpload'
import Loading from '../../components/Loading/Loading'
import SnackbarContent from '../../components/Snackbar/SnackbarContent'

function JavForm() {
  const dispatch = useDispatch()
  const javState = useSelector<any>(state => state.jav) as JavState
  const javEditState = useSelector<any>(state => state.javEdit) as JavEditState

  const jav: any = javState.jav
  const [title, setTitle] = useState(jav.title)
  const [youtubeLink, setYoutubeLink] = useState(jav.youtube_link)
  const [isActive, setIsActive] = useState(jav.active)
  const [isPublic, setIsPublic] = useState(jav.is_public)
  const [tags, setTags] = useState(jav.tags.map(t => t.title))
  const [image, setImage] = useState(jav.image.id)
  const [pdf, setPdf] = useState(jav.pdf.id)

  useEffect(() => {
    return () => {
      dispatch(clearJavEdit())
    }
  }, [dispatch])

  useEffect(() => {
    if (javEditState.success) {
      setTimeout(() => {
        try {
          dispatch(requestJav({ id: jav.id }))
        } catch(err) {}
      }, 1000)
    }
  }, [dispatch, jav.id, javEditState.success])

  const handleSubmit = useCallback(() => {
    const data = {
      id: jav.id,
      title,
      active: isActive,
      is_public: isPublic,
      youtube_link: youtubeLink,
      tags,
      image,
      pdf
    }
    dispatch(requestJavEdit(data))
  }, [jav.id, title, isActive, isPublic, youtubeLink, tags, image, pdf, dispatch])

  const disabled = useMemo(() => javEditState.requesting, [javEditState.requesting])
  const errors: any = useMemo(() => javEditState.errors, [javEditState.errors])
  
  const Message = useMemo(() => {
    if (!javEditState.message) {
      return null
    }
    return (
      <SnackbarContent 
        color={javEditState.success ? 'success': 'danger'} 
        message={javEditState.message} 
      />
    )
  }, [javEditState.message, javEditState.success])

  function ErrorItem({ message }) {
    if (!message) return null
    return <span style={{color: 'red'}}>{message}</span>
  }

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              <h4><b>#{jav.id} - {jav.title}</b></h4>
              <Loading
                message="Aguarde um momento ..."
                hide={!disabled}
              />
              {Message}
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Título"
                    id="title"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      defaultValue: title,
                      onChange: (e) => setTitle(e.target.value),
                      disabled
                    }}
                    error={errors && errors.title}
                  />
                  <ErrorItem message={errors ? errors.title : null} />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Link do Youtube"
                    id="link"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      defaultValue: youtubeLink,
                      onChange: (e) => setYoutubeLink(e.target.value),
                      disabled
                    }}
                    error={errors && errors.youtube_link}
                  />
                  <ErrorItem message={errors && errors.youtube_link} />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomSwitch 
                    label="Público?"
                    checked={isPublic}
                    onChange={setIsPublic}
                    disabled={disabled}
                  />
                  <ErrorItem message={errors ? errors.is_public : null} />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomSwitch 
                    label="Ativo?"
                    checked={isActive}
                    onChange={setIsActive}
                    disabled={disabled}
                  />
                  <ErrorItem message={errors ? errors.active : null} />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomTagsInput
                    defaultTags={tags}
                    onChange={setTags}
                    disabled={disabled}
                  />
                  <ErrorItem message={errors ? errors.tags : null} />
                </GridItem>
              </GridContainer>
              <div style={{marginTop: '30px'}} />
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomFileUpload
                    title={<a href={jav.image.url} target="blank">{`Trocar Imagem ${jav.image.name}`}</a>}
                    acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
                    onChange={files => {
                      const file = files && files.length > 0 ? files[0] : null
                      setImage(file)
                    }}
                    disabled={disabled}
                  />
                  <ErrorItem message={errors ? errors.image_id : null} />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomFileUpload 
                    title={<a href={jav.pdf.url} target="blank">{`Trocar PDF ${jav.pdf.name}`}</a>}
                    acceptedFiles={['application/pdf']}
                    onChange={files => {
                      const file = files && files.length > 0 ? files[0] : null
                      setPdf(file)
                    }}
                    disabled={disabled}
                  />
                  <ErrorItem message={errors ? errors.pdf_id : null} />
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter>
              <Button 
                onClick={handleSubmit} 
                color="primary"
              >Editar JAV</Button>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  )
}

const JavEdit = (props) => {
  const id = props.match.params.id
  const dispatch = useDispatch()
  const javState = useSelector<any>(state => state.jav) as JavState

  useEffect(() => {
    dispatch(requestJav({ id }))
    return () => {
      dispatch(clearJav())
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return useMemo(() => {
    if (javState.success) {
      return <JavForm />
    }
    if (javState.error) {
      return (
        <SnackbarContent 
          color="danger" 
          message={javState.message}
        />
      )
    }
    return <Loading hide={false}  message="Aguarde ..." />
  }, [javState])
}

export default JavEdit
