import { applyMiddleware, compose, createStore, Store } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { persistReducer, persistStore } from 'redux-persist'
import { createRefreshTokenInterceptor } from '../services/Service'
import storage from 'redux-persist/lib/storage'

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['books', 'spiritualFoods', 'javs', 'releases', 'contents'],
  whitelist: ['auth', 'ebooksOffline']
}

export default (rootReducer: any, rootSaga: any) => {
  const middleware: any = []
  const enhancers: any = []

  const sagaMiddleware: any = createSagaMiddleware<any>()
  middleware.push(sagaMiddleware)

  enhancers.push(applyMiddleware(...middleware))

  const persistedReducer = persistReducer(persistConfig, rootReducer)

  const store: Store<any> = createStore(persistedReducer, compose(...enhancers))
  const persistor = persistStore(store)

  createRefreshTokenInterceptor(store.dispatch, store.getState)

  sagaMiddleware.run(rootSaga)

  return {
    store,
    persistor
  }
}
