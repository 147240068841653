import React from 'react'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import SnackbarContent from '../Snackbar/SnackbarContent'

export default function Success({ message, hide, onClick = () => {} }) {
  if (!!hide) {
    return null
  }
  return (
    <SnackbarContent
      onClick={onClick}
      color="success"
      icon={CheckCircleIcon} 
      message={message}
    />
  )
}
