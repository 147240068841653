import {
  REQUEST_JAV_EDIT,
  SUCCESS_JAV_EDIT,
  ERROR_JAV_EDIT,
  CLEAR_JAV_EDIT,
  JavEditActions,
  RequestJavEditParams,
  SuccessRequestJavEdit,
  ErrorRequestJavEdit
} from './Types'

export function requestJavEdit(params: RequestJavEditParams): JavEditActions {
  return {
    type: REQUEST_JAV_EDIT,
    payload: params,
  }
}

export function successRequestJavEdit(response: SuccessRequestJavEdit): JavEditActions {
  return {
    type: SUCCESS_JAV_EDIT,
    data: response,
  }
}

export function errorRequestJavEdit(response: ErrorRequestJavEdit): JavEditActions {
  return {
    type: ERROR_JAV_EDIT,
    error: response,
  }
}

export function clearJavEdit(): JavEditActions {
  return {
    type: CLEAR_JAV_EDIT
  }
}

export default {
  requestJavEdit,
  clearJavEdit,
  successRequestJavEdit,
  errorRequestJavEdit,
}
