import { takeLatest, all } from 'redux-saga/effects'

import { AuthTypes } from '../stores/Auth'
import { login, logout } from './AuthSaga'

import { BookTypes } from '../stores/Book'
import { BooksTypes } from '../stores/Books'
import { BookAddTypes } from '../stores/BookAdd'
import { BookEditTypes } from '../stores/BookEdit'
import { getBooks, getBook, bookAdd, bookEdit } from './BooksSaga'

import { ContentsTypes } from '../stores/Contents'
import { getContents } from './ContentsSaga'

import { JavTypes } from '../stores/Jav'
import { JavsTypes } from '../stores/Javs'
import { JavAddTypes } from '../stores/JavAdd'
import { JavEditTypes } from '../stores/JavEdit'
import { getJav, getJavs, javAdd, javEdit } from './JavsSaga'

import { RegisterTypes } from '../stores/Register'
import { registerUser } from './RegisterSaga'

import { ReleaseAddTypes } from '../stores/ReleaseAdd'
import { ReleasesTypes } from '../stores/Releases'
import { ReleaseTypes } from '../stores/Release'
import { ReleaseEditTypes } from '../stores/ReleaseEdit'
import { getReleases, getRelease, releaseAdd, releaseEdit } from './ReleasesSaga'

import { RequestRecoveryPasswordTypes } from '../stores/RequestRecoveryPassword'
import { requestRecoveryPassword } from './RequestRecoveryPasswordSaga'

import { RecoveryPasswordTypes } from '../stores/RecoveryPassword'
import { recoveryPassword } from './RecoveryPasswordSaga'

import { SpiritualFoodsTypes } from '../stores/SpiritualFoods'
import { SpiritualFoodTypes } from '../stores/SpiritualFood'
import { SpiritualFoodAddTypes } from '../stores/SpiritualFoodAdd'
import { SpiritualFoodEditTypes } from '../stores/SpiritualFoodEdit'
import { getSpiritualFoods, getSpiritualFood, spiritualFoodAdd, spiritualFoodEdit } from './SpiritualFoodsSaga'

export default function* root() {
  yield all([
    /**
     * @see https://redux-saga.js.org/docs/basics/UsingSagaHelpers.html
     */
    // Run the startup saga when the application starts

    takeLatest(AuthTypes.REQUEST_AUTH, login),
    takeLatest(AuthTypes.REQUEST_LOGOUT, logout),

    takeLatest(BookAddTypes.REQUEST_BOOK_ADD, bookAdd),
    takeLatest(BookEditTypes.REQUEST_BOOK_EDIT, bookEdit),
    takeLatest(BookTypes.REQUEST_BOOK, getBook),
    takeLatest(BooksTypes.REQUEST_BOOKS, getBooks),
    
    takeLatest(JavEditTypes.REQUEST_JAV_EDIT, javEdit),
    takeLatest(JavTypes.REQUEST_JAV, getJav),
    takeLatest(JavsTypes.REQUEST_JAVS, getJavs),
    takeLatest(JavAddTypes.REQUEST_JAV_ADD, javAdd),

    takeLatest(ContentsTypes.REQUEST_CONTENTS, getContents),

    takeLatest(RegisterTypes.REQUEST_REGISTER, registerUser),

    takeLatest(ReleaseAddTypes.REQUEST_RELEASE_ADD, releaseAdd),
    takeLatest(ReleaseEditTypes.REQUEST_RELEASE_EDIT, releaseEdit),
    takeLatest(ReleaseTypes.REQUEST_RELEASE, getRelease),
    takeLatest(ReleasesTypes.REQUEST_RELEASES, getReleases),
    
    takeLatest(RequestRecoveryPasswordTypes.REQUEST_REQUEST_RECOVERY_PASSWORD, requestRecoveryPassword),
   
    takeLatest(RecoveryPasswordTypes.REQUEST_RECOVERY_PASSWORD, recoveryPassword),

    takeLatest(SpiritualFoodsTypes.REQUEST_SPIRITUAL_FOODS, getSpiritualFoods),
    takeLatest(SpiritualFoodTypes.REQUEST_SPIRITUAL_FOOD, getSpiritualFood),
    takeLatest(SpiritualFoodAddTypes.REQUEST_SPIRITUAL_FOOD_ADD, spiritualFoodAdd),
    takeLatest(SpiritualFoodEditTypes.REQUEST_SPIRITUAL_FOOD_EDIT, spiritualFoodEdit),
  ])
}
