import React, { useState, useMemo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Actions } from '../../stores/SpiritualFoodForm'
import Button from '../../components/CustomButtons/Button'
import VerseForm from './VerseForm'

const VerseFormList = () => {
  const dispatch = useDispatch()
  const state: any = useSelector<any>(state => state.spiritualFoodForm)
  const disabled = state.disabled
  const day = state.weeks[state.currentWeekIndex].days[state.currentDayIndex]
  const verses = day.verses

  const handleAddVerse = useCallback(() => {
    dispatch(Actions.addVerse())
  }, [verses])
  
  const handleRemoveVerse = useCallback(i => {
    dispatch(Actions.removeVerse(i))
  }, [verses])
  
  const handleUpdateVerse = useCallback((verse, index) => {
    dispatch(Actions.setVerse(verse, index))
  }, [verses])

  const items = useMemo(() => verses.map((verse, i) => {
    return (
      <div key={i + '-' + verses.length}>
        <VerseForm
          key={i + '-' + verses.length}
          index={i}
          verse={verse}
          onChangeVerse={verse => handleUpdateVerse(verse, i)}
        />
        <Button
          disabled={disabled}
          color="danger" 
          style={{marginLeft: '10px'}} 
          onClick={() => handleRemoveVerse(i)}
        >Remover Versículo</Button>
      </div>
    )
  }), [verses, disabled])

  return (
    <div>
      <h5>Lista de Versículos</h5>
      {items}
      <Button
        disabled={disabled}
        style={{marginTop: '40px'}} 
        onClick={handleAddVerse}
      >Adicionar Versículo</Button>
    </div>
  )
}

export default VerseFormList
