import React, { useState, useEffect, useMemo } from 'react'

import { subscriptionContentAdd } from '../../services/SubscriptionService'

import SelectTypeContent from './SelectTypeContent'

import Card from '../../components/Card/Card'
import CardBody from '../../components/Card/CardBody'
import CardHeader from '../../components/Card/CardHeader'
import GridContainer from '../../components/Grid/GridContainer'
import GridItem from '../../components/Grid/GridItem'
import AutocompleteContent from './AutocompleteContent'
import CardFooter from '../../components/Card/CardFooter'
import Button from '../../components/CustomButtons/Button'
import Success from '../../components/Success/Success'
import Error from '../../components/Error/Error'
import Loading from '../../components/Loading/Loading'


const SubscriptionContentAdd = ({ subscription, onSuccess }) => {
  const [key, setKey] = useState<any>('key')
  const [error, setError] = useState<any>(null)
  const [success, setSuccess] = useState<any>(null)
  const [loading, setLoading] = useState(false)
  const [typeContent, setTypeContent] = useState(null)
  const [contentId, setContentId] = useState(null)

  const disabled = useMemo(() => {
    return !contentId || !typeContent || loading
  }, [contentId, typeContent, loading])
  
  useEffect(() => {
    setContentId(null)
  }, [typeContent])

  useEffect(() => {
    if (success) {
      setTypeContent(null)
      setContentId(null)
      setKey('key' + Date.now())
    }
  }, [success])

  const handleSubmit = () => {
    setLoading(true)
    setSuccess(false)
    setError(null)
    const payload = { 
      subscriptionId: subscription.id,
      contentId,
      typeContent
    }
    subscriptionContentAdd(payload)
    .then(response => {
      if (response.type === 'error') {
        setLoading(false)
        setSuccess(false)
        setError(response.message)
        return
      }
      setSuccess(response.message || 'Assinatura criada com sucesso')
      setError(null)
      setLoading(false)
      onSuccess()
    })
    .catch(err => {
      setSuccess(false)
      setError(err.message)
      setLoading(false)
    })
  }

  return (
    <Card key={key}>
      <CardHeader>
        <h4 style={{ margin: 0 }}>Adicionar Conteúdo</h4>
      </CardHeader>
      <CardBody>
        <Success message={success} hide={!success} onClick={() => setSuccess(null)} />
        <Error message={error} hide={!error} onClick={() => setError(null)} />
        <Loading message={"Aguarde..."} hide={!loading} />
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <span>Tipo de conteúdo</span>
            <SelectTypeContent onChange={setTypeContent} />
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <span>Contéudo</span>
            <AutocompleteContent 
              key={'' + typeContent}
              typeContent={typeContent} 
              onSelected={setContentId} 
            />
          </GridItem>
        </GridContainer>
      </CardBody>
      <CardFooter>
        <Button
          color="primary"
          disabled={disabled}
          onClick={handleSubmit} 
        >Adicionar Conteúdo</Button>
      </CardFooter>
    </Card>
  )
}

export default SubscriptionContentAdd
