export const REQUEST_BOOK_ADD: any = 'REQUEST_BOOK_ADD'
export const SUCCESS_BOOK_ADD = 'SUCCESS_BOOK_ADD'
export const ERROR_BOOK_ADD = 'ERROR_BOOK_ADD'
export const CLEAR_BOOK_ADD = 'CLEAR_BOOK_ADD'

export interface Book {
  id: string;
  title: string;
}

export interface BookAddState {
  data: RequestBookAddParams | Book | null;
  requesting: boolean;
  errors: Object | null;
  success: boolean;
  message: string | null;
}

export interface RequestBookAddParams {
  title: string;
  active: boolean;
  is_public: boolean;
  tags: Array<string>;
  image: File | number;
  pdf: File | number;
}

export interface ErrorRequestBookAdd {
  errors: Object;
  message: string;
}

export interface SuccessRequestBookAdd {
  message: string;
  book: Book;
}

export interface RequestBookAddAction {
  type: typeof REQUEST_BOOK_ADD
  payload: RequestBookAddParams
}

export interface SuccessRequestBookAddAction {
  type: typeof SUCCESS_BOOK_ADD
  data: SuccessRequestBookAdd
}

export interface ErrorRequestBookAddAction {
  type: typeof ERROR_BOOK_ADD
  error: ErrorRequestBookAdd
}

export interface ClearBookAddAction {
  type: typeof CLEAR_BOOK_ADD
}

export type BookAddActions = RequestBookAddAction | SuccessRequestBookAddAction | ErrorRequestBookAddAction | ClearBookAddAction

export const BookAddTypes = {
  REQUEST_BOOK_ADD,
  SUCCESS_BOOK_ADD,
  ERROR_BOOK_ADD,
  CLEAR_BOOK_ADD,
}