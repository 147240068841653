import { SpiritualFoodState, SpiritualFoodTypes } from './Types'

const initialState: SpiritualFoodState = {
  id: null,
  spiritualFood: null,
  requesting: false,
  error: false,
  success: false,
  message: null,
}

export function reducer(state = initialState, action: any): SpiritualFoodState {
  switch (action.type) {
    case SpiritualFoodTypes.REQUEST_SPIRITUAL_FOOD:
      return {
        id: action.payload.id,
        spiritualFood: null,
        requesting: true,
        error: false,
        success: false,
        message: null
      }
    case SpiritualFoodTypes.SUCCESS_SPIRITUAL_FOOD:
      return {
        ...state,
        spiritualFood: action.data.spiritualFood,
        requesting: false,
        error: false,
        success: true,
        message: null,
      }
    case SpiritualFoodTypes.ERROR_SPIRITUAL_FOOD:
      return {
        ...state,
        spiritualFood: null,
        requesting: false,
        error: true,
        success: false,
        message: action.error.message,
      }
    case SpiritualFoodTypes.CLEAR_SPIRITUAL_FOOD:
      return {
        id: null,
        spiritualFood: null,
        requesting: false,
        error: false,
        success: false,
        message: null,
      }
  }
  return state
}
