import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createStyles, withStyles } from '@material-ui/core'

import { Actions } from '../../stores/SpiritualFoodForm'
import { createDay } from '../../stores/SpiritualFoodForm/Reducers'

import Button from '../../components/CustomButtons/Button'
import GridItem from '../../components/Grid/GridItem'
import GridContainer from '../../components/Grid/GridContainer'
import CustomInput from '../../components/CustomInput/CustomInput'
import CustomFileUpload from '../../components/CustomFileUpload/CustomFileUpload'
import Card from '../../components/Card/Card'
import CardBody from '../../components/Card/CardBody'

import VerseFormList from './VerseFormList'
import ErrorItem, { useSpiritualFoodFormDayError } from './ErrorItem'

const DayFormComponent = () => {
  const dispatch = useDispatch()
  const state: any = useSelector<any>(state => state.spiritualFoodForm)
  const disabled = state.disabled
  const day = state.weeks[state.currentWeekIndex].days[state.currentDayIndex]

  const handleAddDay = () => {
    let day = createDay(state.currentDayIndex + 1)
    dispatch(Actions.setCurrentDayValue(day))
  }

  if (!day) {
    if (state.currentDayIndex > 0 && !state.weeks[state.currentWeekIndex].days[state.currentDayIndex - 1]) {
      return (
        <div style={{textAlign: 'center', marginTop: '20px' }}>
          <Button disabled>Para configurar esse dia, preencha o anterior</Button>
        </div>
      )
    }
    return (
      <div style={{textAlign: 'center', marginTop: '20px' }}>
        <Button disabled={disabled} onClick={handleAddDay}>Configurar dia</Button>
      </div>
    )
  }

  return <DayFormStyled />
}

const DayForm = ({ classes }) => {
  const dispatch = useDispatch()
  const state: any = useSelector<any>(state => state.spiritualFoodForm)
  const disabled = state.disabled
  const day = state.weeks[state.currentWeekIndex].days[state.currentDayIndex]
  const key = state.currentWeekIndex + '-' + state.currentDayIndex

  const onChange = (column, value) => {
    dispatch(Actions.setCurrentDayColumn(column, value ))
  }

  return (
    <Card className={classes.container}>
      <CardBody>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <CustomInput
              key={key}
              labelText="Título do dia"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                defaultValue: day.title,
                onChange: (e) => onChange('title', e.target.value),
                disabled
              }}
              error={useSpiritualFoodFormDayError('title')}
            />
            <ErrorItem message={useSpiritualFoodFormDayError('title')} />
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <CustomFileUpload
              key={key}
              title={
                day.audio instanceof Object 
                ? <a href={day.audio.url} target="blank">{`Trocar Áudio ${day.audio.name}`}</a>
                : 'Adicionar Áudio'
              }
              acceptedFiles={['audio/mpeg']}
              onChange={files => {
                const file = files && files.length > 0 ? files[0] : null
                onChange('audio', file)
              }}
              disabled={disabled}
              defaultFile={day.audio ? day.audio instanceof File ? day.audio : day.audio.id : null}
            />
            <ErrorItem message={useSpiritualFoodFormDayError('audio_id')} />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <CustomInput
              key={key}
              labelText="Leitura do dia"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                defaultValue: day.text,
                onChange: (e) => onChange('text', e.target.value),
                disabled,
                multiline: true,
                rows: 10
              }}
              error={useSpiritualFoodFormDayError('error')}
            />
            <ErrorItem message={useSpiritualFoodFormDayError('text')} />
          </GridItem>
        </GridContainer>
        <VerseFormList />
      </CardBody>
    </Card>
  )
}

const stylesForm = createStyles({
  container: {
    flex: 1,
    marginTop: 0,
    borderRadius: 0,
    background: '#fff',
    borderTop: 0
  },
})

const DayFormStyled = withStyles(stylesForm)(DayForm)

export default DayFormComponent
