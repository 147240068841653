import { put, call } from 'redux-saga/effects'
import { spiritualFoodsService } from '../services/SpiritualFoodsService'

import SpiritualFoodAddActions from '../stores/SpiritualFoodAdd/Actions'
import SpiritualFoodEditActions from '../stores/SpiritualFoodEdit/Actions'

import SpiritualFoodsActions from '../stores/SpiritualFoods/Actions'
import { RequestSpiritualFoodsAction } from '../stores/SpiritualFoods/Types'

import SpiritualFoodActions from '../stores/SpiritualFood/Actions'
import { RequestSpiritualFoodAction } from '../stores/SpiritualFood/Types'

import { ResponseService } from '../services/Service'
import { RequestSpiritualFoodAddAction } from '../stores/SpiritualFoodAdd/Types'
import { RequestSpiritualFoodEditAction } from '../stores/SpiritualFoodEdit/Types'

export function* getSpiritualFoods(action: RequestSpiritualFoodsAction) {
  const response: ResponseService = yield call < any > (spiritualFoodsService.fetchSpiritualFoods, action.payload)
  if (response.type === 'success') {
    let spiritualFoods = response.details.data
    let lastPage = Number(response.details.lastPage) || 0
    let page = Number(response.details.page) || 0
    let total = Number(response.details.total) || 0
    let perPage = Number(response.details.perPage) || 0
    let data = {
      key: action.payload.key,
      spiritualFoods,
      page,
      total,
      lastPage,
      perPage
    }
    yield put(SpiritualFoodsActions.successRequestSpiritualFoods(data))
  } else {
    let message = response.message || ''
    yield put(
      SpiritualFoodsActions.errorRequestSpiritualFoods({
        key: action.payload.key,
        message,
      })
    )
  }
}

export function* getSpiritualFood(action: RequestSpiritualFoodAction) {
  const response: ResponseService = yield call<any>(spiritualFoodsService.fetchSpiritualFood, action.payload.id)
  if (response.type === 'success') {
    let spiritualFood = response.details
    let data = {
      id: action.payload.id,
      spiritualFood,
    }
    yield put(SpiritualFoodActions.successRequestSpiritualFood(data))
  } else {
    let message = response.message || ''
    yield put(
      SpiritualFoodActions.errorRequestSpiritualFood({
        message,
      })
    )
  }
}

export function* spiritualFoodAdd(action: RequestSpiritualFoodAddAction) {
  const response: ResponseService = yield call<any>(spiritualFoodsService.spiritualFoodAdd, action.payload)
  if (response.type === 'success') {
    yield put(SpiritualFoodAddActions.successRequestSpiritualFoodAdd({
      spiritualFood: response.details,
      message: 'Alimento diário adicionado com sucesso'
    }))
  } else {
    let message = response.message || ''
    yield put(
      SpiritualFoodAddActions.errorRequestSpiritualFoodAdd({
        errors: response.details,
        message,
      })
    )
  }
}

export function* spiritualFoodEdit(action: RequestSpiritualFoodEditAction) {
  const response: ResponseService = yield call<any>(spiritualFoodsService.spiritualFoodEdit, action.payload)
  if (response.type === 'success') {
    yield put(SpiritualFoodEditActions.successRequestSpiritualFoodEdit({
      spiritualFood: response.details,
      message: 'Alimento diário editado com sucesso'
    }))
  } else {
    let message = response.message || ''
    yield put(
      SpiritualFoodEditActions.errorRequestSpiritualFoodEdit({
        errors: response.details,
        message,
      })
    )
  }
}
