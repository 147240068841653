import {
  REQUEST_SPIRITUAL_FOOD,
  SUCCESS_SPIRITUAL_FOOD,
  ERROR_SPIRITUAL_FOOD,
  CLEAR_SPIRITUAL_FOOD,
  SpiritualFoodActions,
  RequestSpiritualFoodParams,
  SuccessRequestSpiritualFood,
  ErrorRequestSpiritualFood
} from './Types'

export function requestSpiritualFood(params: RequestSpiritualFoodParams): SpiritualFoodActions {
  return {
    type: REQUEST_SPIRITUAL_FOOD,
    payload: params,
  }
}

export function successRequestSpiritualFood(response: SuccessRequestSpiritualFood): SpiritualFoodActions {
  return {
    type: SUCCESS_SPIRITUAL_FOOD,
    data: response,
  }
}

export function errorRequestSpiritualFood(response: ErrorRequestSpiritualFood): SpiritualFoodActions {
  return {
    type: ERROR_SPIRITUAL_FOOD,
    error: response,
  }
}

export function clearSpiritualFood(): SpiritualFoodActions {
  return {
    type: CLEAR_SPIRITUAL_FOOD
  }
}

export default {
  requestSpiritualFood,
  clearSpiritualFood,
  successRequestSpiritualFood,
  errorRequestSpiritualFood,
}
