import {
  transition,
} from '../../material-dashboard-react';
import { createStyles, Theme } from '@material-ui/core';

const appStyle = (theme: Theme) => createStyles({
  mainPanel: {
    width: '1000px',
    margin: '0 auto',
    overflow: 'auto',
    ...transition,
    maxHeight: '100%',
    overflowScrolling: 'touch'
  },
});

export default appStyle;
