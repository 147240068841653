import { get, ResponseService } from './Service'

async function fetchContents({ page, search, limit }): Promise<ResponseService> {
  let url = 'api/v1/search?'
  if (page) {
    url += 'page=' + page + '&'
  }
  if (search) {
    url += 'text=' + encodeURI(search) + '&'
  }
  if (limit) {
    url += 'limit=' + limit
  }
  return await get(url, undefined, {
    default_error_message: 'Ocorreu um erro na busca'
  })
}

export const contentsService = {
  fetchContents,
}
