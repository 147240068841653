import React, { useState, useCallback, useMemo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createStyles, withStyles } from '@material-ui/core'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

import { SpiritualFoodState } from '../../stores/SpiritualFood/Types'
import Actions from '../../stores/SpiritualFoodForm/Actions'
import { requestSpiritualFood, clearSpiritualFood } from '../../stores/SpiritualFood/Actions'
import { requestSpiritualFoodEdit, clearSpiritualFoodEdit } from '../../stores/SpiritualFoodEdit/Actions'

import Button from '../../components/CustomButtons/Button'
import Loading from '../../components/Loading/Loading'
import SnackbarContent from '../../components/Snackbar/SnackbarContent'
import SpiritualFoodForm from './SpiritualFoodForm'
import WeekForm from './WeekForm'

const SpiritualFood = (props) => {
  const id = props.match.params.id
  const dispatch = useDispatch()
  const spiritualFoodState = useSelector<any>(state => state.spiritualFood) as SpiritualFoodState
  const spiritualFoodForm: any = useSelector<any>(state => state.spiritualFoodForm)
  
  useEffect(() => {
    if (!spiritualFoodState.success) {
      return
    }
    let spiritualFood:any = JSON.parse(JSON.stringify(spiritualFoodState.spiritualFood))
    const tags = spiritualFood.tags.map(t => t.title)
    dispatch(Actions.setDetails({
      ...spiritualFood,
      tags
    }))
  }, [dispatch, spiritualFoodState.success])

  useEffect(() => {
    dispatch(requestSpiritualFood({ id }))
    return () => {
      dispatch(clearSpiritualFood())
      dispatch(clearSpiritualFoodEdit())
      dispatch(Actions.clear())
    }
  }, [])

  return useMemo(() => {
    if (spiritualFoodState.success && spiritualFoodForm.id) {
      return <SpiritualFoodEditStyled />
    }
    if (spiritualFoodState.error) {
      return (
        <SnackbarContent 
          color="danger" 
          message={spiritualFoodState.message}
        />
      )
    }
    return <Loading hide={false}  message="Aguarde ..." />
  }, [spiritualFoodState, spiritualFoodForm])
}

export default SpiritualFood

const SpiritualFoodEdit = ({ classes }) => {
  const dispatch = useDispatch()
  const state: any = useSelector<any>(state => state.spiritualFoodForm)
  const stateEdit: any = useSelector<any>(state => state.spiritualFoodEdit)
  const [value, setValue] = React.useState(0)

  useEffect(() => {
    dispatch(Actions.setColumn('disabled', stateEdit.requesting))
  }, [stateEdit.requesting])

  useEffect(() => {
    dispatch(Actions.setColumn('errors', stateEdit.errors))
  }, [stateEdit.errors])

  const handleClick = useCallback(() => {
    let data = { ...state }
    delete data.disabled
    delete data.currentWeekIndex
    delete data.currentDayIndex
    delete data.errors
    dispatch(requestSpiritualFoodEdit(data))
  }, [state, dispatch])

  const handleChange = (e, newValue) => {
    setValue(newValue)
    if (newValue > 0) {
      dispatch(Actions.setCurrentWeek(newValue - 1))
      dispatch(Actions.setCurrentDay(0))
    }
  }

  const content = useMemo(() => {
    if (value === 0) {
      return <SpiritualFoodForm />
    }
    return <WeekForm key={value} />
  }, [value])

  const errorMessage = useMemo(() => {
    if (!state.errors) {
      return null
    }
    return <span style={{color: 'red'}}>Formulário inválido</span>
  }, [state.errors])

  const successMessage = useMemo(() => {
    if (!stateEdit.success) {
      return null
    }
    return <span style={{color: 'green'}}>Alimento diário editado com sucesso</span>
  }, [stateEdit.success])

  return (
    <div>
      {successMessage}
      {errorMessage}
      <Tabs
        classes={classes}
        value={value}
        onChange={handleChange}
        variant="fullWidth"
        scrollButtons="auto"
      >
        <TabStyled label="Dados" />
        <TabStyled label="Semana 1" />
        <TabStyled label="Semana 2" />
        <TabStyled label="Semana 3" />
        <TabStyled label="Semana 4" />
      </Tabs>
      {content}
      <Button 
        disabled={state.disabled} 
        onClick={handleClick} 
        color="success"
      >Editar</Button>
    </div>
  );
}

const styles = createStyles({
  root: {
    marginTop: '20px',
    marginBottom: '20px',
    borderRadius: '20px',
    background: '#fff',
    border: '1px solid #007332',
    color: 'red'
  },
  'indicator': {
    backgroundColor: '#007332',
  }
})

const stylesTab = createStyles({
  root: {
    color: '#333'
  }
})

const TabStyled = withStyles(stylesTab)(Tab)

const SpiritualFoodEditStyled = withStyles(styles)(SpiritualFoodEdit)
