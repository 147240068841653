import { JavEditState, JavEditTypes } from './Types'

const initialState: JavEditState = {
  data: null,
  requesting: false,
  errors: null,
  success: false,
  message: null
}

export function reducer(state = initialState, action: any): JavEditState {
  switch (action.type) {
    case JavEditTypes.REQUEST_JAV_EDIT:
      const payloadEdit = action.payload
      return {
        data: payloadEdit,
        requesting: true,
        errors: null,
        success: false,
        message: null
      }
    case JavEditTypes.SUCCESS_JAV_EDIT:
      const payloadJav = action.data
      return {
        data: payloadJav.jav,
        requesting: false,
        errors: null,
        success: true,
        message: payloadJav.message
      }
    case JavEditTypes.ERROR_JAV_EDIT:
      const payloadError = action.error
      return {
        data: null,
        requesting: false,
        errors: payloadError.errors,
        success: false,
        message: payloadError.message
      }
    case JavEditTypes.CLEAR_JAV_EDIT:
      return {
        data: null,
        requesting: false,
        errors: null,
        success: false,
        message: null
      }
  }
  return state
}
