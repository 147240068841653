import {
  REQUEST_BOOK_ADD,
  SUCCESS_BOOK_ADD,
  ERROR_BOOK_ADD,
  CLEAR_BOOK_ADD,
  BookAddActions,
  RequestBookAddParams,
  SuccessRequestBookAdd,
  ErrorRequestBookAdd
} from './Types'

export function requestBookAdd(params: RequestBookAddParams): BookAddActions {
  return {
    type: REQUEST_BOOK_ADD,
    payload: params,
  }
}

export function successRequestBookAdd(response: SuccessRequestBookAdd): BookAddActions {
  return {
    type: SUCCESS_BOOK_ADD,
    data: response,
  }
}

export function errorRequestBookAdd(response: ErrorRequestBookAdd): BookAddActions {
  return {
    type: ERROR_BOOK_ADD,
    error: response,
  }
}

export function clearBookAdd(): BookAddActions {
  return {
    type: CLEAR_BOOK_ADD
  }
}

export default {
  requestBookAdd,
  clearBookAdd,
  successRequestBookAdd,
  errorRequestBookAdd,
}
