import { put, call } from 'redux-saga/effects'
import { authService } from '../services/AuthService'

import RecoveryPasswordActions from '../stores/RecoveryPassword/Actions'
import { RequestRecoveryPasswordAction } from '../stores/RecoveryPassword/Types'

import { ResponseService } from '../services/Service'

export function* recoveryPassword(action: RequestRecoveryPasswordAction) {
  const response: ResponseService = yield call<any>(authService.recoveryPassword, action.payload)
  let message = response.message || '';
  if (response.type === 'success') {
    yield put(RecoveryPasswordActions.successRecoveryPassword({
      message
    }))
  } else {    
    yield put(RecoveryPasswordActions.errorRecoveryPassword({
      message
    }))
  }
}
