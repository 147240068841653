export const REQUEST_RECOVERY_PASSWORD: any = 'REQUEST_RECOVERY_PASSWORD'
export const SUCCESS_RECOVERY_PASSWORD = 'SUCCESS_RECOVERY_PASSWORD'
export const ERROR_RECOVERY_PASSWORD = 'ERROR_RECOVERY_PASSWORD'
export const CLEAR_RECOVERY_PASSWORD = 'CLEAR_RECOVERY_PASSWORD'
export const CLEAR_ERROR = 'CLEAR_ERROR'

export interface RecoveryPasswordState {
  requesting: boolean;
  error: boolean;
  success: boolean;
  message: string | null;
}

export interface Data {
  code: string;
  password: string;
  repassword: string;
}

export interface MessageRecoveryPassword {
  message: string;
}

export interface RequestRecoveryPasswordAction {
  type: typeof REQUEST_RECOVERY_PASSWORD
  payload: Data
}

export interface SuccessRecoveryPasswordAction {
  type: typeof SUCCESS_RECOVERY_PASSWORD
  data: MessageRecoveryPassword
}

export interface ErrorRecoveryPasswordAction {
  type: typeof ERROR_RECOVERY_PASSWORD
  error: MessageRecoveryPassword
}

export interface ClearRecoveryPasswordAction {
  type: typeof CLEAR_RECOVERY_PASSWORD
}

export interface ClearErrorAction {
  type: typeof CLEAR_ERROR
}

export type RecoveryPasswordActions = 
  RequestRecoveryPasswordAction | 
  SuccessRecoveryPasswordAction | 
  ErrorRecoveryPasswordAction | 
  ClearRecoveryPasswordAction | 
  ClearErrorAction

export const RecoveryPasswordTypes = {
  REQUEST_RECOVERY_PASSWORD,
  SUCCESS_RECOVERY_PASSWORD,
  ERROR_RECOVERY_PASSWORD,
  CLEAR_RECOVERY_PASSWORD,
  CLEAR_ERROR,
}