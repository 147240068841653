import {
  ERROR_REGISTER,
  SUCCESS_REGISTER,
  REQUEST_REGISTER,
  CLEAR_REGISTER,
  CLEAR_ERROR,
  Data,
  MessageRegister,
  RegisterActions,
} from './Types'

export function requestRegister(data: Data): RegisterActions {
  return {
    type: REQUEST_REGISTER,
    payload: data,
  }
}

export function successRegister(response: MessageRegister): RegisterActions {
  return {
    type: SUCCESS_REGISTER,
    data: response,
  }
}

export function errorRegister(response: MessageRegister): RegisterActions {
  return {
    type: ERROR_REGISTER,
    error: response,
  }
}

export function clear(): RegisterActions {
  return {
    type: CLEAR_REGISTER,
  }
}

export function clearError(): RegisterActions {
  return {
    type: CLEAR_ERROR,
  }
}

export default {
  requestRegister,
  successRegister,
  errorRegister,
}
