import React, { useState, useMemo, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { createStyles, withStyles } from '@material-ui/core'

import { Actions } from '../../stores/SpiritualFoodForm'

import Card from '../../components/Card/Card'
import CardBody from '../../components/Card/CardBody'
import GridItem from '../../components/Grid/GridItem'
import GridContainer from '../../components/Grid/GridContainer'
import CustomInput from '../../components/CustomInput/CustomInput'
import Loading from '../../components/Loading/Loading'

import DaysTab from './DaysTab'
import ErrorItem, { useSpiritualFoodFormWeekError } from './ErrorItem'
import { createWeek } from '../../stores/SpiritualFoodForm/Reducers'
import Button from '../../components/CustomButtons/Button'

const WeekForm = ({ classes }) => {
  const dispatch = useDispatch()
  const state: any = useSelector<any>(state => state.spiritualFoodForm)
  const week = state.weeks[state.currentWeekIndex]
  const disabled = state.disabled
  const titleError = useSpiritualFoodFormWeekError('title')
  const youtubeLinkError = useSpiritualFoodFormWeekError('youtube_link')

  const handleAddWeek = () => {
    let week = createWeek(state.currentWeekIndex + 1)
    dispatch(Actions.setCurrentWeekValue(week))
  }

  const onChange = (column, value) => {
    dispatch(Actions.setCurrentWeekColumn(column, value ))
  }

  if (!week) {
    if (state.currentWeekIndex > 0 && !state.weeks[state.currentWeekIndex - 1]) {
      return (
        <div style={{textAlign: 'center'}}>
          <Button disabled>Para configurar essa semana, preencha a anterior</Button>
        </div>
      )
    }
    return (
      <div style={{textAlign: 'center'}}>
        <Button onClick={handleAddWeek}>Configurar semana</Button>
      </div>
    )
  }
  return (
    <div className={classes.formContainer}>
      <Loading
        message="Aguarde um momento ..."
        hide={!state.disabled}
      />
      <Card>
        <CardBody>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <CustomInput
                labelText="Título da semana"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  defaultValue: week.title,
                  onChange: (e) => onChange('title', e.target.value),
                  disabled
                }}
                error={titleError}
              />
              <ErrorItem message={titleError} />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <CustomInput
                labelText="Link do Youtube"
                id="link"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  defaultValue: week.youtube_link,
                  onChange: (e) => onChange('youtube_link', e.target.value),
                  disabled
                }}
                error={youtubeLinkError}
              />
              <ErrorItem message={youtubeLinkError} />
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
      <DaysTab days={week.days} />
    </div>
  )
}

const weekStyle = createStyles({
  button: {
    background: '#007332',
    marginTop: '20px',
    borderRadius: '9px 9px 0 0',
    color: '#fff',
    '&:hover &:active': {
      background: '#007332',
      color: '#333'
    }
  },
  item: {
    border: '1px solid #f0f0f0',
    padding: 0
  },
  formContainer: {
    flex: 1,
    margin: '10px'
  }
})

export default withStyles(weekStyle)(WeekForm)