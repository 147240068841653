import React from 'react'
import CloseIcon from '@material-ui/icons/Close'
import SnackbarContent from '../Snackbar/SnackbarContent'

export default function Error({ message, hide, onClick = () => {} }) {
  if (!!hide) {
    return null
  }
  return (
    <SnackbarContent
      onClick={onClick}
      color="danger"
      icon={CloseIcon} 
      message={message}
    />
  )
}
