import { createStyles, Theme } from '@material-ui/core';

const logoStyle = (theme: Theme) => createStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 40
  },
  image: {
    maxWidth: 140
  }
});

export default logoStyle;
