export const REQUEST_REQUEST_RECOVERY_PASSWORD: any = 'REQUEST_REQUEST_RECOVERY_PASSWORD'
export const SUCCESS_REQUEST_RECOVERY_PASSWORD = 'SUCCESS_REQUEST_RECOVERY_PASSWORD'
export const ERROR_REQUEST_RECOVERY_PASSWORD = 'ERROR_REQUEST_RECOVERY_PASSWORD'
export const CLEAR_REQUEST_RECOVERY_PASSWORD = 'CLEAR_REQUEST_RECOVERY_PASSWORD'
export const CLEAR_ERROR = 'CLEAR_ERROR'

export interface RequestRecoveryPasswordState {
  requesting: boolean;
  error: boolean;
  success: boolean;
  message: string | null;
}

export interface Data {
  email: string;
}

export interface MessageRequestRecoveryPassword {
  message: string;
}

export interface RequestRequestRecoveryPasswordAction {
  type: typeof REQUEST_REQUEST_RECOVERY_PASSWORD
  payload: Data
}

export interface SuccessRequestRecoveryPasswordAction {
  type: typeof SUCCESS_REQUEST_RECOVERY_PASSWORD
  data: MessageRequestRecoveryPassword
}

export interface ErrorRequestRecoveryPasswordAction {
  type: typeof ERROR_REQUEST_RECOVERY_PASSWORD
  error: MessageRequestRecoveryPassword
}

export interface ClearRequestRecoveryPasswordAction {
  type: typeof CLEAR_REQUEST_RECOVERY_PASSWORD
}

export interface ClearErrorAction {
  type: typeof CLEAR_ERROR
}

export type RequestRecoveryPasswordActions = 
  RequestRequestRecoveryPasswordAction | 
  SuccessRequestRecoveryPasswordAction | 
  ErrorRequestRecoveryPasswordAction | 
  ClearRequestRecoveryPasswordAction | 
  ClearErrorAction

export const RequestRecoveryPasswordTypes = {
  REQUEST_REQUEST_RECOVERY_PASSWORD,
  SUCCESS_REQUEST_RECOVERY_PASSWORD,
  ERROR_REQUEST_RECOVERY_PASSWORD,
  CLEAR_REQUEST_RECOVERY_PASSWORD,
  CLEAR_ERROR,
}