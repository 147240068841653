import React from 'react';
import { withStyles } from '@material-ui/core';

import logoStyle from '../../assets/jss/material-dashboard-react/components/logoStyle';

import logo from 'assets/img/logo.svg';
import logoTitle from 'assets/img/logo-title.svg';

interface Props {
  classes: any;
}

const Logo = (props: Props) => {
  const { classes } = props;
  return (
    <div className={classes.container}>
      <img className={classes.image} src={logo} alt="..." />
      <img className={classes.image} src={logoTitle} alt="..." />
    </div>
  )
}

export default withStyles(logoStyle)(Logo);