export const REQUEST_SPIRITUAL_FOOD: any = 'REQUEST_SPIRITUAL_FOOD'
export const SUCCESS_SPIRITUAL_FOOD = 'SUCCESS_SPIRITUAL_FOOD'
export const ERROR_SPIRITUAL_FOOD = 'ERROR_SPIRITUAL_FOOD'
export const CLEAR_SPIRITUAL_FOOD = 'CLEAR_SPIRITUAL_FOOD'

export interface SpiritualFoodState {
  id: number | string | null;
  spiritualFood: Object | null;
  requesting: boolean;
  error: boolean;
  success: boolean;
  message: string | null;
}

export interface RequestSpiritualFoodParams {
  id: number | string;
}

export interface ErrorRequestSpiritualFood {
  message: string;
}

export interface ClearSpiritualFood {
}

export interface SuccessRequestSpiritualFood {
  spiritualFood: Object;
}

export interface RequestSpiritualFoodAction {
  type: typeof REQUEST_SPIRITUAL_FOOD
  payload: RequestSpiritualFoodParams
}

export interface SuccessRequestSpiritualFoodAction {
  type: typeof SUCCESS_SPIRITUAL_FOOD
  data: SuccessRequestSpiritualFood
}

export interface ErrorRequestSpiritualFoodAction {
  type: typeof ERROR_SPIRITUAL_FOOD
  error: ErrorRequestSpiritualFood
}

export interface ClearSpiritualFoodAction {
  type: typeof CLEAR_SPIRITUAL_FOOD
}

export type SpiritualFoodActions = RequestSpiritualFoodAction | SuccessRequestSpiritualFoodAction | ErrorRequestSpiritualFoodAction | ClearSpiritualFoodAction

export const SpiritualFoodTypes = {
  REQUEST_SPIRITUAL_FOOD,
  SUCCESS_SPIRITUAL_FOOD,
  ERROR_SPIRITUAL_FOOD,
  CLEAR_SPIRITUAL_FOOD,
}