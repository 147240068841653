import { pickBy, identity } from 'lodash'
import api, { get, post, ResponseService } from './Service'

interface FilterParams {
  text?: string; 
  page?: Number;
  jav_id?: Number;
  spiritual_food_id?: Number;
  book_id?: Number;
  subscription_id?: Number;
}

interface GenerateParams {
  amount: Number;
  jav_id?: Number;
  spiritual_food_id?: Number;
  book_id?: Number;
  subscription_id?: Number;
}

export interface DownloadParams {
  jav_id?: Number | null;
  spiritual_food_id?: Number | null;
  book_id?: Number | null;
  subscription_id?: Number | null;
}


export async function fetchAccessCode(filter: FilterParams): Promise<ResponseService> {
  const params = new URLSearchParams(pickBy(filter, identity))
  let url = `api/v1/admin/access-codes?${params}`
  return await get(url, undefined, {
    default_error_message: 'Ocorreu um erro na busca'
  })
}

export async function activeAccessCode({ accessCode, active }): Promise<ResponseService> {
  let url = `/api/v1/admin/access-codes/active`
  const data = {
    access_code: accessCode,
    active
  }
  return await post(url, data, {
    default_error_message: 'Ocorreu um erro na busca'
  })
}

export async function generateAccessCode(params: GenerateParams): Promise<ResponseService> {
  const data = pickBy(params, identity)
  let url = `/api/v1/admin/access-codes/generate`
  return await post(url, data, {
    default_error_message: 'Ocorreu um erro na busca'
  })
}

export async function downloadAccessCode(filters: DownloadParams) {
  const params = new URLSearchParams(pickBy(filters, identity))
  let url = '/api/v1/admin/access-codes/export?' + params
  const response = await api.get(url, {
    responseType: 'blob'
  })
  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(new Blob([response.data]))
  link.setAttribute('download', 'file.csv')
  document.body.appendChild(link)
  link.click()
  setTimeout(() => {
    link.remove()
  }, 10000)
}