import React, { useState, useMemo, useEffect, useCallback } from 'react';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

import GridItem from '../../components/Grid/GridItem';
import GridContainer from '../../components/Grid/GridContainer';
import CustomInput from '../../components/CustomInput/CustomInput';
import Button from '../../components/CustomButtons/Button';
import Card from '../../components/Card/Card';
import CardHeader from '../../components/Card/CardHeader';
import CardBody from '../../components/Card/CardBody';
import CardFooter from '../../components/Card/CardFooter';
import Logo from '../../components/Logo/Logo';

import { createStyles } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { AuthState } from '../../stores/Auth/Types';
import SnackbarContent from '../../components/Snackbar/SnackbarContent';
import { requestAuthorization, requestLogout, clearError } from '../../stores/Auth/Actions';

function UserProfile(props: any) {
  const { classes } = props;
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const dispatch = useDispatch()
  const authState: AuthState = useSelector<any>((state) => state.auth) as AuthState
  const disabled = useMemo(() => authState.requesting, [authState.requesting])

  useEffect(() => {
    dispatch(requestLogout())
    return () => {
      dispatch(clearError())
    }
  }, [dispatch])

  const loadingComp = useMemo(() => {
    if (!authState.requesting) {
      return null
    }
    return <SnackbarContent message={'Aguarde ...'} />
  }, [authState.requesting])

  const messageComp = useMemo(() => {
    if (!authState.error) {
      return null
    }
    return <SnackbarContent message={authState.message} color="danger" />
  }, [authState.message, authState.error])

  const handleSubmit = useCallback((e) => {
    e.preventDefault()
    dispatch(requestAuthorization({ username, password }))
  }, [username, password, dispatch])

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Logo />
          <Card>
            <CardHeader className={classes.cardHeader}>
              <h4 className={classes.cardTitleWhite}>Acesso administrativo</h4>
            </CardHeader>
            <CardBody>
              {loadingComp}
              {messageComp}
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText="E-mail"
                    id="email-address"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      defaultValue: username,
                      onChange: (e) => setUsername(e.target.value),
                      disabled: disabled
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText="Senha"
                    id="password"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "password",
                      defaultValue: password,
                      onChange: (e) => setPassword(e.target.value),
                      disabled: disabled
                    }}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter>
              <Button onClick={handleSubmit} color="primary">Logar</Button>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

const styles = createStyles({
  cardHeader: {
    background: '#f0f0f0'
  },
  cardTitleWhite: {
    color: '#333',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: 300,
    fontFamily: '\'Roboto\', \'Helvetica\', \'Arial\', sans-serif',
    marginBottom: '3px',
    textDecoration: 'none'
  }
});

export default withStyles(styles)(UserProfile);
