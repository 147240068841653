export const SPIRITUAL_FOOD_FORM_SET_DETAILS: any = 'SPIRITUAL_FOOD_FORM_SET_DETAILS'
export const SPIRITUAL_FOOD_FORM_SET_CURRENT_DAY: any = 'SPIRITUAL_FOOD_FORM_SET_CURRENT_DAY'
export const SPIRITUAL_FOOD_FORM_SET_CURRENT_WEEK: any = 'SPIRITUAL_FOOD_FORM_SET_CURRENT_WEEK'
export const SPIRITUAL_FOOD_FORM_SET_COLUMN_CURRENT_WEEK: any = 'SPIRITUAL_FOOD_FORM_SET_COLUMN_CURRENT_WEEK'
export const SPIRITUAL_FOOD_FORM_SET_COLUMN_CURRENT_DAY: any = 'SPIRITUAL_FOOD_FORM_SET_COLUMN_CURRENT_DAY'
export const SPIRITUAL_FOOD_FORM_SET_VERSE: any = 'SPIRITUAL_FOOD_FORM_SET_VERSE'
export const SPIRITUAL_FOOD_FORM_ADD_VERSE: any = 'SPIRITUAL_FOOD_FORM_ADD_VERSE'
export const SPIRITUAL_FOOD_FORM_REMOVE_VERSE: any = 'SPIRITUAL_FOOD_FORM_REMOVE_VERSE'
export const SPIRITUAL_FOOD_FORM_CLEAR: any = 'SPIRITUAL_FOOD_FORM_CLEAR'

export const SpiritualFoodFormTypes = {
  SPIRITUAL_FOOD_FORM_SET_DETAILS,
  SPIRITUAL_FOOD_FORM_SET_CURRENT_DAY,
  SPIRITUAL_FOOD_FORM_SET_CURRENT_WEEK,
  SPIRITUAL_FOOD_FORM_SET_COLUMN_CURRENT_WEEK,
  SPIRITUAL_FOOD_FORM_SET_COLUMN_CURRENT_DAY,
  SPIRITUAL_FOOD_FORM_SET_VERSE,
  SPIRITUAL_FOOD_FORM_ADD_VERSE,
  SPIRITUAL_FOOD_FORM_REMOVE_VERSE,
  SPIRITUAL_FOOD_FORM_CLEAR
}