export const REQUEST_RELEASE_ADD: any = 'REQUEST_RELEASE_ADD'
export const SUCCESS_RELEASE_ADD = 'SUCCESS_RELEASE_ADD'
export const ERROR_RELEASE_ADD = 'ERROR_RELEASE_ADD'
export const CLEAR_RELEASE_ADD = 'CLEAR_RELEASE_ADD'

export interface Release {
  id: string;
  title: string;
}

export interface ReleaseAddState {
  data: RequestReleaseAddParams | Release | null;
  requesting: boolean;
  errors: Object | null;
  success: boolean;
  message: string | null;
}

export interface RequestReleaseAddParams {
  title: string;
  link: string;
  tags: Array<string>;
  image: File | number;
}

export interface ErrorRequestReleaseAdd {
  errors: Object;
  message: string;
}

export interface SuccessRequestReleaseAdd {
  message: string;
  release: Release;
}

export interface RequestReleaseAddAction {
  type: typeof REQUEST_RELEASE_ADD
  payload: RequestReleaseAddParams
}

export interface SuccessRequestReleaseAddAction {
  type: typeof SUCCESS_RELEASE_ADD
  data: SuccessRequestReleaseAdd
}

export interface ErrorRequestReleaseAddAction {
  type: typeof ERROR_RELEASE_ADD
  error: ErrorRequestReleaseAdd
}

export interface ClearReleaseAddAction {
  type: typeof CLEAR_RELEASE_ADD
}

export type ReleaseAddActions = RequestReleaseAddAction | SuccessRequestReleaseAddAction | ErrorRequestReleaseAddAction | ClearReleaseAddAction

export const ReleaseAddTypes = {
  REQUEST_RELEASE_ADD,
  SUCCESS_RELEASE_ADD,
  ERROR_RELEASE_ADD,
  CLEAR_RELEASE_ADD,
}