import {
  ERROR_AUTH,
  SUCCESS_AUTH,
  REQUEST_AUTH,
  REQUEST_LOGOUT,
  CLEAR_AUTH,
  CLEAR_ERROR,
  Credentials,
  SuccessAuthorization,
  ErrorAuthorization,
  AuthActions
} from './Types'

export function requestAuthorization(credentials: Credentials): AuthActions {
  return {
    type: REQUEST_AUTH,
    payload: credentials
  }
}

export function successAuthorization(response: SuccessAuthorization): AuthActions {
  return {
    type: SUCCESS_AUTH,
    data: response
  }
}

export function successRefreshAuthorization(response: SuccessAuthorization): AuthActions {
  return {
    type: SUCCESS_AUTH,
    data: response
  }
}

export function errorAuthorization(response: ErrorAuthorization): AuthActions {
  return {
    type: ERROR_AUTH,
    error: response
  }
}

export function requestLogout(): AuthActions {
  return {
    type: REQUEST_LOGOUT
  }
}

export function logout(): AuthActions {
  return {
    type: CLEAR_AUTH
  }
}

export function clearError(): AuthActions {
  return {
    type: CLEAR_ERROR
  }
}

export default {
  requestAuthorization,
  requestLogout,
  successAuthorization,
  successRefreshAuthorization,
  logout,
  errorAuthorization
}
