import BookIcon from '@material-ui/icons/Book'
import QueueIcon from '@material-ui/icons/Queue'
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks'
import AssignmentIcon from '@material-ui/icons/Assignment'
import HttpsIcon from '@material-ui/icons/Https'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

import { createEditButton, createAccessCodeButton } from './components/ContentList/ContentList'
import { requestSpiritualFoods, clearSpiritualFoods } from './stores/SpiritualFoods/Actions'
import { requestBooks, clearBooks } from './stores/Books/Actions'
import { requestJavs, clearJavs } from './stores/Javs/Actions'
import { requestReleases, clearReleases } from './stores/Releases/Actions'
import { createReleaseDeleteAction } from './views/Releases/ReleaseDelete'

import AccessCodeListPage from './views/AccessCode/AccessCodeList'
import BookAddPage from './views/Books/BookAdd'
import BookEditPage from './views/Books/BookEdit'
import ContentListPage from './views/ContentList/ContentList'
import JavAddPage from './views/Javs/JavAdd'
import JavEditPage from './views/Javs/JavEdit'
import ReleaseAddPage from './views/Releases/ReleaseAdd'
import ReleaseEditPage from './views/Releases/ReleaseEdit'
import SpiritualFoodAddPage from './views/SpiritualFoods/SpiritualFoodAdd'
import SpiritualFoodEditPage from './views/SpiritualFoods/SpiritualFoodEdit'
import SubscriptionListPage from './views/Subscriptions/SubscriptionList'
import SubscriptionAddPage from './views/Subscriptions/SubscriptionAdd'
import SubscriptionEditPage from './views/Subscriptions/SubscriptionEdit'
import SubscriptionContentPage from './views/Subscriptions/SubscriptionContent'
import LogoutPage from './views/LogoutPage/LogoutPage'

const dashboardRoutes = [
  {
    path: '/alimentos-diarios',
    name: 'Alimentos Diários',
    icon: QueueIcon,
    component: ContentListPage,
    layout: '/admin',
    props: {
      routeAddLabel: "Adicionar Alimento Diário",
      routeAddUrl: "/admin/adicionar-alimento-diario",
      storeName: "spiritualFoods",
      request: requestSpiritualFoods,
      clearRequest: clearSpiritualFoods,
      renderItemActions: (item : any) => [
        createEditButton(item.id, '/admin/editar-alimento-diario/'),
        createAccessCodeButton(item.id, '/admin/codigos-de-acesso?spiritual_food_id=')
      ]
    }
  },
  {
    path: '/adicionar-alimento-diario',
    name: 'Adicionar Alimento Diário',
    icon: BookIcon,
    component: SpiritualFoodAddPage,
    layout: '/admin',
    hideMenu: true
  },
  {
    path: '/editar-alimento-diario/:id',
    name: 'Editar Alimento Diário',
    icon: BookIcon,
    component: SpiritualFoodEditPage,
    layout: '/admin',
    hideMenu: true
  },
  {
    path: '/livros',
    name: 'Livros',
    icon: LibraryBooksIcon,
    component: ContentListPage,
    layout: '/admin',
    props: {
      routeAddLabel: "Adicionar Livro",
      routeAddUrl: "/admin/adicionar-livro",
      storeName: "books",
      request: requestBooks,
      clearRequest: clearBooks,
      renderItemActions: (item : any) => [
        createEditButton(item.id, '/admin/editar-livro/'),
        createAccessCodeButton(item.id, '/admin/codigos-de-acesso?book_id=')
      ]
    }
  },
  {
    path: '/adicionar-livro',
    name: 'Adicionar Livro',
    icon: BookIcon,
    component: BookAddPage,
    layout: '/admin',
    hideMenu: true
  },
  {
    path: '/editar-livro/:id',
    name: 'Editar Livro',
    icon: BookIcon,
    component: BookEditPage,
    layout: '/admin',
    hideMenu: true
  },
  {
    path: '/javs',
    name: 'Javs',
    icon: AssignmentIcon,
    component: ContentListPage,
    layout: '/admin',
    props: {
      routeAddLabel: "Adicionar JAV",
      routeAddUrl: "/admin/adicionar-jav",
      storeName: "javs",
      request: requestJavs,
      clearRequest: clearJavs,
      renderItemActions: (item : any) => [
        createEditButton(item.id, '/admin/editar-jav/'),
        createAccessCodeButton(item.id, '/admin/codigos-de-acesso?jav_id=')
      ]
    }
  },
  {
    path: '/adicionar-jav',
    name: 'Adicionar JAV',
    icon: BookIcon,
    component: JavAddPage,
    layout: '/admin',
    hideMenu: true
  },
  {
    path: '/editar-jav/:id',
    name: 'Editar JAV',
    icon: BookIcon,
    component: JavEditPage,
    layout: '/admin',
    hideMenu: true
  },

  {
    path: '/lancamentos',
    name: 'Lançamentos',
    icon: BookIcon,
    component: ContentListPage,
    layout: '/admin',
    props: {
      routeAddLabel: "Adicionar Lançamento",
      routeAddUrl: "/admin/adicionar-lancamento",
      storeName: "releases",
      request: requestReleases,
      clearRequest: clearReleases,
      renderItemActions: (item : any) => [
        createEditButton(item.id, '/admin/editar-lancamento/'),
      ],
      renderRemoveAction: (item: any, onDelete) => {
        return createReleaseDeleteAction(item.id, onDelete)
      }
    }
  },
  {
    path: '/adicionar-lancamento',
    name: 'Adicionar Lançamento',
    icon: BookIcon,
    component: ReleaseAddPage,
    layout: '/admin',
    hideMenu: true
  },
  {
    path: '/editar-lancamento/:id',
    name: 'Editar Lançamento',
    icon: BookIcon,
    component: ReleaseEditPage,
    layout: '/admin',
    hideMenu: true
  },
  {
    path: '/assinaturas',
    name: 'Assinaturas',
    icon: HttpsIcon,
    component: SubscriptionListPage,
    layout: '/admin',
    hideMenu: false
  },
  {
    path: '/adicionar-assinatura',
    name: 'Adicionar Assinatura',
    icon: BookIcon,
    component: SubscriptionAddPage,
    layout: '/admin',
    hideMenu: true
  },
  {
    path: '/editar-assinatura/:id',
    name: 'Editar Assinatura',
    icon: BookIcon,
    component: SubscriptionEditPage,
    layout: '/admin',
    hideMenu: true
  },
  {
    path: '/conteudos-assinatura/:id',
    name: 'Conteúdos da Assinatura',
    icon: BookIcon,
    component: SubscriptionContentPage,
    layout: '/admin',
    hideMenu: true
  },
  {
    path: '/codigos-de-acesso',
    name: 'Códigos de Acesso',
    icon: BookIcon,
    component: AccessCodeListPage,
    layout: '/admin',
    hideMenu: true
  },
  {
    path: '/logout',
    name: 'Sair',
    icon: ArrowBackIcon,
    component: LogoutPage,
    layout: '/admin',
    hideMenu: false
  },
];


export default dashboardRoutes;
