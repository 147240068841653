import { ReleaseAddState, ReleaseAddTypes } from './Types'

const initialState: ReleaseAddState = {
  data: null,
  requesting: false,
  errors: null,
  success: false,
  message: null
}

export function reducer(state = initialState, action: any): ReleaseAddState {
  switch (action.type) {
    case ReleaseAddTypes.REQUEST_RELEASE_ADD:
      const payloadAdd = action.payload
      return {
        data: payloadAdd,
        requesting: true,
        errors: null,
        success: false,
        message: null
      }
    case ReleaseAddTypes.SUCCESS_RELEASE_ADD:
      const payloadRelease = action.data
      return {
        data: payloadRelease.release,
        requesting: false,
        errors: null,
        success: true,
        message: payloadRelease.message
      }
    case ReleaseAddTypes.ERROR_RELEASE_ADD:
      const payloadError = action.error
      return {
        data: null,
        requesting: false,
        errors: payloadError.errors,
        success: false,
        message: payloadError.message
      }
    case ReleaseAddTypes.CLEAR_RELEASE_ADD:
      return {
        data: null,
        requesting: false,
        errors: null,
        success: false,
        message: null
      }
  }
  return state
}
