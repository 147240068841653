export const REQUEST_SPIRITUAL_FOOD_EDIT: any = 'REQUEST_SPIRITUAL_FOOD_EDIT'
export const SUCCESS_SPIRITUAL_FOOD_EDIT = 'SUCCESS_SPIRITUAL_FOOD_EDIT'
export const ERROR_SPIRITUAL_FOOD_EDIT = 'ERROR_SPIRITUAL_FOOD_EDIT'
export const CLEAR_SPIRITUAL_FOOD_EDIT = 'CLEAR_SPIRITUAL_FOOD_EDIT'

export interface SpiritualFood {
  id: string;
  title: string;
}

export interface SpiritualFoodEditState {
  data: RequestSpiritualFoodEditParams | SpiritualFood | null;
  requesting: boolean;
  errors: Object | null;
  success: boolean;
  message: string | null;
}

export interface RequestSpiritualFoodEditParams {
  id: string | number;
  title: string;
  active: boolean;
  is_public: boolean;
  tags: Array<string>;
  image: File | number;
  pdf: File | number;
}

export interface ErrorRequestSpiritualFoodEdit {
  errors: Array<{ name, message }>;
  message: string;
}

export interface SuccessRequestSpiritualFoodEdit {
  message: string;
  spiritualFood: SpiritualFood;
}

export interface RequestSpiritualFoodEditAction {
  type: typeof REQUEST_SPIRITUAL_FOOD_EDIT
  payload: RequestSpiritualFoodEditParams
}

export interface SuccessRequestSpiritualFoodEditAction {
  type: typeof SUCCESS_SPIRITUAL_FOOD_EDIT
  data: SuccessRequestSpiritualFoodEdit
}

export interface ErrorRequestSpiritualFoodEditAction {
  type: typeof ERROR_SPIRITUAL_FOOD_EDIT
  error: ErrorRequestSpiritualFoodEdit
}

export interface ClearSpiritualFoodEditAction {
  type: typeof CLEAR_SPIRITUAL_FOOD_EDIT
}

export type SpiritualFoodEditActions = RequestSpiritualFoodEditAction | SuccessRequestSpiritualFoodEditAction | ErrorRequestSpiritualFoodEditAction | ClearSpiritualFoodEditAction

export const SpiritualFoodEditTypes = {
  REQUEST_SPIRITUAL_FOOD_EDIT,
  SUCCESS_SPIRITUAL_FOOD_EDIT,
  ERROR_SPIRITUAL_FOOD_EDIT,
  CLEAR_SPIRITUAL_FOOD_EDIT,
}