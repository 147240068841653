import {
  REQUEST_JAVS,
  SUCCESS_JAVS,
  ERROR_JAVS,
  CLEAR_JAVS,
  JavsActions,
  RequestJavsParams,
  SuccessRequestJavs,
  ErrorRequestJavs,
  ClearJavs,
} from './Types'

export function requestJavs(params: RequestJavsParams): JavsActions {
  return {
    type: REQUEST_JAVS,
    payload: params,
  }
}

export function successRequestJavs(response: SuccessRequestJavs): JavsActions {
  return {
    type: SUCCESS_JAVS,
    data: response,
  }
}

export function errorRequestJavs(response: ErrorRequestJavs): JavsActions {
  return {
    type: ERROR_JAVS,
    error: response,
  }
}

export function clearJavs(clear: ClearJavs): JavsActions {
  return {
    type: CLEAR_JAVS,
    clear,
  }
}

export default {
  requestJavs,
  clearJavs,
  successRequestJavs,
  errorRequestJavs,
}
