import {
  SPIRITUAL_FOOD_FORM_SET_CURRENT_DAY,
  SPIRITUAL_FOOD_FORM_SET_CURRENT_WEEK,
  SPIRITUAL_FOOD_FORM_SET_DETAILS,
  SPIRITUAL_FOOD_FORM_SET_COLUMN_CURRENT_WEEK,
  SPIRITUAL_FOOD_FORM_SET_COLUMN_CURRENT_DAY,
  SPIRITUAL_FOOD_FORM_SET_VERSE,
  SPIRITUAL_FOOD_FORM_ADD_VERSE,
  SPIRITUAL_FOOD_FORM_REMOVE_VERSE,
  SPIRITUAL_FOOD_FORM_CLEAR
} from './Types'

export function setDetails(payload) {
  return {
    type: SPIRITUAL_FOOD_FORM_SET_DETAILS,
    payload
  }
}

export function setColumn(column, value) {
  return {
    type: SPIRITUAL_FOOD_FORM_SET_DETAILS,
    payload: {
      [column]: value
    }
  }
}

export function setCurrentWeekColumn(column, value) {
  return {
    type: SPIRITUAL_FOOD_FORM_SET_COLUMN_CURRENT_WEEK,
    payload: {
      [column]: value
    }
  }
}

export function setCurrentWeekValue(payload) {
  return {
    type: SPIRITUAL_FOOD_FORM_SET_COLUMN_CURRENT_WEEK,
    payload
  }
}

export function setCurrentDayValue(payload) {
  return {
    type: SPIRITUAL_FOOD_FORM_SET_COLUMN_CURRENT_DAY,
    payload
  }
}


export function setCurrentDayColumn(column, value) {
  return {
    type: SPIRITUAL_FOOD_FORM_SET_COLUMN_CURRENT_DAY,
    payload: {
      [column]: value
    }
  }
}

export function setVerse(verse, index) {
  return {
    type: SPIRITUAL_FOOD_FORM_SET_VERSE,
    payload: {
      index,
      verse
    }
  }
}

export function removeVerse(index) {
  return {
    type: SPIRITUAL_FOOD_FORM_REMOVE_VERSE,
    payload: {
      index
    }
  }
}

export function addVerse() {
  return {
    type: SPIRITUAL_FOOD_FORM_ADD_VERSE
  }
}

export function setCurrentWeek(index) {
  return {
    type: SPIRITUAL_FOOD_FORM_SET_CURRENT_WEEK,
    index
  }
}

export function setCurrentDay(index) {
  return {
    type: SPIRITUAL_FOOD_FORM_SET_CURRENT_DAY,
    index
  }
}

export function clear() {
  return {
    type: SPIRITUAL_FOOD_FORM_CLEAR
  }
}

export default {
  setColumn,
  setDetails,
  setCurrentWeek,
  setCurrentDayColumn,
  clear,
  setCurrentDay
}
