import {
  REQUEST_SPIRITUAL_FOODS,
  SUCCESS_SPIRITUAL_FOODS,
  ERROR_SPIRITUAL_FOODS,
  CLEAR_SPIRITUAL_FOODS,
  SpiritualFoodsActions,
  RequestSpiritualFoodsParams,
  SuccessRequestSpiritualFoods,
  ErrorRequestSpiritualFoods,
  ClearSpiritualFoods,
} from './Types'

export function requestSpiritualFoods(params: RequestSpiritualFoodsParams): SpiritualFoodsActions {
  return {
    type: REQUEST_SPIRITUAL_FOODS,
    payload: params,
  }
}

export function successRequestSpiritualFoods(response: SuccessRequestSpiritualFoods): SpiritualFoodsActions {
  return {
    type: SUCCESS_SPIRITUAL_FOODS,
    data: response,
  }
}

export function errorRequestSpiritualFoods(response: ErrorRequestSpiritualFoods): SpiritualFoodsActions {
  return {
    type: ERROR_SPIRITUAL_FOODS,
    error: response,
  }
}

export function clearSpiritualFoods(clear: ClearSpiritualFoods): SpiritualFoodsActions {
  return {
    type: CLEAR_SPIRITUAL_FOODS,
    clear,
  }
}

export default {
  requestSpiritualFoods,
  clearSpiritualFoods,
  successRequestSpiritualFoods,
  errorRequestSpiritualFoods,
}
