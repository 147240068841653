import React, { useState } from 'react'
import { Select, MenuItem, withStyles } from '@material-ui/core'

const SelectTypeContent = ({ classes, onChange }) => {
  const [value, setValue] = useState('spiritual_foods')
  const handleOnChange = e => {
    onChange(e.target.value)
    setValue(e.target.value)
  }
  return (
    <Select
      id="select"
      placeholder="Selecione Tipo de conteúdo"
      fullWidth={true}
      value={value}
      onChange={handleOnChange}
      className={classes.select}
    >
      <MenuItem value="spiritual-foods">Alimento diário</MenuItem>
      <MenuItem value="books">Livro</MenuItem>
      <MenuItem value="javs">Jav</MenuItem>
    </Select>
  )
}

const styles = () => ({
  select: {
    '&:after': {
      borderColor: "#007332",
    }
  },
  icon: {
    fill: "#007332"
  },
});

export default withStyles(styles)(SelectTypeContent)
