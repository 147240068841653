import React, { useState, useMemo } from 'react'

import { deleteSubscriptionContent } from '../../services/SubscriptionService'

import Button from '../../components/CustomButtons/Button'

const SubscriptionContentDelete = ({ id, onSuccess }) => {
  const [step, setStep] = useState<any>('default')
  const [error, setError] = useState<any>(null)
  const [loading, setLoading] = useState(false)

  const disabled = useMemo(() => {
    return loading
  }, [loading])

  const handleSubmit = () => {
    setLoading(true)
    setError(null)
    const payload = { id }
    deleteSubscriptionContent(payload)
    .then(response => {
      if (response.type === 'error') {
        setLoading(false)
        setError(response.message)
        return
      }
      setError(null)
      setLoading(false)
      onSuccess()
    })
    .catch(err => {
      setError(err.message)
      setLoading(false)
    })
  }

  return useMemo(() => {
    if (error) {
      return <span style={{ color: 'red' }}>{error}</span>
    }
    if (step === 'default') {
      return (
        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
          <Button
            color="default"
            onClick={() => setStep('remove')} 
          >Remover</Button>
        </div>
      )
    }
    return (
      <Button
        disabled={disabled}
        color="danger"
        onClick={handleSubmit} 
      >Clique novamente para confirmar</Button>
    )
  }, [step, disabled, error])
}

export default SubscriptionContentDelete
