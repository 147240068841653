import { RecoveryPasswordState, RecoveryPasswordTypes } from './Types'

const initialState: RecoveryPasswordState = {
  requesting: false,
  error: false,
  success: false,
  message: null,
}

export function reducer(state = initialState, action: any): RecoveryPasswordState {
  switch (action.type) {
    case RecoveryPasswordTypes.REQUEST_RECOVERY_PASSWORD:
      return {
        requesting: true,
        error: false,
        success: false,
        message: null,
      }
    case RecoveryPasswordTypes.SUCCESS_RECOVERY_PASSWORD:
      return {
        requesting: false,
        error: false,
        success: true,
        message: action.data.message,
      }
    case RecoveryPasswordTypes.ERROR_RECOVERY_PASSWORD:
      return {
        requesting: false,
        error: true,
        success: false,
        message: action.error.message,
      }
    case RecoveryPasswordTypes.CLEAR_RECOVERY_PASSWORD:
      return {
        requesting: false,
        error: false,
        success: false,
        message: null,
      }
    case RecoveryPasswordTypes.CLEAR_ERROR:
      return {
        ...state,
        error: false,
        message: null
      }
  }
  return state
}
