import React, { useMemo, useState, useEffect, useCallback } from 'react'
import { Link } from 'react-router-dom'
import { pickBy, identity } from 'lodash'
import AddOutlined from '@material-ui/icons/AddOutlined'
import AddCircle from '@material-ui/icons/AddCircle'

import { createEditButton, createAccessCodeButton, buildButtonAction } from '../../components/ContentList/ContentList'
import { fetchAccessCode } from '../../services/AccessCodeService'

import GridItem from '../../components/Grid/GridItem'
import GridContainer from '../../components/Grid/GridContainer'
import Table from '../../components/Table/Table'
import Card from '../../components/Card/Card'
import CardBody from '../../components/Card/CardBody'
import Paginate from '../../components/Paginate'
import CardFooter from '../../components/Card/CardFooter'
import Loading from '../../components/Loading/Loading'
import Error from '../../components/Error/Error'
import Button from '../../components/CustomButtons/Button'
import CustomInput from '../../components/CustomInput/CustomInput'
import AccessCodeSwitch from './AccessCodeSwitch'
import AccessCodeAdd from './AccessCodeAdd'
import AccessCodeDownload from './AccessCodeDownload'

const AccessCodeList: React.FC<any> = props => {
  const params = new URLSearchParams(props.location.search)
  const defaultFilters = {
    text: params.get('text'),
    page: params.get('page') ? Number(params.get('page')) : null,
    jav_id: params.get('jav_id') ? Number(params.get('jav_id')) : null,
    book_id: params.get('book_id') ? Number(params.get('book_id')) : null,
    spiritual_food_id: params.get('spiritual_food_id') ? Number(params.get('spiritual_food_id')) : null,
    subscription_id: params.get('subscription_id') ? Number(params.get('subscription_id')) : null,
  }

  const [items, setItems] = useState([])
  const [error, setError] = useState<any>(null)
  const [success, setSuccess] = useState(false)
  const [loading, setLoading] = useState(false)
  const [filters, setFilters] = useState(defaultFilters)
  const [paginateState, setPaginateState] = useState({ page: 0,  lastPage: 0,  perPage: 0, total: 0, })

  useEffect(() => {
    fetchData(filters)
  }, [])

  useEffect(() => {
    fetchData(filters)
    changeUrl(filters)
  }, [filters])

  const changeUrl = (filters) => {
    const params = new URLSearchParams(pickBy(filters, identity))
    const newUrl = props.location.pathname + '?' + params.toString()
    props.history.push(newUrl)
  }

  const onChangePage = useCallback(page => {
    setFilters({
      ...filters,
      page
    })
  }, [filters])

  const fetchData = filters => {
    setLoading(true)
    setError(null)
    setSuccess(false)
    fetchAccessCode(filters)
      .then(response => {
        if (response.type === 'error') {
          setSuccess(false)
          setError(response.message)
          setLoading(false)
          return
        }
        const data = response.details
        setPaginateState({
          page: +data.page,
          lastPage: +data.lastPage,
          perPage: +data.perPage,
          total: +data.total,
        })
        setItems(data.data)
        setSuccess(true)
        setError(null)
        setLoading(false)
      })
      .catch(err => {
        setSuccess(false)
        setError(err.message)
        setLoading(false)
      })
  }

  const ListContent = useMemo(() => {
    const data = items.map(createContentItem)
    if (data.length === 0) {
      if (loading) {
        return null
      }
      return <p>Nenhum registro encontrado</p>
    }
    return (
      <Table
        tableHeaderColor="primary"
        tableHead={['Id', 'Código de acesso', 'Usuário', 'Código ativo']}
        tableData={data}
      />
    )
  }, [items, loading])

  return (
    <div>
      <AccessCodeAdd params={filters} onSuccess={() => fetchData(filters)} />
      <AccessCodeDownload 
        params={{
          jav_id: filters.jav_id,
          book_id: filters.book_id,
          spiritual_food_id: filters.spiritual_food_id,
          subscription_id: filters.subscription_id
        }} 
      />
      <Search 
        onChange={text => setFilters({ ...filters, text, page: 1 })} 
        defaultText={defaultFilters.text} 
      />
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              <Error message={error} hide={!error} />
              <Loading message={"Aguarde..."} hide={!loading} />
              {ListContent}
            </CardBody>
            <CardFooter>
              {useMemo(() => {
                if (!success) {
                  return null
                }
                return <Paginate changePage={onChangePage} {...paginateState} />
              }, [success, paginateState])}
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  )
}

const createContentItem = (item) => {
  return [item.id, item.access_code, item.user_id || 'Nenhum', createItemActions(item)]
}

const createItemActions = (item : any) => (
  <AccessCodeSwitch accessCode={item.access_code} defaultChecked={item.is_active} />
)

const Search = ({ defaultText, onChange }) => {
  const [text, setText] = useState(defaultText || '')
  return (
    <div>
      <CustomInput
        labelText="Filtrar por título"
        id="search"
        formControlProps={{
          fullWidth: true
        }}
        inputProps={{
          defaultValue: text,
          onChange: (e) => setText(e.target.value)
        }}
      />
      <Button onClick={() => onChange(text)}>Filtrar</Button>
    </div>
  )
}

export default AccessCodeList
