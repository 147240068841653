import { put, call } from 'redux-saga/effects'
import { javsService } from '../services/JavsService'

import JavActions from '../stores/Jav/Actions'
import JavEditActions from '../stores/JavEdit/Actions'
import JavAddActions from '../stores/JavAdd/Actions'

import JavsActions from '../stores/Javs/Actions'
import { RequestJavsAction } from '../stores/Javs/Types'

import { ResponseService } from '../services/Service'
import { RequestJavAddAction } from '../stores/JavAdd/Types'
import { RequestJavAction } from '../stores/Jav/Types'
import { RequestJavEditAction } from '../stores/JavEdit/Types'

export function* getJavs(action: RequestJavsAction) {
  const response: ResponseService = yield call < any > (javsService.fetchJavs, action.payload)
  if (response.type === 'success') {
    let javs = response.details.data
    let lastPage = Number(response.details.lastPage) || 0
    let page = Number(response.details.page) || 0
    let total = Number(response.details.total) || 0
    let perPage = Number(response.details.perPage) || 0
    let data = {
      key: action.payload.key,
      javs,
      lastPage,
      page,
      total,
      perPage
    }
    yield put(JavsActions.successRequestJavs(data))
  } else {
    let message = response.message || ''
    yield put(
      JavsActions.errorRequestJavs({
        key: action.payload.key,
        message,
      })
    )
  }
}

export function* getJav(action: RequestJavAction) {
  const response: ResponseService = yield call<any>(javsService.fetchJav, action.payload.id)
  if (response.type === 'success') {
    let data = {
      jav: response.details
    }
    yield put(JavActions.successRequestJav(data))
  } else {
    let message = response.message || ''
    yield put(
      JavActions.errorRequestJav({
        message
      })
    )
  }
}

export function* javAdd(action: RequestJavAddAction) {
  const response: ResponseService = yield call<any>(javsService.javAdd, action.payload)
  if (response.type === 'success') {
    yield put(JavAddActions.successRequestJavAdd({
      jav: response.details,
      message: 'JAV adicionado com sucesso'
    }))
  } else {
    let message = response.message || ''
    yield put(
      JavAddActions.errorRequestJavAdd({
        errors: response.details,
        message,
      })
    )
  }
}

export function* javEdit(action: RequestJavEditAction) {
  const response: ResponseService = yield call<any>(javsService.javEdit, action.payload)
  if (response.type === 'success') {
    yield put(JavEditActions.successRequestJavEdit({
      jav: response.details,
      message: 'JAV editado com sucesso'
    }))
  } else {
    let message = response.message || ''
    yield put(
      JavEditActions.errorRequestJavEdit({
        errors: response.details,
        message,
      })
    )
  }
}
