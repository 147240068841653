export const REQUEST_JAVS: any = 'REQUEST_JAVS'
export const SUCCESS_JAVS = 'SUCCESS_JAVS'
export const ERROR_JAVS = 'ERROR_JAVS'
export const CLEAR_JAVS = 'CLEAR_JAVS'


export interface JavsStateItem {
  key: string;
  books: Array<Object>;
  search: string;
  page: number;
  lastPage: number;
  limit: number;
  requesting: boolean;
  error: boolean;
  success: boolean;
  message: string;
}

export interface JavsState {
  items: Array<JavsStateItem> 
}

export interface RequestJavsParams {
  key: string;
  page: number;
  search?: string;
  limit?: number;
  clear?: boolean;
}

export interface ErrorRequestJavs {
  key: string;
  message: string;
}

export interface ClearJavs {
  key: string;
}

export interface SuccessRequestJavs {
  key: string;
  javs: Array<Object>;
  lastPage: number;
  page: number;
  total: number;
  perPage: number;
}

export interface RequestJavsAction {
  type: typeof REQUEST_JAVS
  payload: RequestJavsParams
}

export interface SuccessRequestJavsAction {
  type: typeof SUCCESS_JAVS
  data: SuccessRequestJavs
}

export interface ErrorRequestJavsAction {
  type: typeof ERROR_JAVS
  error: ErrorRequestJavs
}

export interface ClearJavsAction {
  type: typeof CLEAR_JAVS,
  clear: ClearJavs
}

export type JavsActions = RequestJavsAction | SuccessRequestJavsAction | ErrorRequestJavsAction | ClearJavsAction

export const JavsTypes = {
  REQUEST_JAVS,
  SUCCESS_JAVS,
  ERROR_JAVS,
  CLEAR_JAVS,
}