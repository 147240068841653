import {
  REQUEST_RELEASE_EDIT,
  SUCCESS_RELEASE_EDIT,
  ERROR_RELEASE_EDIT,
  CLEAR_RELEASE_EDIT,
  ReleaseEditActions,
  RequestReleaseEditParams,
  SuccessRequestReleaseEdit,
  ErrorRequestReleaseEdit
} from './Types'

export function requestReleaseEdit(params: RequestReleaseEditParams): ReleaseEditActions {
  return {
    type: REQUEST_RELEASE_EDIT,
    payload: params,
  }
}

export function successRequestReleaseEdit(response: SuccessRequestReleaseEdit): ReleaseEditActions {
  return {
    type: SUCCESS_RELEASE_EDIT,
    data: response,
  }
}

export function errorRequestReleaseEdit(response: ErrorRequestReleaseEdit): ReleaseEditActions {
  return {
    type: ERROR_RELEASE_EDIT,
    error: response,
  }
}

export function clearReleaseEdit(): ReleaseEditActions {
  return {
    type: CLEAR_RELEASE_EDIT
  }
}

export default {
  requestReleaseEdit,
  clearReleaseEdit,
  successRequestReleaseEdit,
  errorRequestReleaseEdit,
}
