import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Actions } from '../../stores/SpiritualFoodForm'

import GridItem from '../../components/Grid/GridItem'
import GridContainer from '../../components/Grid/GridContainer'
import CustomInput from '../../components/CustomInput/CustomInput'
import Card from '../../components/Card/Card'
import CardBody from '../../components/Card/CardBody'
import CustomSwitch from '../../components/CustomSwitch/CustomSwitch'
import CustomTagsInput from '../../components/CustomInput/CustomTagsInput'
import CustomFileUpload from '../../components/CustomFileUpload/CustomFileUpload'
import Loading from '../../components/Loading/Loading'
import ErrorItem, { useSpiritualFoodFormError } from './ErrorItem'

export default function SpiritualFoodAdd() {
  const dispatch = useDispatch()
  const state: any = useSelector<any>(state => state.spiritualFoodForm)
  const onChange = (column, value) => {
    dispatch(Actions.setColumn(column, value ))
  }

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card style={{ marginTop: 0 }}>
          <CardBody>
            <Loading
              message="Aguarde um momento ..."
              hide={!state.disabled}
            />
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText="Título do Alimento Diário"
                  id="title"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    defaultValue: state.title,
                    onChange: (e) => onChange('title', e.target.value),
                    disabled: state.disabled
                  }}
                  error={useSpiritualFoodFormError('title')}
                />
                <ErrorItem message={useSpiritualFoodFormError('title')} />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText="Link do Youtube"
                  id="link"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    defaultValue: state.youtube_link,
                    onChange: (e) => onChange('youtube_link', e.target.value),
                    disabled: state.disabled
                  }}
                  error={useSpiritualFoodFormError('youtube_link')}
                />
                <ErrorItem message={useSpiritualFoodFormError('youtube_link')} />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <CustomTagsInput
                  defaultTags={state.tags}
                  onChange={tags => onChange('tags', tags)}
                  disabled={state.disabled}
                />
                <ErrorItem message={useSpiritualFoodFormError('tags')} />
              </GridItem>
              <GridItem xs={12} sm={6} md={3}>
                <CustomSwitch 
                  label="Público?"
                  checked={state.is_public}
                  onChange={value => onChange('is_public', value)}
                  disabled={state.disabled}
                />
                <ErrorItem message={useSpiritualFoodFormError('is_public')} />
              </GridItem>
              <GridItem xs={12} sm={6} md={3}>
                <CustomSwitch 
                  label="Ativo?"
                  checked={state.active}
                  onChange={value => onChange('active', value)}
                  disabled={state.disabled}
                />
                <ErrorItem message={useSpiritualFoodFormError('active')} />
              </GridItem>
            </GridContainer>
            <div style={{marginTop: '30px'}} />
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <CustomFileUpload
                  title={
                    state.image instanceof Object 
                    ? <a href={state.image.url} target="blank">{`Trocar Imagem ${state.image.name}`}</a>
                    : 'Adicionar Imagem'
                  }
                  acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
                  onChange={files => {
                    const file = files && files.length > 0 ? files[0] : null
                    onChange('image', file)
                  }}
                  defaultFile={state.image ? state.image instanceof File ? state.image : state.image.id : null}
                  disabled={state.disabled}
                />
                <ErrorItem message={useSpiritualFoodFormError('image_id')} />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <CustomFileUpload
                  title={
                    state.pdf instanceof Object 
                    ? <a href={state.pdf.url} target="blank">{`Trocar PDF ${state.pdf.name}`}</a>
                    : 'Adicionar PDF'
                  }
                  acceptedFiles={['application/pdf']}
                  onChange={files => {
                    const file = files && files.length > 0 ? files[0] : null
                    onChange('pdf', file)
                  }}
                  defaultFile={state.pdf ? state.pdf instanceof File ? state.pdf : state.pdf.id : null}
                  disabled={state.disabled}
                />
                <ErrorItem message={useSpiritualFoodFormError('pdf_id')} />
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  )
}
