import {
  REQUEST_JAV_ADD,
  SUCCESS_JAV_ADD,
  ERROR_JAV_ADD,
  CLEAR_JAV_ADD,
  JavAddActions,
  RequestJavAddParams,
  SuccessRequestJavAdd,
  ErrorRequestJavAdd
} from './Types'

export function requestJavAdd(params: RequestJavAddParams): JavAddActions {
  return {
    type: REQUEST_JAV_ADD,
    payload: params,
  }
}

export function successRequestJavAdd(response: SuccessRequestJavAdd): JavAddActions {
  return {
    type: SUCCESS_JAV_ADD,
    data: response,
  }
}

export function errorRequestJavAdd(response: ErrorRequestJavAdd): JavAddActions {
  return {
    type: ERROR_JAV_ADD,
    error: response,
  }
}

export function clearJavAdd(): JavAddActions {
  return {
    type: CLEAR_JAV_ADD
  }
}

export default {
  requestJavAdd,
  clearJavAdd,
  successRequestJavAdd,
  errorRequestJavAdd,
}
