import {
  SPIRITUAL_FOOD_FORM_SET_COLUMN_CURRENT_DAY,
  SPIRITUAL_FOOD_FORM_SET_COLUMN_CURRENT_WEEK,
  SPIRITUAL_FOOD_FORM_SET_CURRENT_WEEK,
  SPIRITUAL_FOOD_FORM_SET_CURRENT_DAY,
  SPIRITUAL_FOOD_FORM_SET_DETAILS,
  SPIRITUAL_FOOD_FORM_SET_VERSE,
  SPIRITUAL_FOOD_FORM_ADD_VERSE,
  SPIRITUAL_FOOD_FORM_REMOVE_VERSE,
  SPIRITUAL_FOOD_FORM_CLEAR
} from './Types'

const createDefaultDays = () => ([
  {
    order: 1,
    title: '',
    text: '',
    verses: [
      { title: '', text: '' }
    ]
  },
  {
    order: 2,
    title: '',
    text: '',
    verses: [
      { title: '', text: '' }
    ]
  },
  {
    order: 3,
    title: '',
    text: '',
    verses: [
      { title: '', text: '' }
    ]
  },
  {
    order: 4,
    title: '',
    text: '',
    verses: [
      { title: '', text: '' }
    ]
  },
  {
    order: 5,
    title: '',
    text: '',
    verses: [
      { title: '', text: '' }
    ]
  },
  {
    order: 6,
    title: '',
    text: '',
    verses: [
      { title: '', text: '' }
    ]
  },
  {
    order: 7,
    title: '',
    text: '',
    verses: [
      { title: '', text: '' }
    ]
  },
])

export const createWeek = (order) => {
  return {
    "order": order,
    "title": "",
    youtube_link: '',
    days: [createDay(1)]
  }
}

export const createDay = (order) => {
  return {
    order,
    title: '',
    text: '',
    verses: [
      { title: '', text: '' }
    ]
  }
}

const createInitialData = () => ({
  errors: null,
  disabled: false,
  currentWeekIndex: 0,
  currentDayIndex: 0,
  title: '',
  tags: [],
  weeks: [
    {
      "order": 1,
      "title": "",
      youtube_link: '',
      days: [createDay(1)]
    }
  ]
})

const initialState: any = createInitialData()

export function reducer(state = initialState, action: any): any {
  let index
  let indexDay
  let day
  let weeks
  let week
  switch (action.type) {
    case SPIRITUAL_FOOD_FORM_ADD_VERSE:
      index = state.currentWeekIndex
      indexDay = state.currentDayIndex
      weeks = state.weeks.slice()
      week = weeks[index]
      day = { ...week.days[indexDay] }
      day.verses = [...day.verses, { title: '', text: '' }]
      week.days[indexDay] = day
      return {
        ...state,
        weeks
      }
    case SPIRITUAL_FOOD_FORM_REMOVE_VERSE:
      index = state.currentWeekIndex
      indexDay = state.currentDayIndex
      weeks = state.weeks.slice()
      week = weeks[index]
      day = { ...week.days[indexDay] }
      day.verses = [...day.verses]
      day.verses.splice(action.payload.index, 1)
      week.days[indexDay] = day
      return {
        ...state,
        weeks
      }
    case SPIRITUAL_FOOD_FORM_SET_VERSE:
      index = state.currentWeekIndex
      indexDay = state.currentDayIndex
      weeks = state.weeks.slice()
      week = weeks[index]
      day = { ...week.days[indexDay] }
      day.verses = [...day.verses]
      day.verses[action.payload.index] = action.payload.verse
      week.days[indexDay] = day
      return {
        ...state,
        weeks
      }
    case SPIRITUAL_FOOD_FORM_SET_COLUMN_CURRENT_DAY:
      index = state.currentWeekIndex
      indexDay = state.currentDayIndex
      weeks = state.weeks.slice()
      week = weeks[index]
      week.days[indexDay] = {
        ...week.days[indexDay],
        ...action.payload
      }
      return {
        ...state,
        weeks
      }
    case SPIRITUAL_FOOD_FORM_SET_COLUMN_CURRENT_WEEK:
      index = state.currentWeekIndex
      weeks = state.weeks.slice()
      weeks[index] = {
        ...state.weeks[index],
        ...action.payload
      }
      return {
        ...state,
        weeks
      }
    case SPIRITUAL_FOOD_FORM_SET_CURRENT_WEEK:
      index = action.index
      return {
        ...state,
        currentWeekIndex: index
      }
    case SPIRITUAL_FOOD_FORM_SET_CURRENT_DAY:
      index = action.index
      return {
        ...state,
        currentDayIndex: index
      }
    case SPIRITUAL_FOOD_FORM_SET_DETAILS:
      return {
        ...state,
        ...action.payload
      }
    case SPIRITUAL_FOOD_FORM_CLEAR:
      return createInitialData()
    default:
      return state
  }
}
