import React, { useState, useCallback, useMemo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { BookEditState } from '../../stores/BookEdit/Types'
import { requestBookEdit, clearBookEdit } from '../../stores/BookEdit/Actions'

import { BookState } from '../../stores/Book/Types'
import { requestBook, clearBook } from '../../stores/Book/Actions'

import GridItem from '../../components/Grid/GridItem'
import GridContainer from '../../components/Grid/GridContainer'
import CustomInput from '../../components/CustomInput/CustomInput'
import Button from '../../components/CustomButtons/Button'
import Card from '../../components/Card/Card'
import CardBody from '../../components/Card/CardBody'
import CardFooter from '../../components/Card/CardFooter'
import CustomSwitch from '../../components/CustomSwitch/CustomSwitch'
import CustomTagsInput from '../../components/CustomInput/CustomTagsInput'
import CustomFileUpload from '../../components/CustomFileUpload/CustomFileUpload'
import Loading from '../../components/Loading/Loading'
import SnackbarContent from '../../components/Snackbar/SnackbarContent'

function BookForm() {
  const dispatch = useDispatch()
  const bookState = useSelector<any>(state => state.book) as BookState
  const bookEditState = useSelector<any>(state => state.bookEdit) as BookEditState

  const book: any = bookState.book
  const [title, setTitle] = useState(book.title)
  const [isActive, setIsActive] = useState(book.active)
  const [isPublic, setIsPublic] = useState(book.is_public)
  const [tags, setTags] = useState(book.tags.map(t => t.title))
  const [image, setImage] = useState(book.image.id)
  const [pdf, setPdf] = useState(book.pdf.id)

  useEffect(() => {
    return () => {
      dispatch(clearBookEdit())
    }
  }, [dispatch])

  useEffect(() => {
    if (bookEditState.success) {
      setTimeout(() => {
        try {
          dispatch(requestBook({ id: book.id }))
        } catch(err) {}
      }, 1000)
    }
  }, [bookEditState.success])

  const handleSubmit = useCallback(() => {
    const data = {
      id: book.id,
      title,
      active: isActive,
      is_public: isPublic,
      tags,
      image,
      pdf
    }
    dispatch(requestBookEdit(data))
  }, [title, isActive, isPublic, tags, image, pdf])

  const disabled = useMemo(() => bookEditState.requesting, [bookEditState.requesting])
  const errors: any = useMemo(() => bookEditState.errors, [bookEditState.errors])
  
  const Message = useMemo(() => {
    if (!bookEditState.message) {
      return null
    }
    return (
      <SnackbarContent 
        color={bookEditState.success ? 'success': 'danger'} 
        message={bookEditState.message} 
      />
    )
  }, [bookEditState.message, bookEditState.success])

  function ErrorItem({ message }) {
    if (!message) return null
    return <span style={{color: 'red'}}>{message}</span>
  }

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              <h4><b>#{book.id} - {book.title}</b></h4>
              <Loading
                message="Aguarde um momento ..."
                hide={!disabled}
              />
              {Message}
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Título"
                    id="title"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      defaultValue: title,
                      onChange: (e) => setTitle(e.target.value),
                      disabled
                    }}
                    error={errors && errors.title}
                  />
                  <ErrorItem message={errors ? errors.title : null} />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomSwitch 
                    label="Público?"
                    checked={isPublic}
                    onChange={setIsPublic}
                    disabled={disabled}
                  />
                  <ErrorItem message={errors ? errors.is_public : null} />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomSwitch 
                    label="Ativo?"
                    checked={isActive}
                    onChange={setIsActive}
                    disabled={disabled}
                  />
                  <ErrorItem message={errors ? errors.active : null} />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomTagsInput
                    defaultTags={tags}
                    onChange={setTags}
                    disabled={disabled}
                  />
                  <ErrorItem message={errors ? errors.tags : null} />
                </GridItem>
              </GridContainer>
              <div style={{marginTop: '30px'}} />
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomFileUpload
                    title={<a href={book.image.url} target="blank">{`Trocar Imagem ${book.image.name}`}</a>}
                    acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
                    onChange={files => {
                      const file = files && files.length > 0 ? files[0] : null
                      setImage(file)
                    }}
                    disabled={disabled}
                  />
                  <ErrorItem message={errors ? errors.image_id : null} />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomFileUpload 
                    title={<a href={book.pdf.url} target="blank">{`Trocar PDF ${book.pdf.name}`}</a>}
                    acceptedFiles={['application/pdf']}
                    onChange={files => {
                      const file = files && files.length > 0 ? files[0] : null
                      setPdf(file)
                    }}
                    disabled={disabled}
                  />
                  <ErrorItem message={errors ? errors.pdf_id : null} />
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter>
              <Button 
                onClick={handleSubmit} 
                color="primary"
              >Editar Livro</Button>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  )
}

const BookEdit = (props) => {
  const id = props.match.params.id
  const dispatch = useDispatch()
  const bookState = useSelector<any>(state => state.book) as BookState
  
  useEffect(() => {
    dispatch(requestBook({ id }))
    return () => {
      dispatch(clearBook())
    }
  }, [])

  return useMemo(() => {
    if (bookState.success) {
      return <BookForm />
    }
    if (bookState.error) {
      return (
        <SnackbarContent 
          color="danger" 
          message={bookState.message}
        />
      )
    }
    return <Loading hide={false}  message="Aguarde ..." />
  }, [bookState])
}

export default BookEdit
