import {
  REQUEST_RELEASE_ADD,
  SUCCESS_RELEASE_ADD,
  ERROR_RELEASE_ADD,
  CLEAR_RELEASE_ADD,
  ReleaseAddActions,
  RequestReleaseAddParams,
  SuccessRequestReleaseAdd,
  ErrorRequestReleaseAdd
} from './Types'

export function requestReleaseAdd(params: RequestReleaseAddParams): ReleaseAddActions {
  return {
    type: REQUEST_RELEASE_ADD,
    payload: params,
  }
}

export function successRequestReleaseAdd(response: SuccessRequestReleaseAdd): ReleaseAddActions {
  return {
    type: SUCCESS_RELEASE_ADD,
    data: response,
  }
}

export function errorRequestReleaseAdd(response: ErrorRequestReleaseAdd): ReleaseAddActions {
  return {
    type: ERROR_RELEASE_ADD,
    error: response,
  }
}

export function clearReleaseAdd(): ReleaseAddActions {
  return {
    type: CLEAR_RELEASE_ADD
  }
}

export default {
  requestReleaseAdd,
  clearReleaseAdd,
  successRequestReleaseAdd,
  errorRequestReleaseAdd,
}
