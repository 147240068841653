export const REQUEST_CONTENTS: any = 'REQUEST_CONTENTS'
export const SUCCESS_CONTENTS = 'SUCCESS_CONTENTS'
export const ERROR_CONTENTS = 'ERROR_CONTENTS'
export const CLEAR_CONTENTS = 'CLEAR_CONTENTS'


export interface ContentsStateItem {
  key: string;
  contents: Array<Object>;
  search: string;
  page: number;
  lastPage: number;
  limit: number;
  requesting: boolean;
  error: boolean;
  success: boolean;
  message: string;
}

export interface ContentsState {
  items: Array<ContentsStateItem> 
}

export interface RequestContentsParams {
  key: string;
  page: number;
  search?: string;
  limit?: number;
  clear?: boolean;
}

export interface ErrorRequestContents {
  key: string;
  message: string;
}

export interface ClearContents {
  key: string;
}

export interface SuccessRequestContents {
  key: string;
  contents: Array<Object>;
  lastPage: number;
}

export interface RequestContentsAction {
  type: typeof REQUEST_CONTENTS
  payload: RequestContentsParams
}

export interface SuccessRequestContentsAction {
  type: typeof SUCCESS_CONTENTS
  data: SuccessRequestContents
}

export interface ErrorRequestContentsAction {
  type: typeof ERROR_CONTENTS
  error: ErrorRequestContents
}

export interface ClearContentsAction {
  type: typeof CLEAR_CONTENTS,
  clear: ClearContents
}

export type ContentsActions = RequestContentsAction | SuccessRequestContentsAction | ErrorRequestContentsAction | ClearContentsAction

export const ContentsTypes = {
  REQUEST_CONTENTS,
  SUCCESS_CONTENTS,
  ERROR_CONTENTS,
  CLEAR_CONTENTS,
}