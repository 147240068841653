import React from 'react'
import Pagination from 'react-js-pagination'

const Paginate = ({ perPage, page, total, lastPage, changePage }) => {
  return (
    <Pagination
      hideDisabled={true}
      activePage={page || 0}
      itemsCountPerPage={perPage || 0}
      totalItemsCount={total || 0}
      onChange={changePage}
    />
  )
  //return (
  //  <table>
  //    <tbody>
  //      <tr>
  //        <TablePagination
  //          labelDisplayedRows={({ from, to, count, page }) => {
  //            return `Total: ${count} - Items por página: ${perPage} - Página: ${page}`
  //          }}
  //          rowsPerPageOptions={[ perPage || 0 ]}
  //          rowsPerPage={perPage || 0}
  //          count={total || 0}
  //          page={page || 0} 
  //          onChangePage={(e, page) => changePage(page)}
  //        />
  //      </tr>
  //    </tbody>
  //  </table>
  //)
};

export default Paginate
