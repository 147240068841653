export const REQUEST_BOOK: any = 'REQUEST_BOOK'
export const SUCCESS_BOOK = 'SUCCESS_BOOK'
export const ERROR_BOOK = 'ERROR_BOOK'
export const CLEAR_BOOK = 'CLEAR_BOOK'

export interface BookState {
  id: number | string | null;
  book: Object | null;
  requesting: boolean;
  error: boolean;
  success: boolean;
  message: string | null;
}

export interface RequestBookParams {
  id: number | string;
}

export interface ErrorRequestBook {
  message: string;
}

export interface ClearBook {
}

export interface SuccessRequestBook {
  book: Object;
}

export interface RequestBookAction {
  type: typeof REQUEST_BOOK
  payload: RequestBookParams
}

export interface SuccessRequestBookAction {
  type: typeof SUCCESS_BOOK
  data: SuccessRequestBook
}

export interface ErrorRequestBookAction {
  type: typeof ERROR_BOOK
  error: ErrorRequestBook
}

export interface ClearBookAction {
  type: typeof CLEAR_BOOK
}

export type BookActions = RequestBookAction | SuccessRequestBookAction | ErrorRequestBookAction | ClearBookAction

export const BookTypes = {
  REQUEST_BOOK,
  SUCCESS_BOOK,
  ERROR_BOOK,
  CLEAR_BOOK,
}