import {
  REQUEST_SPIRITUAL_FOOD_EDIT,
  SUCCESS_SPIRITUAL_FOOD_EDIT,
  ERROR_SPIRITUAL_FOOD_EDIT,
  CLEAR_SPIRITUAL_FOOD_EDIT,
  SpiritualFoodEditActions,
  RequestSpiritualFoodEditParams,
  SuccessRequestSpiritualFoodEdit,
  ErrorRequestSpiritualFoodEdit
} from './Types'

export function requestSpiritualFoodEdit(params: RequestSpiritualFoodEditParams): SpiritualFoodEditActions {
  return {
    type: REQUEST_SPIRITUAL_FOOD_EDIT,
    payload: params,
  }
}

export function successRequestSpiritualFoodEdit(response: SuccessRequestSpiritualFoodEdit): SpiritualFoodEditActions {
  return {
    type: SUCCESS_SPIRITUAL_FOOD_EDIT,
    data: response,
  }
}

export function errorRequestSpiritualFoodEdit(response: ErrorRequestSpiritualFoodEdit): SpiritualFoodEditActions {
  return {
    type: ERROR_SPIRITUAL_FOOD_EDIT,
    error: response,
  }
}

export function clearSpiritualFoodEdit(): SpiritualFoodEditActions {
  return {
    type: CLEAR_SPIRITUAL_FOOD_EDIT
  }
}

export default {
  requestSpiritualFoodEdit,
  clearSpiritualFoodEdit,
  successRequestSpiritualFoodEdit,
  errorRequestSpiritualFoodEdit,
}
