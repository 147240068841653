import { put, call } from 'redux-saga/effects'
import { booksService } from '../services/BooksService'

import BooksActions from '../stores/Books/Actions'
import { RequestBooksAction } from '../stores/Books/Types'

import BookActions from '../stores/Book/Actions'
import { RequestBookAction } from '../stores/Book/Types'

import BookAddActions from '../stores/BookAdd/Actions'
import { RequestBookAddAction } from '../stores/BookAdd/Types'

import BookEditActions from '../stores/BookEdit/Actions'
import { RequestBookEditAction } from '../stores/BookEdit/Types'

import { ResponseService } from '../services/Service'

export function* getBooks(action: RequestBooksAction) {
  const response: ResponseService = yield call < any > (booksService.fetchBooks, action.payload)
  if (response.type === 'success') {
    let books = response.details.data
    let lastPage = Number(response.details.lastPage) || 0
    let page = Number(response.details.page) || 0
    let total = Number(response.details.total) || 0
    let perPage = Number(response.details.perPage) || 0
    let data = {
      key: action.payload.key,
      books,
      lastPage,
      page,
      total,
      perPage
    }
    yield put(BooksActions.successRequestBooks(data))
  } else {
    let message = response.message || ''
    yield put(
      BooksActions.errorRequestBooks({
        key: action.payload.key,
        message,
      })
    )
  }
}

export function* getBook(action: RequestBookAction) {
  const response: ResponseService = yield call<any>(booksService.fetchBook, action.payload.id)
  if (response.type === 'success') {
    let data = {
      book: response.details
    }
    yield put(BookActions.successRequestBook(data))
  } else {
    let message = response.message || ''
    yield put(
      BookActions.errorRequestBook({
        message
      })
    )
  }
}

export function* bookAdd(action: RequestBookAddAction) {
  const response: ResponseService = yield call<any>(booksService.bookAdd, action.payload)
  if (response.type === 'success') {
    yield put(BookAddActions.successRequestBookAdd({
      book: response.details,
      message: 'Livro adicionado com sucesso'
    }))
  } else {
    let message = response.message || ''
    yield put(
      BookAddActions.errorRequestBookAdd({
        errors: response.details,
        message,
      })
    )
  }
}

export function* bookEdit(action: RequestBookEditAction) {
  const response: ResponseService = yield call<any>(booksService.bookEdit, action.payload)
  if (response.type === 'success') {
    yield put(BookEditActions.successRequestBookEdit({
      book: response.details,
      message: 'Livro editado com sucesso'
    }))
  } else {
    let message = response.message || ''
    yield put(
      BookEditActions.errorRequestBookEdit({
        errors: response.details,
        message,
      })
    )
  }
}
