import { JavAddState, JavAddTypes } from './Types'

const initialState: JavAddState = {
  data: null,
  requesting: false,
  errors: null,
  success: false,
  message: null
}

export function reducer(state = initialState, action: any): JavAddState {
  switch (action.type) {
    case JavAddTypes.REQUEST_JAV_ADD:
      const payloadAdd = action.payload
      return {
        data: payloadAdd,
        requesting: true,
        errors: null,
        success: false,
        message: null
      }
    case JavAddTypes.SUCCESS_JAV_ADD:
      const payloadJav = action.data
      return {
        data: payloadJav.jav,
        requesting: false,
        errors: null,
        success: true,
        message: payloadJav.message
      }
    case JavAddTypes.ERROR_JAV_ADD:
      const payloadError = action.error
      return {
        data: null,
        requesting: false,
        errors: payloadError.errors,
        success: false,
        message: payloadError.message
      }
    case JavAddTypes.CLEAR_JAV_ADD:
      return {
        data: null,
        requesting: false,
        errors: null,
        success: false,
        message: null
      }
  }
  return state
}
