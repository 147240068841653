import React from 'react'
import { Link } from 'react-router-dom'
import AddCircle from '@material-ui/icons/AddCircle'

import ContentProvider from '../../components/ContentProvider'
import ContentList, { Search } from '../../components/ContentList/ContentList'

import Button from '../../components/CustomButtons/Button'

interface IContentView {
  location: any;
  history: any;
  routeAddUrl;
  routeAddLabel;
  storeName: string;
  request: Function;
  clearRequest: Function;
  renderItemActions: (item: object) => React.Component;
  renderRemoveAction?: (item: object) => React.Component;
}

const ContentListPage: React.FC<IContentView> = (props) => {
  const params = new URLSearchParams(props.location.search)
  const defaultText = params.get('text')
  const defaultPage = params.get('page') ? Number(params.get('page')) : 0
  const changeUrl = ({ page, text }) => {
    const newUrl = props.location.pathname 
      + '?'
      + (page ? '&page=' + page : '')
      + (text ? '&text=' + text : '')
    props.history.push(newUrl)
  }
  return (
    <div>
      <Link to={props.routeAddUrl}>
        <Button color="primary">
          <AddCircle />
          <span>{props.routeAddLabel}</span>
        </Button>
      </Link>
      <ContentProvider
        name="paginate"
        storeName={props.storeName}
        defaultTitle={defaultText}
        defaultPage={defaultPage}
        SearchComponent={Search}
        ListComponent={propsList => <ContentList {...propsList} renderItemActions={props.renderItemActions} renderRemoveAction={props.renderRemoveAction} />}
        request={props.request}
        clearRequest={props.clearRequest}
        onFiltersChange={changeUrl}
      />
    </div>
  )
}

export default ContentListPage
