import { request, get, ResponseService, requestForm } from './Service'
import { RequestReleaseAddParams } from '../stores/ReleaseAdd/Types'
import { uploadBookCover } from './UploadService'
import { RequestReleaseEditParams } from '../stores/ReleaseEdit/Types'

async function fetchReleases({ page, search, limit }): Promise<ResponseService> {
  let url = 'api/v1/releases?'
  if (page) {
    url += 'page=' + page + '&'
  }
  if (search) {
    url += 'text=' + encodeURI(search) + '&'
  }
  if (limit) {
    url += 'limit=' + limit
  }
  return await get(url, undefined, {
    default_error_message: 'Ocorreu um erro na busca'
  })
}

async function fetchRelease(id: Number): Promise<ResponseService> {
  let url = 'api/v1/releases/' + id
  return await get(url, undefined, {
    default_error_message: 'Ocorreu um erro na busca'
  })
}

async function releaseAdd(data: RequestReleaseAddParams): Promise<ResponseService> {
  let payload: any = {...data}
  if (data.image instanceof File) {
    const responseUploadImage = await uploadBookCover(data.image)
    if (responseUploadImage.type === 'error') {
      return {
        type: 'error',
        message: 'Ocorreu um erro no upload de imagem',
        details: {
          image_id: responseUploadImage.message
        }
      }
    }
    payload.image_id = responseUploadImage.details.id
    delete payload.image
  } else {
    payload.image_id = data.image
    delete payload.image
  }
  let url = 'api/v1/admin/releases'
  return await requestForm('post', url, payload, {
    default_error_message: 'Ocorreu um erro na busca'
  })
}

async function releaseEdit(data: RequestReleaseEditParams): Promise<ResponseService> {
  let payload: any = {...data}
  if (data.image instanceof File) {
    const responseUploadImage = await uploadBookCover(data.image)
    if (responseUploadImage.type === 'error') {
      return {
        type: 'error',
        message: 'Ocorreu um erro no upload de imagem',
        details: {
          image_id: responseUploadImage.message
        }
      }
    }
    payload.image_id = responseUploadImage.details.id
    delete payload.image
  } else {
    payload.image_id = data.image
    delete payload.image
  }
  let url = 'api/v1/admin/releases/' + payload.id
  return await requestForm('put', url, payload, {
    default_error_message: 'Ocorreu um erro na busca'
  })
}

export async function deleteRelease({ id }): Promise<ResponseService> {
  let url = 'api/v1/admin/releases/' + id
  return await request('delete', url, undefined, {
    default_error_message: 'Ocorreu um erro'
  })
}


export const releasesService = {
  fetchReleases,
  fetchRelease,
  releaseAdd,
  releaseEdit
}
