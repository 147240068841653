import {
  REQUEST_BOOK,
  SUCCESS_BOOK,
  ERROR_BOOK,
  CLEAR_BOOK,
  BookActions,
  RequestBookParams,
  SuccessRequestBook,
  ErrorRequestBook
} from './Types'

export function requestBook(params: RequestBookParams): BookActions {
  return {
    type: REQUEST_BOOK,
    payload: params,
  }
}

export function successRequestBook(response: SuccessRequestBook): BookActions {
  return {
    type: SUCCESS_BOOK,
    data: response,
  }
}

export function errorRequestBook(response: ErrorRequestBook): BookActions {
  return {
    type: ERROR_BOOK,
    error: response,
  }
}

export function clearBook(): BookActions {
  return {
    type: CLEAR_BOOK
  }
}

export default {
  requestBook,
  clearBook,
  successRequestBook,
  errorRequestBook,
}
