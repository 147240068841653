import React, { useState, useEffect, useMemo } from 'react'

import { generateAccessCode } from '../../services/AccessCodeService'

import Card from '../../components/Card/Card'
import CardBody from '../../components/Card/CardBody'
import CardHeader from '../../components/Card/CardHeader'
import GridContainer from '../../components/Grid/GridContainer'
import GridItem from '../../components/Grid/GridItem'
import Button from '../../components/CustomButtons/Button'
import Success from '../../components/Success/Success'
import Error from '../../components/Error/Error'
import Loading from '../../components/Loading/Loading'
import CustomInput from '../../components/CustomInput/CustomInput'


const AccessCodeAdd = ({ params, onSuccess }) => {
  const [key, setKey] = useState<any>('key')
  const [error, setError] = useState<any>(null)
  const [success, setSuccess] = useState<any>(null)
  const [loading, setLoading] = useState(false)
  const [amount, setAmount] = useState<any>(null)

  const disabled = useMemo(() => {
    return loading
  }, [loading])
  
  useEffect(() => {
    if (success) {
      setAmount(0)
      setKey('key' + Date.now())
    }
  }, [success])

  const handleSubmit = () => {
    setLoading(true)
    setSuccess(false)
    setError(null)
    generateAccessCode({ ...params, amount })
      .then(response => {
        if (response.type === 'error') {
          setLoading(false)
          setSuccess(false)
          setError(response.message)
          return
        }
        setSuccess(response.message || 'Códigos de acesso gerado com sucesso')
        setError(null)
        setLoading(false)
        onSuccess()
      })
      .catch(err => {
        setSuccess(false)
        setError(err.message)
        setLoading(false)
      })
  }

  return (
    <Card key={key}>
      <CardHeader>
        <h4 style={{ margin: 0 }}>Gerar códigos de acesso</h4>
      </CardHeader>
      <CardBody>
        <Success message={success} hide={!success} onClick={() => setSuccess(null)} />
        <Error message={error} hide={!error} onClick={() => setError(null)} />
        <Loading message={"Aguarde..."} hide={!loading} />
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <CustomInput
              labelText="Digite a quantidade de códigos de acesso"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                type: 'number',
                defaultValue: amount,
                onChange: (e) => setAmount(+e.target.value)
              }}
             />
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <div style={{ marginTop: '15px', textAlign: 'right' }}>
              <Button
                color="primary"
                disabled={disabled}
                onClick={handleSubmit} 
              >Gerar códigos de acesso</Button>
            </div>
          </GridItem>
        </GridContainer>
      </CardBody>
    </Card>
  )
}

export default AccessCodeAdd
