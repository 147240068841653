import {
  REQUEST_JAV,
  SUCCESS_JAV,
  ERROR_JAV,
  CLEAR_JAV,
  JavActions,
  RequestJavParams,
  SuccessRequestJav,
  ErrorRequestJav
} from './Types'

export function requestJav(params: RequestJavParams): JavActions {
  return {
    type: REQUEST_JAV,
    payload: params,
  }
}

export function successRequestJav(response: SuccessRequestJav): JavActions {
  return {
    type: SUCCESS_JAV,
    data: response,
  }
}

export function errorRequestJav(response: ErrorRequestJav): JavActions {
  return {
    type: ERROR_JAV,
    error: response,
  }
}

export function clearJav(): JavActions {
  return {
    type: CLEAR_JAV
  }
}

export default {
  requestJav,
  clearJav,
  successRequestJav,
  errorRequestJav,
}
