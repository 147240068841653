import React, { useState, useCallback, useMemo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ReleaseEditState } from '../../stores/ReleaseEdit/Types'
import { requestReleaseEdit, clearReleaseEdit } from '../../stores/ReleaseEdit/Actions'

import { ReleaseState } from '../../stores/Release/Types'
import { requestRelease, clearRelease } from '../../stores/Release/Actions'

import GridItem from '../../components/Grid/GridItem'
import GridContainer from '../../components/Grid/GridContainer'
import CustomInput from '../../components/CustomInput/CustomInput'
import Button from '../../components/CustomButtons/Button'
import Card from '../../components/Card/Card'
import CardBody from '../../components/Card/CardBody'
import CardFooter from '../../components/Card/CardFooter'
import CustomTagsInput from '../../components/CustomInput/CustomTagsInput'
import CustomFileUpload from '../../components/CustomFileUpload/CustomFileUpload'
import Loading from '../../components/Loading/Loading'
import SnackbarContent from '../../components/Snackbar/SnackbarContent'

function ReleaseForm() {
  const dispatch = useDispatch()
  const releaseState = useSelector<any>(state => state.release) as ReleaseState
  const releaseEditState = useSelector<any>(state => state.releaseEdit) as ReleaseEditState
  
  const release: any = releaseState.release  
  const [title, setTitle] = useState(release.title)
  const [link, setLink] = useState(release.link)
  const [tags, setTags] = useState(release.tags.map(t => t.title))
  const [image, setImage] = useState(release.image.id)

  useEffect(() => {
    return () => {
      dispatch(clearReleaseEdit())
    }
  }, [dispatch])

  useEffect(() => {
    if (releaseEditState.success) {
      setTimeout(() => {
        try {
          dispatch(requestRelease({ id: release.id }))
        } catch(err) {}
      }, 1000)
    }
  }, [releaseEditState.success])

  const handleSubmit = useCallback(() => {
    const data = {
      id: release.id,
      title,
      link,
      tags,
      image
    }
    dispatch(requestReleaseEdit(data))
  }, [title, link, tags, image])

  const disabled = useMemo(() => releaseEditState.requesting, [releaseEditState.requesting])
  const errors: any = useMemo(() => releaseEditState.errors, [releaseEditState.errors])
  
  const Message = useMemo(() => {
    if (!releaseEditState.message) {
      return null
    }
    return (
      <SnackbarContent 
        color={releaseEditState.success ? 'success': 'danger'} 
        message={releaseEditState.message} 
      />
    )
  }, [releaseEditState.message, releaseEditState.success])

  function ErrorItem({ message }) {
    if (!message) return null
    return <span style={{color: 'red'}}>{message}</span>
  }

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              <h4><b>#{release.id} - {release.title}</b></h4>
              <Loading
                message="Aguarde um momento ..."
                hide={!disabled}
              />
              {Message}
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Título"
                    id="title"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      defaultValue: title,
                      onChange: (e) => setTitle(e.target.value),
                      disabled
                    }}
                    error={errors && errors.title}
                  />
                  <ErrorItem message={errors ? errors.title : null} />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Link"
                    id="link"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      defaultValue: link,
                      onChange: (e) => setLink(e.target.value),
                      disabled
                    }}
                    error={errors && errors.link}
                  />
                  <ErrorItem message={errors ? errors.link : null} />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomTagsInput
                    defaultTags={tags}
                    onChange={setTags}
                    disabled={disabled}
                  />
                  <ErrorItem message={errors ? errors.tags : null} />
                </GridItem>
              </GridContainer>
              <div style={{marginTop: '30px'}} />
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomFileUpload
                    title={<a href={release.image.url} target="blank">{`Trocar Imagem ${release.image.name}`}</a>}
                    acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
                    onChange={files => {
                      const file = files && files.length > 0 ? files[0] : null
                      setImage(file)
                    }}
                    disabled={disabled}
                  />
                  <ErrorItem message={errors ? errors.image_id : null} />
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter>
              <Button 
                onClick={handleSubmit} 
                color="primary"
              >Editar Livro</Button>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  )
}

const ReleaseEdit = (props) => {
  const id = props.match.params.id
  const dispatch = useDispatch()
  const releaseState = useSelector<any>(state => state.release) as ReleaseState
  
  useEffect(() => {
    dispatch(requestRelease({ id }))
    return () => {
      dispatch(clearRelease())
    }
  }, [])

  return useMemo(() => {
    if (releaseState.success) {
      return <ReleaseForm />
    }
    if (releaseState.error) {
      return (
        <SnackbarContent 
          color="danger" 
          message={releaseState.message}
        />
      )
    }
    return <Loading hide={false}  message="Aguarde ..." />
  }, [releaseState])
}

export default ReleaseEdit
