import React, { useState, useCallback, useEffect } from 'react'

import GridContainer from '../Grid/GridContainer'
import GridItem from '../Grid/GridItem'
import CustomChipList from '../CustomChipList/CustomChipList'
import CustomButton from '../CustomButtons/Button'
import CustomInput from './CustomInput'

const CustomTagsInput = ({ defaultTags, onChange, disabled }) => {
  const [tags, setTags] = useState(defaultTags || [])
  const [tag, setTag] = useState('')
  
  useEffect(() => {
    if (defaultTags === tags)  {
      return
    }
    onChange(tags)
  }, [tags, onChange])

  const handleSubmit = useCallback(() => {
    if (!tag) {
      return
    }
    const newTags = tags.filter(t => t !== tag)
    newTags.push(tag)
    setTags(newTags)
    setTag('')
  }, [tag, tags])

  const handleDelete = useCallback(text => {
    if (!text) {
      return
    }
    const newTags = tags.filter(t => t !== text)
    setTags(newTags)
  }, [tags])
  
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={6}>
        <CustomInput
          labelText="Tags para busca"
          id={tag + '--'}
          formControlProps={{ fullWidth: true }}
          inputProps={{
            disabled,
            value: tag,
            autoComplete: 'none',
            onChange: e => setTag(e.target.value),
          }}
        />
      </GridItem>
      <GridItem xs={12} sm={12} md={6}>
        <div style={{marginTop: '30px'}} />
        <CustomButton 
          onClick={handleSubmit} 
          color="primary"
          disabled={disabled}
        >ADD</CustomButton>
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        <CustomChipList 
          disabled={disabled} 
          chips={tags} 
          onDelete={handleDelete} 
        />
      </GridItem>
    </GridContainer>
  )
}

export default CustomTagsInput
