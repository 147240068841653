import { put, call } from 'redux-saga/effects'
import { registerService } from '../services/RegisterService'

import RegisterActions from '../stores/Register/Actions'
import { RequestRegisterAction } from '../stores/Register/Types'

import { ResponseService } from '../services/Service'

export function* registerUser(action: RequestRegisterAction) {
  const response: ResponseService = yield call<any>(registerService.registerUser, action.payload)
  let message = response.message || '';
  if (response.type === 'success') {
    yield put(RegisterActions.successRegister({
      message
    }))
  } else {    
    yield put(RegisterActions.errorRegister({
      message
    }))
  }
}
