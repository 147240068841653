import React from 'react'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { requestLogout } from '../../stores/Auth/Actions'

const LogoutPage = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(requestLogout())
  }, [dispatch])
  return null
}

export default LogoutPage
