import { put, call } from 'redux-saga/effects'
import { authService } from '../services/AuthService'

import RequestRecoveryPasswordActions from '../stores/RequestRecoveryPassword/Actions'
import { RequestRequestRecoveryPasswordAction } from '../stores/RequestRecoveryPassword/Types'

import { ResponseService } from '../services/Service'

export function* requestRecoveryPassword(action: RequestRequestRecoveryPasswordAction) {
  const response: ResponseService = yield call<any>(authService.requestRecoveryPassword, action.payload)
  let message = response.message || '';
  if (response.type === 'success') {
    yield put(RequestRecoveryPasswordActions.successRequestRecoveryPassword({
      message
    }))
  } else {    
    yield put(RequestRecoveryPasswordActions.errorRequestRecoveryPassword({
      message
    }))
  }
}
