import { BookState, BookTypes } from './Types'

const initialState: BookState = {
  id: null,
  book: null,
  requesting: false,
  error: false,
  success: false,
  message: null,
}

export function reducer(state = initialState, action: any): BookState {
  switch (action.type) {
    case BookTypes.REQUEST_BOOK:
      return {
        id: action.payload.id,
        book: null,
        requesting: true,
        error: false,
        success: false,
        message: null
      }
    case BookTypes.SUCCESS_BOOK:
      return {
        ...state,
        book: action.data.book,
        requesting: false,
        error: false,
        success: true,
        message: null,
      }
    case BookTypes.ERROR_BOOK:
      return {
        ...state,
        book: null,
        requesting: false,
        error: true,
        success: false,
        message: action.error.message,
      }
    case BookTypes.CLEAR_BOOK:
      return {
        id: null,
        book: null,
        requesting: false,
        error: false,
        success: false,
        message: null,
      }
  }
  return state
}
