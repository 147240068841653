import { BookAddState, BookAddTypes } from './Types'

const initialState: BookAddState = {
  data: null,
  requesting: false,
  errors: null,
  success: false,
  message: null
}

export function reducer(state = initialState, action: any): BookAddState {
  switch (action.type) {
    case BookAddTypes.REQUEST_BOOK_ADD:
      const payloadAdd = action.payload
      return {
        data: payloadAdd,
        requesting: true,
        errors: null,
        success: false,
        message: null
      }
    case BookAddTypes.SUCCESS_BOOK_ADD:
      const payloadBook = action.data
      return {
        data: payloadBook.book,
        requesting: false,
        errors: null,
        success: true,
        message: payloadBook.message
      }
    case BookAddTypes.ERROR_BOOK_ADD:
      const payloadError = action.error
      return {
        data: null,
        requesting: false,
        errors: payloadError.errors,
        success: false,
        message: payloadError.message
      }
    case BookAddTypes.CLEAR_BOOK_ADD:
      return {
        data: null,
        requesting: false,
        errors: null,
        success: false,
        message: null
      }
  }
  return state
}
