export const REQUEST_AUTH: any = 'REQUEST_AUTH'
export const REQUEST_LOGOUT: any = 'REQUEST_LOGOUT'
export const SUCCESS_AUTH = 'SUCCESS_AUTH'
export const ERROR_AUTH = 'ERROR_AUTH'
export const CLEAR_AUTH = 'CLEAR_AUTH'
export const CLEAR_ERROR = 'CLEAR_ERROR'

export interface AuthState {
  requesting: boolean
  error: boolean
  logged: boolean
  message?: string | null
  username?: string | null
  token?: string | null
  refreshToken?: string | null
}

export interface Credentials {
  username: string
  password: string
}

export interface ErrorAuthorization {
  message: string | undefined
}

export interface SuccessAuthorization {
  username: string
  token: string
  refreshToken: string
  message: string
}

export interface RequestAuthorizationAction {
  type: typeof REQUEST_AUTH
  payload: Credentials
}

export interface RequestLogoutAction {
  type: typeof REQUEST_LOGOUT
}

export interface SuccessAuthorizationAction {
  type: typeof SUCCESS_AUTH
  data: SuccessAuthorization
}

export interface ErrorAuthorizationAction {
  type: typeof ERROR_AUTH
  error: ErrorAuthorization
}

export interface ClearAuthorizationAction {
  type: typeof CLEAR_AUTH
}

export interface ClearErrorAction {
  type: typeof CLEAR_ERROR
}

export type AuthActions =
  | RequestAuthorizationAction
  | SuccessAuthorizationAction
  | ErrorAuthorizationAction
  | ClearAuthorizationAction
  | ClearErrorAction

export const AuthTypes = {
  REQUEST_AUTH,
  REQUEST_LOGOUT,
  SUCCESS_AUTH,
  ERROR_AUTH,
  CLEAR_AUTH,
  CLEAR_ERROR
}
