import {
  REQUEST_RELEASES,
  SUCCESS_RELEASES,
  ERROR_RELEASES,
  CLEAR_RELEASES,
  ReleasesActions,
  RequestReleasesParams,
  SuccessRequestReleases,
  ErrorRequestReleases,
  ClearReleases,
} from './Types'

export function requestReleases(params: RequestReleasesParams): ReleasesActions {
  return {
    type: REQUEST_RELEASES,
    payload: params,
  }
}

export function successRequestReleases(response: SuccessRequestReleases): ReleasesActions {
  return {
    type: SUCCESS_RELEASES,
    data: response,
  }
}

export function errorRequestReleases(response: ErrorRequestReleases): ReleasesActions {
  return {
    type: ERROR_RELEASES,
    error: response,
  }
}

export function clearReleases(clear: ClearReleases): ReleasesActions {
  return {
    type: CLEAR_RELEASES,
    clear,
  }
}

export default {
  requestReleases,
  clearReleases,
  successRequestReleases,
  errorRequestReleases,
}
