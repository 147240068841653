import { request, get, post, requestForm, ResponseService } from './Service'

export async function fetchSubscriptions({ page, text }): Promise<ResponseService> {
  let url = 'api/v1/admin/subscriptions?'
  if (page) {
    url += 'page=' + page + '&'
  }
  if (text) {
    url += 'text=' + encodeURI(text) + '&'
  }
  return await get(url, undefined, {
    default_error_message: 'Ocorreu um erro na busca'
  })
}

export async function subscriptionAdd({ title, active }): Promise<ResponseService> {
  let payload: any = { title, active }
  let url = 'api/v1/admin/subscriptions'
  return await post(url, payload, {
    default_error_message: 'Ocorreu um erro na busca'
  })
}

export async function subscriptionEdit({ id, title, active }): Promise<ResponseService> {
  let payload: any = { title, active }
  let url = 'api/v1/admin/subscriptions/' + id
  return await requestForm('put', url, payload, {
    default_error_message: 'Ocorreu um erro na busca'
  })
}

export async function fetchSubscription(id: Number): Promise<ResponseService> {
  let url = 'api/v1/admin/subscriptions/' + id
  return await get(url, undefined, {
    default_error_message: 'Ocorreu um erro na busca'
  })
}

export async function subscriptionContentAdd({ subscriptionId, typeContent, contentId }): Promise<ResponseService> {
  let columnName
  if (typeContent === 'javs') {
    columnName = 'jav_id'
  } else if (typeContent === 'books') {
    columnName = 'book_id'
  } else {
    columnName = 'spiritual_food_id'
  }
  let payload: any = { 
    subscription_id: subscriptionId, 
    [columnName]: contentId
  }
  let url = 'api/v1/admin/subscription-contents'
  return await requestForm('post', url, payload, {
    default_error_message: 'Ocorreu um erro na busca'
  })
}

export async function fetchSubscriptionContents({ subscriptionId, page, text }): Promise<ResponseService> {
  let url = 'api/v1/admin/subscription-contents?'
  if (page) {
    url += 'page=' + page + '&'
  }
  if (subscriptionId) {
    url += 'subscription_id=' + subscriptionId + '&'
  }
  if (text) {
    url += 'text=' + encodeURI(text) + '&'
  }
  return await get(url, undefined, {
    default_error_message: 'Ocorreu um erro na busca'
  })
}

export async function deleteSubscriptionContent({ id }): Promise<ResponseService> {
  let url = 'api/v1/admin/subscription-contents/' + id
  return await request('delete', url, undefined, {
    default_error_message: 'Ocorreu um erro na busca'
  })
}

export const suvbscriptionService = {
  fetchSubscriptions,
  subscriptionAdd,
  subscriptionEdit,
  fetchSubscription
}
