import { put, call, select } from 'redux-saga/effects'

import { authService } from '../services/AuthService'
import { ResponseService, setToken } from '../services/Service'

import AuthActions from '../stores/Auth/Actions'
import { RequestAuthorizationAction, RequestLogoutAction } from '../stores/Auth/Types'

export function* login(action: RequestAuthorizationAction) {
  const response: ResponseService = yield call<any>(authService.fetchAuth, action.payload)
  if (response.type === 'success') {
    let data = {
      username: action.payload.username,
      token: response.details.token,
      refreshToken: response.details.refreshToken,
      message: 'Login realizado com sucesso',
    }
    setToken(response.details.token)
    yield put(AuthActions.successAuthorization(data))
  } else {
    let message = response.message
    yield put(
      AuthActions.errorAuthorization({
        message,
      })
    )
  }
}

export function* logout(action: RequestLogoutAction) {
  const refreshToken = yield select(state => state.auth.refreshToken)
  setToken(null)
  if (refreshToken)
    yield call<any>(authService.logout, { refreshToken })
  yield put(AuthActions.logout())
}