import React, { useMemo, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createStyles, withStyles } from '@material-ui/core'

import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

import { Actions } from '../../stores/SpiritualFoodForm'
import { requestSpiritualFoodAdd, clearSpiritualFoodAdd } from '../../stores/SpiritualFoodAdd/Actions'

import SpiritualFoodForm from './SpiritualFoodForm'
import WeekForm from './WeekForm'
import Button from '../../components/CustomButtons/Button'

const SpiritualFoodAdd = ({ classes }) => {
  const dispatch = useDispatch()
  const state: any = useSelector<any>(state => state.spiritualFoodForm)
  const stateAdd: any = useSelector<any>(state => state.spiritualFoodAdd)
  const [value, setValue] = React.useState(0)

  useEffect(() => {
    return () => {
      dispatch(Actions.clear())
      dispatch(clearSpiritualFoodAdd())
    }
  }, [dispatch])

  useEffect(() => {
    if (stateAdd.success) {
      dispatch(Actions.clear())
    }
  }, [dispatch, stateAdd.success])

  useEffect(() => {
    dispatch(Actions.setColumn('disabled', stateAdd.requesting))
  }, [stateAdd.requesting])

  useEffect(() => {
    dispatch(Actions.setColumn('errors', stateAdd.errors))
  }, [stateAdd.errors])

  const handleClick = useCallback(() => {
    let data = { ...state }
    delete data.disabled
    delete data.currentWeekIndex
    delete data.currentDayIndex
    delete data.errors
    dispatch(requestSpiritualFoodAdd(data))
  }, [state, dispatch])

  const handleChange = (e, newValue) => {
    setValue(newValue)
    if (newValue > 0) {
      dispatch(Actions.setCurrentWeek(newValue - 1))
      dispatch(Actions.setCurrentDay(0))
    }
  }

  const content = useMemo(() => {
    if (value === 0) {
      return <SpiritualFoodForm />
    }
    return <WeekForm key={value} />
  }, [value])

  const errorMessage = useMemo(() => {
    if (!state.errors) {
      return null
    }
    return <span style={{color: 'red'}}>Formulário inválido</span>
  }, [state.errors])

  const successMessage = useMemo(() => {
    if (!state.success) {
      return null
    }
    return <span style={{color: 'green'}}>Alimento diário criado com sucesso</span>
  }, [state.success])

  return (
    <div>
      {successMessage}
      {errorMessage}
      <Tabs
        classes={classes}
        value={value}
        onChange={handleChange}
        variant="fullWidth"
        scrollButtons="auto"
      >
        <TabStyled label="Dados" />
        <TabStyled label="Semana 1" />
        <TabStyled label="Semana 2" />
        <TabStyled label="Semana 3" />
        <TabStyled label="Semana 4" />
      </Tabs>
      {content}
      <Button 
        disabled={state.disabled} 
        onClick={handleClick} 
        color="success"
      >Criar</Button>
    </div>
  );
}

const styles = createStyles({
  root: {
    marginTop: '20px',
    marginBottom: '20px',
    borderRadius: '20px',
    background: '#fff',
    border: '1px solid #007332',
    color: 'red'
  },
  'indicator': {
    backgroundColor: '#007332',
  }
})

const stylesTab = createStyles({
  root: {
    color: '#333'
  }
})

const TabStyled = withStyles(stylesTab)(Tab)

export default withStyles(styles)(SpiritualFoodAdd)
