import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { createStyles, withStyles } from '@material-ui/core'

import Card from '../../components/Card/Card'
import CardBody from '../../components/Card/CardBody'
import GridItem from '../../components/Grid/GridItem'
import GridContainer from '../../components/Grid/GridContainer'
import CustomInput from '../../components/CustomInput/CustomInput'

import ErrorItem, { useSpiritualFoodFormVerseError } from './ErrorItem'

const VerseForm = ({ verse, index, classes, onChangeVerse }) => {
  const errors: any = {}
  const state: any = useSelector<any>(state => state.spiritualFoodForm)
  const disabled = state.disabled
  const [title, setTitle] = useState(verse.title || '')
  const [text, setText] = useState(verse.text || '')

  useEffect(() => {
    onChangeVerse({
      ...verse,
      title, 
      text
    })
  }, [title, text])

  return (
    <div className={classes.formContainer}>
      <Card className={classes.card}>
        <CardBody>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <CustomInput
                labelText="Versículo"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  defaultValue: title,
                  onChange: (e) => setTitle(e.target.value),
                  disabled
                }}
                error={useSpiritualFoodFormVerseError('title', index)}
              />
              <ErrorItem message={useSpiritualFoodFormVerseError('title', index)} />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <CustomInput
                labelText="Texto do Versículo"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  defaultValue: text,
                  onChange: (e) => setText(e.target.value),
                  disabled,
                  multiline: true,
                  rows: 10
                }}
                error={useSpiritualFoodFormVerseError('text', index)}
              />
              <ErrorItem message={useSpiritualFoodFormVerseError('text', index)} />
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    </div>
  )
}

const verseStyle = createStyles({
  button: {
    background: '#007332',
    marginTop: '20px',
    borderRadius: '9px 9px 0 0',
    color: '#fff',
    '&:hover &:active': {
      background: '#007332',
      color: '#333'
    }
  },
  item: {
    border: '1px solid #f0f0f0',
    padding: 0
  },
  formContainer: {
    flex: 1,
    margin: '10px'
  },
  card: {
    marginBottom: 0
  }
})

export default withStyles(verseStyle)(VerseForm)