import { get, ResponseService } from "./Service";

export async function fetchContent({
  typeContent,
  text,
  limit,
}): Promise<Array<any>> {
  let url = `api/v1/${typeContent}?page=1&limit=${limit}&`;
  if (text) {
    url += "title=" + encodeURI(text);
  }
  const response: ResponseService = await get(url, undefined, {
    default_error_message: "Ocorreu um erro na busca",
  });
  if (response.type === "error") {
    return [];
  }
  return response.details.data;
}
