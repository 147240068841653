import {
  REQUEST_CONTENTS,
  SUCCESS_CONTENTS,
  ERROR_CONTENTS,
  CLEAR_CONTENTS,
  ContentsActions,
  RequestContentsParams,
  SuccessRequestContents,
  ErrorRequestContents,
  ClearContents,
} from './Types'

export function requestContents(params: RequestContentsParams): ContentsActions {
  return {
    type: REQUEST_CONTENTS,
    payload: params,
  }
}

export function successRequestContents(response: SuccessRequestContents): ContentsActions {
  return {
    type: SUCCESS_CONTENTS,
    data: response,
  }
}

export function errorRequestContents(response: ErrorRequestContents): ContentsActions {
  return {
    type: ERROR_CONTENTS,
    error: response,
  }
}

export function clearContents(clear: ClearContents): ContentsActions {
  return {
    type: CLEAR_CONTENTS,
    clear,
  }
}

export default {
  requestContents,
  clearContents,
  successRequestContents,
  errorRequestContents,
}
