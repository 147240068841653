import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

const ErrorItem = ({ message }) => {
  return useMemo(() => {
    if (message) {
      return <span style={{color: 'red'}}>{message}</span>
    }
    return null
  }, [message])
}

export function useSpiritualFoodFormError(name) {
  const errors: any = useSelector<any>(state => state.spiritualFoodForm.errors ? state.spiritualFoodForm.errors : {})
  return useMemo(() => {
    if (errors[name]) {
      return errors[name]
    }
    return null
  }, [name, errors[name]])
}

export function useSpiritualFoodFormWeekError(name) {
  const state: any = useSelector<any>(state => state.spiritualFoodForm)
  const baseErrorName = `weeks.${state.currentWeekIndex}`
  return useSpiritualFoodFormError(`${baseErrorName}.${name}`)
}

export function useSpiritualFoodFormDayError(name) {
  const state: any = useSelector<any>(state => state.spiritualFoodForm)
  const baseErrorName = `weeks.${state.currentWeekIndex}.days.${state.currentDayIndex}`
  return useSpiritualFoodFormError(`${baseErrorName}.${name}`)
}

export function useSpiritualFoodFormVerseError(name, index) {
  const state: any = useSelector<any>(state => state.spiritualFoodForm)
  const baseErrorName = `weeks.${state.currentWeekIndex}.days.${state.currentDayIndex}.verses.${index}`
  return useSpiritualFoodFormError(`${baseErrorName}.${name}`)
}

export default ErrorItem