export const REQUEST_SPIRITUAL_FOOD_ADD: any = 'REQUEST_SPIRITUAL_FOOD_ADD'
export const SUCCESS_SPIRITUAL_FOOD_ADD = 'SUCCESS_SPIRITUAL_FOOD_ADD'
export const ERROR_SPIRITUAL_FOOD_ADD = 'ERROR_SPIRITUAL_FOOD_ADD'
export const CLEAR_SPIRITUAL_FOOD_ADD = 'CLEAR_SPIRITUAL_FOOD_ADD'

export interface SpiritualFood {
  id: string;
  title: string;
}

export interface SpiritualFoodAddState {
  data: RequestSpiritualFoodAddParams | SpiritualFood | null;
  requesting: boolean;
  errors: Object | null;
  success: boolean;
  message: string | null;
}

export interface RequestSpiritualFoodVerseAddParams {
  title: string;
  text: string;
}

export interface RequestSpiritualFoodDayAddParams {
  title: string;
  text: string;
  audio: File | number;
  verses: Array<RequestSpiritualFoodVerseAddParams>; 
}

export interface RequestSpiritualFoodWeekAddParams {
  title: string;
  link_youtube: string;
  days: Array<RequestSpiritualFoodDayAddParams>; 
}

export interface RequestSpiritualFoodAddParams {
  title: string;
  active: boolean;
  is_public: boolean;
  tags: Array<string>;
  image: File | number;
  pdf: File | number;
  weeks: Array<RequestSpiritualFoodWeekAddParams>;
}

export interface ErrorRequestSpiritualFoodAdd {
  errors: Object;
  message: string;
}

export interface SuccessRequestSpiritualFoodAdd {
  message: string;
  spiritualFood: SpiritualFood;
}

export interface RequestSpiritualFoodAddAction {
  type: typeof REQUEST_SPIRITUAL_FOOD_ADD
  payload: RequestSpiritualFoodAddParams
}

export interface SuccessRequestSpiritualFoodAddAction {
  type: typeof SUCCESS_SPIRITUAL_FOOD_ADD
  data: SuccessRequestSpiritualFoodAdd
}

export interface ErrorRequestSpiritualFoodAddAction {
  type: typeof ERROR_SPIRITUAL_FOOD_ADD
  error: ErrorRequestSpiritualFoodAdd
}

export interface ClearSpiritualFoodAddAction {
  type: typeof CLEAR_SPIRITUAL_FOOD_ADD
}

export type SpiritualFoodAddActions = RequestSpiritualFoodAddAction | SuccessRequestSpiritualFoodAddAction | ErrorRequestSpiritualFoodAddAction | ClearSpiritualFoodAddAction

export const SpiritualFoodAddTypes = {
  REQUEST_SPIRITUAL_FOOD_ADD,
  SUCCESS_SPIRITUAL_FOOD_ADD,
  ERROR_SPIRITUAL_FOOD_ADD,
  CLEAR_SPIRITUAL_FOOD_ADD,
}