import { get, ResponseService, requestForm } from './Service'
import { uploadBookCover, uploadPdf, uploadAudio } from './UploadService'

async function fetchSpiritualFoods({ page, search, limit }): Promise<ResponseService> {
  let url = 'api/v1/spiritual-foods?'
  if (page) {
    url += 'page=' + page + '&'
  }
  if (search) {
    url += 'text=' + encodeURI(search) + '&'
  }
  if (limit) {
    url += 'limit=' + limit
  }
  return await get(url, undefined, {
    default_error_message: 'Ocorreu um erro na busca'
  })
}

async function fetchSpiritualFood(id: Number): Promise<ResponseService> {
  let url = 'api/v1/spiritual-foods/' + id
  return await get(url, undefined, {
    default_error_message: 'Ocorreu um erro na busca'
  })
}

async function spiritualFoodAdd(data) {
  let payload: any = {
    ...data,
    weeks: data.weeks.map(week => ({
      ...week,
      days: week.days.map(day => ({
        ...day,
        verses: day.verses.map(verse => ({
          ...verse
        })) 
      }))
    }))
  }
  if (data.image instanceof File) {
    const responseUploadImage = await uploadBookCover(data.image)
    if (responseUploadImage.type === 'error') {
      return {
        type: 'error',
        message: 'Ocorreu um erro no upload de imagem',
        details: {
          image_id: responseUploadImage.message
        }
      }
    }
    payload.image_id = responseUploadImage.details.id
    delete payload.image
  } else {
    payload.image_id = data.image
    delete payload.image
  }
  if (data.pdf instanceof File) {
    const responseUploadPdf = await uploadPdf(data.pdf)
    if (responseUploadPdf.type === 'error') {
      return {
        type: 'error',
        message: 'Ocorreu um erro no upload de pdf',
        details: {
          pdf_id: responseUploadPdf.message
        }
      }
    }
    payload.pdf_id = responseUploadPdf.details.id
    delete payload.pdf
  } else {
    payload.pdf_id = data.pdf
    delete payload.pdf
  }

  for (let i = 0; i < payload.weeks.length; i++) {
    const week = payload.weeks[i];
    for (let j = 0; j < week.days.length; j++) {
      const day = week.days[j];
      if (day.audio instanceof File) {
        const responseUploadAudio = await uploadAudio(day.audio)
        if (responseUploadAudio.type === 'error') {
          return {
            type: 'error',
            message: 'Ocorreu um erro no upload de áudio',
            details: {
              [`weeks.${i}.days.${j}.audio_id`]: responseUploadAudio.message
            }
          }
        }
        day.audio_id = responseUploadAudio.details.id
        delete day.audio
      } else {
        day.audio_id = day.audio
        delete payload.audio
      }
    }
  }

  let url = 'api/v1/admin/spiritual-foods'
  return await requestForm('post', url, payload, {
    default_error_message: 'Ocorreu um erro'
  })
}

async function spiritualFoodEdit(data) {
  let payload: any = {
    ...data,
    weeks: data.weeks.map(week => ({
      ...week,
      days: week.days.map((day, i) => ({
        ...day,
        order: i + 1,
        verses: day.verses.map(verse => ({
          ...verse
        })) 
      }))
    }))
  }
  if (data.image instanceof File) {
    const responseUploadImage = await uploadBookCover(data.image)
    if (responseUploadImage.type === 'error') {
      return {
        type: 'error',
        message: 'Ocorreu um erro no upload de imagem',
        details: {
          image_id: responseUploadImage.message
        }
      }
    }
    payload.image_id = responseUploadImage.details.id
    delete payload.image
  } else if (data.image instanceof Object) {
    payload.image_id = data.image.id
    delete payload.image
  } else {
    payload.image_id = data.image
    delete payload.image
  }
  if (data.pdf instanceof File) {
    const responseUploadPdf = await uploadPdf(data.pdf)
    if (responseUploadPdf.type === 'error') {
      return {
        type: 'error',
        message: 'Ocorreu um erro no upload de pdf',
        details: {
          pdf_id: responseUploadPdf.message
        }
      }
    }
    payload.pdf_id = responseUploadPdf.details.id
    delete payload.pdf
  } else if (data.pdf instanceof Object) {
    payload.pdf_id = data.pdf.id
    delete payload.pdf
  } else {
    payload.pdf_id = data.pdf
    delete payload.pdf
  }

  for (let i = 0; i < payload.weeks.length; i++) {
    const week = payload.weeks[i];
    for (let j = 0; j < week.days.length; j++) {
      const day = week.days[j];
      if (day.audio instanceof File) {
        const responseUploadAudio = await uploadAudio(day.audio)
        if (responseUploadAudio.type === 'error') {
          return {
            type: 'error',
            message: 'Ocorreu um erro no upload de áudio',
            details: {
              [`weeks.${i}.days.${j}.audio_id`]: responseUploadAudio.message
            }
          }
        }
        day.audio_id = responseUploadAudio.details.id
        delete day.audio
      } else if (day.audio instanceof Object) {
        day.audio_id = day.audio.id
        delete day.audio
      } else {
        day.audio_id = day.audio
        delete day.audio
      }
    }
  }

  let url = 'api/v1/admin/spiritual-foods/' + data.id
  return await requestForm('put', url, payload, {
    default_error_message: 'Ocorreu um erro'
  })
}


export const spiritualFoodsService = {
  spiritualFoodAdd,
  spiritualFoodEdit,
  fetchSpiritualFoods,
  fetchSpiritualFood
}
