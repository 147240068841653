export const REQUEST_RELEASES: any = 'REQUEST_RELEASES'
export const SUCCESS_RELEASES = 'SUCCESS_RELEASES'
export const ERROR_RELEASES = 'ERROR_RELEASES'
export const CLEAR_RELEASES = 'CLEAR_RELEASES'


export interface ReleasesStateItem {
  key: string;
  books: Array<Object>;
  search: string;
  page: number;
  lastPage: number;
  limit: number;
  requesting: boolean;
  error: boolean;
  success: boolean;
  message: string;
}

export interface ReleasesState {
  items: Array<ReleasesStateItem> 
}

export interface RequestReleasesParams {
  key: string;
  page: number;
  search?: string;
  limit?: number;
  clear?: boolean;
}

export interface ErrorRequestReleases {
  key: string;
  message: string;
}

export interface ClearReleases {
  key: string;
}

export interface SuccessRequestReleases {
  key: string;
  releases: Array<Object>;
  lastPage: number;
  page: number;
  total: number;
  perPage: number;
}

export interface RequestReleasesAction {
  type: typeof REQUEST_RELEASES
  payload: RequestReleasesParams
}

export interface SuccessRequestReleasesAction {
  type: typeof SUCCESS_RELEASES
  data: SuccessRequestReleases
}

export interface ErrorRequestReleasesAction {
  type: typeof ERROR_RELEASES
  error: ErrorRequestReleases
}

export interface ClearReleasesAction {
  type: typeof CLEAR_RELEASES,
  clear: ClearReleases
}

export type ReleasesActions = RequestReleasesAction | SuccessRequestReleasesAction | ErrorRequestReleasesAction | ClearReleasesAction

export const ReleasesTypes = {
  REQUEST_RELEASES,
  SUCCESS_RELEASES,
  ERROR_RELEASES,
  CLEAR_RELEASES,
}