import React, { useMemo, useState, useEffect, useCallback } from 'react'

import { fetchSubscriptionContents } from '../../services/SubscriptionService'

import SubscriptionContentDelete from './SubscriptionContentDelete'

import GridItem from '../../components/Grid/GridItem'
import GridContainer from '../../components/Grid/GridContainer'
import Table from '../../components/Table/Table'
import Card from '../../components/Card/Card'
import CardBody from '../../components/Card/CardBody'
import Paginate from '../../components/Paginate'
import CardFooter from '../../components/Card/CardFooter'
import Loading from '../../components/Loading/Loading'
import Error from '../../components/Error/Error'
import Button from '../../components/CustomButtons/Button'
import CustomInput from '../../components/CustomInput/CustomInput'

const SubscriptionContentList: React.FC<any> = ({ subscription, defaultText, defaultPage, changeUrl }) => {
  const [items, setItems] = useState([])
  const [error, setError] = useState<any>(null)
  const [success, setSuccess] = useState(false)
  const [loading, setLoading] = useState(false)
  const [filters, setFilters] = useState({ subscriptionId: subscription.id, page: defaultPage, text: defaultText })
  const [paginateState, setPaginateState] = useState({ page: 0,  lastPage: 0,  perPage: 0, total: 0, })

  useEffect(() => {
    fetchData(filters)
  }, [filters])

  useEffect(() => {
    fetchData(filters)
    changeUrl(filters)
  }, [changeUrl, filters])

  const onChangePage = useCallback(page => {
    setFilters({
      ...filters,
      page
    })
  }, [filters])

  const resetFilters = useCallback(() => {
    setFilters({
      ...filters,
      page: 1,
      text: null
    })
  }, [filters])

  const fetchData = filters => {
    setLoading(true)
    setError(null)
    setSuccess(false)
    fetchSubscriptionContents(filters)
      .then(response => {
        if (response.type === 'error') {
          setSuccess(false)
          setError(response.message)
          setLoading(false)
          return
        }
        const data = response.details
        setPaginateState({
          page: +data.page,
          lastPage: +data.lastPage,
          perPage: +data.perPage,
          total: +data.total,
        })
        setItems(data.data)
        setSuccess(true)
        setError(null)
        setLoading(false)
      })
      .catch(err => {
        setSuccess(false)
        setError(err.message)
        setLoading(false)
      })
  }

  const ListContent = useMemo(() => {
    const data = items.map(item => createContentItem(item, resetFilters))
    if (data.length === 0) {
      if (loading) {
        return null
      }
      return <p>Nenhum registro encontrado</p>
    }
    return (
      <Table
        tableHeaderColor="primary"
        tableHead={['Id','Título', 'Tipo de conteúdo', 'Opções']}
        tableData={data}
      />
    )
  }, [items, loading, resetFilters])

  return (
    <div>
      {/*
      <Search 
        onChange={text => setFilters({ ...filters, text, page: 1 })} 
        defaultText={defaultText} 
      />
      */}
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              <Error message={error} hide={!error} />
              <Loading message={"Aguarde..."} hide={!loading} />
              {ListContent}
            </CardBody>
            <CardFooter>
              {useMemo(() => {
                if (!success) {
                  return null
                }
                return <Paginate changePage={onChangePage} {...paginateState} />
              }, [success, onChangePage, paginateState])}
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  )
}

const createContentItem = (item, onSuccess) => {
  let content, typeContent
  if (item.book) {
    content = item.book
    typeContent = 'Livro'
  } else if (item.jav) {
    content = item.jav
    typeContent = 'Jav'
  } else {
    content = item.spiritualFood
    typeContent = 'Alimento Diário'
  }
  const actionDelete = <SubscriptionContentDelete key={item.id} id={item.id} onSuccess={onSuccess} />
  return [content.id, content.title, typeContent, actionDelete]
}

const Search = ({ defaultText, onChange }) => {
  const [text, setText] = useState(defaultText || '')
  return (
    <div>
      <CustomInput
        labelText="Filtrar por título"
        id="search"
        formControlProps={{
          fullWidth: true
        }}
        inputProps={{
          defaultValue: text,
          onChange: (e) => setText(e.target.value)
        }}
      />
      <Button onClick={() => onChange(text)}>Filtrar</Button>
    </div>
  )
}

export default SubscriptionContentList
