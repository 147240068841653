import {
  ERROR_RECOVERY_PASSWORD,
  SUCCESS_RECOVERY_PASSWORD,
  REQUEST_RECOVERY_PASSWORD,
  CLEAR_RECOVERY_PASSWORD,
  CLEAR_ERROR,
  Data,
  MessageRecoveryPassword,
  RecoveryPasswordActions,
} from './Types'

export function requestRecoveryPassword(data: Data): RecoveryPasswordActions {
  return {
    type: REQUEST_RECOVERY_PASSWORD,
    payload: data,
  }
}

export function successRecoveryPassword(response: MessageRecoveryPassword): RecoveryPasswordActions {
  return {
    type: SUCCESS_RECOVERY_PASSWORD,
    data: response,
  }
}

export function errorRecoveryPassword(response: MessageRecoveryPassword): RecoveryPasswordActions {
  return {
    type: ERROR_RECOVERY_PASSWORD,
    error: response,
  }
}

export function clear(): RecoveryPasswordActions {
  return {
    type: CLEAR_RECOVERY_PASSWORD,
  }
}

export function clearError(): RecoveryPasswordActions {
  return {
    type: CLEAR_ERROR,
  }
}

export default {
  requestRecoveryPassword,
  successRecoveryPassword,
  errorRecoveryPassword,
}
