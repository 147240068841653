import React, { useState, useCallback, useMemo, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { createStyles, withStyles } from '@material-ui/core'

import { Actions } from '../../stores/SpiritualFoodForm'
import DayForm from './DayForm'

const DaysTab = ({ days, classes }) => {
  const dispatch = useDispatch()
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
    dispatch(Actions.setCurrentDay(newValue))
  }

  const content = useMemo(() => {
    return <DayForm key={value} />
  }, [value, days])

  return (
    <div>
      <Tabs
        classes={classes}
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
      >
        <TabStyled label="Segunda" />
        <TabStyled label="Terça" />
        <TabStyled label="Quarta" />
        <TabStyled label="Quinta" />
        <TabStyled label="Sexta" />
        <TabStyled label="Sábado" />
        <TabStyled label="Domingo" />
      </Tabs>
      {content}
    </div>
  );
}

const styles = createStyles({
  root: {
    margin: 0,
    marginTop: '20px',
    background: '#fff',
    boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.14)',
    backgroundColor: '#e6f7ed',
    borderBottom: 0,
    zIndex: 9,
    position: 'relative'
  },
  indicator: {
    backgroundColor: '#007332'
  }
})

const stylesTab = createStyles({
  root: {
    color: '#333'
  }
})

const TabStyled = withStyles(stylesTab)(Tab)

export default withStyles(styles)(DaysTab)
