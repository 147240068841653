export const REQUEST_REGISTER: any = 'REQUEST_REGISTER'
export const SUCCESS_REGISTER = 'SUCCESS_REGISTER'
export const ERROR_REGISTER = 'ERROR_REGISTER'
export const CLEAR_REGISTER = 'CLEAR_REGISTER'
export const CLEAR_ERROR = 'CLEAR_ERROR'

export interface RegisterState {
  requesting: boolean;
  error: boolean;
  success: boolean;
  message: string | null;
}

export interface Data {
  name: string;
  email: string;
  password: string;
  repassword: string;
}

export interface MessageRegister {
  message: string;
}

export interface RequestRegisterAction {
  type: typeof REQUEST_REGISTER
  payload: Data
}

export interface SuccessRegisterAction {
  type: typeof SUCCESS_REGISTER
  data: MessageRegister
}

export interface ErrorRegisterAction {
  type: typeof ERROR_REGISTER
  error: MessageRegister
}

export interface ClearRegisterAction {
  type: typeof CLEAR_REGISTER
}

export interface ClearErrorAction {
  type: typeof CLEAR_ERROR
}

export type RegisterActions = 
  RequestRegisterAction | 
  SuccessRegisterAction | 
  ErrorRegisterAction | 
  ClearRegisterAction | 
  ClearErrorAction

export const RegisterTypes = {
  REQUEST_REGISTER,
  SUCCESS_REGISTER,
  ERROR_REGISTER,
  CLEAR_REGISTER,
  CLEAR_ERROR,
}