import React from 'react'
import InfoIcon from '@material-ui/icons/Info'
import SnackbarContent from '../Snackbar/SnackbarContent'

export default function Loading({ message, hide }) {
  if (!!hide) {
    return null
  }
  return (
    <SnackbarContent
      icon={InfoIcon} 
      color="warning" 
      message={message}
    />
  )
}
